import { Close, Done, Save } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useMemo } from 'react';

import { CustomSelectMemo } from '#shared/components/inputs/CustomSelect';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  peObjetivosFormActions,
  peObjetivosFormSelector,
} from '#modules/disciplinas/redux/peObjetivosFormSlice';

interface IOptionDisciplina {
  idTurmaDisc: number;
  name: string;
  valid: boolean;
  isNotSaved: boolean;
}

interface IDisciplinaOptionRender {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: IOptionDisciplina;
}

function DisciplinaOptionRender({ props, option }: IDisciplinaOptionRender) {
  return (
    <Box {...props} key={option.idTurmaDisc} component="li">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {option.isNotSaved ? (
          <Save sx={{ color: '#1985ed', fontSize: '1.375rem', mr: '0.5rem' }} />
        ) : option.valid ? (
          <Done sx={{ color: '#8CC59A', fontSize: '1.375rem', mr: '0.5rem' }} />
        ) : (
          <Close sx={{ color: '#E05959', fontSize: '1.375rem', mr: '0.5rem' }} />
        )}

        <Typography>{option.name}</Typography>
      </Box>
    </Box>
  );
}

const DisciplinaOptionRenderMemo = React.memo(DisciplinaOptionRender);

const statusIconExternal = {
  notSaved: <Save sx={{ color: '#1985ed', fontSize: '1.875rem', mr: '0.5rem' }} />,
  concluido: <Done sx={{ color: '#8CC59A', fontSize: '1.875rem', mr: '0.5rem' }} />,
  incompleto: <Close sx={{ color: '#E05959', fontSize: '1.875rem', mr: '0.5rem' }} />,
  nenhum: <></>,
};

export function ObjetivosCursoSelector() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const options = useAppSelector(peObjetivosFormSelector.itemsOptions);
  const itemSelected = useAppSelector(peObjetivosFormSelector.selectedItem);
  const validStatus = useAppSelector(peObjetivosFormSelector.validStatus);
  const changedStatus = useAppSelector(peObjetivosFormSelector.changedStatus);
  const dispatch = useAppDispatch();

  const getStatusDisciplina = () => {
    if (itemSelected == null) {
      return 'nenhum';
    }

    const changed = [1, 2, 3].some((item) => changedStatus[item]?.[itemSelected.idTurmaDisc]);

    const status = validStatus[itemSelected.idTurmaDisc];

    if (changed) return 'notSaved';

    return status[1] && status[2] && status[3] ? 'concluido' : 'incompleto';
  };

  const statusDisciplina = getStatusDisciplina();

  const optionsDisciplina = useMemo(() => {
    return options
      .map((option) => {
        const changed = [1, 2, 3].some((item) => changedStatus[item]?.[option.idTurmaDisc]);

        const valid = [1, 2, 3].every((item) => validStatus[option.idTurmaDisc][item]);

        const name = changed ? `${option.name} **` : option.name;

        return {
          ...option,
          name,
          valid,
          isNotSaved: changed,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [changedStatus, options, validStatus]);

  if (disciplina == null) return <></>;

  return (
    <Box sx={{ mb: '1rem', display: 'flex', alignItems: 'center' }}>
      {statusIconExternal[statusDisciplina]}

      <CustomSelectMemo
        label="Curso"
        onChange={(item) => dispatch(peObjetivosFormActions.changeCurso(item))}
        options={optionsDisciplina}
        value={itemSelected}
        optionLabel="name"
        optionValue="idTurmaDisc"
        disabled={!disciplina.gerencial}
        fullWidth
        marginType="no-margin"
        renderOption={(props, option) => (
          <DisciplinaOptionRenderMemo props={props} option={option} key={option.idTurmaDisc} />
        )}
      />
    </Box>
  );
}

export const ObjetivosCursoSelectorMemo = React.memo(ObjetivosCursoSelector);
