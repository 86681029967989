import { Box, styled } from '@mui/material';

export const AuthContainer = styled(Box)`
  min-height: 100vh;
  background: linear-gradient(180deg, #003366 1.9%, #35649f 100%);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthModal = styled(Box)`
  padding: 1.5rem 2.5rem 5rem;
  max-width: 530px;
  width: 100%;
  border-radius: 10px;

  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    max-height: 120px;
  }

  > h2 {
    margin-top: 1rem;
    color: #0000008f;
    font-family: 'Public Sans';
    font-weight: 600;
    font-size: 0.9375rem;
  }

  > form {
    margin-top: 3rem;
    width: 80%;

    * {
      font-family: 'Public Sans';
    }

    > button {
      margin-top: 3rem;
      background-color: #35649f;

      border-radius: 5px;
    }
  }
`;
