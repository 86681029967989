import { Box, Button } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';

import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';
import { usePost } from '#shared/services/useAxios';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IErrorValidation,
  IDisciplinaValidationResponse,
} from '#modules/disciplinas/types/disciplina';

import { DisciplinasSendErrorsModalMemo } from './errorsModal';
import { VerifyErrorsContainer } from './styles';

export function VerifyErrors() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const [errorsValidation, setErrorsValidation] = useState<IErrorValidation[] | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const { toast } = useToast();

  const { loading: validateLoading, send: validateDisciplina } = usePost<
    IDisciplinaValidationResponse,
    any
  >(`/disciplinas/validation/${disciplina?.idTurmaDisc}/check`);

  const sendSecretaria = async () => {
    if (disciplina?.idTurmaDisc != null) {
      const { data, error } = await validateDisciplina({});

      if (error != null) {
        toast({ message: error, severity: 'error' });
      }

      if (data != null) {
        if (data.valid) {
          toast({
            message: 'Não foi encontrado nenhum erro no plano de ensino',
            severity: 'success',
          });

          setErrorsValidation(null);
        } else {
          setErrorsValidation(data.errorsList);

          setOpenErrorDialog(true);
        }
      }
    }
  };

  return (
    <>
      <LoadingMemo loading={validateLoading} />

      <DisciplinasSendErrorsModalMemo
        open={openErrorDialog}
        errors={errorsValidation ?? []}
        closeModal={() => setOpenErrorDialog(false)}
      />

      <VerifyErrorsContainer>
        <Box className="button">
          {errorsValidation != null && (
            <Button variant="contained" onClick={() => setOpenErrorDialog(true)}>
              Mostrar erros ({errorsValidation.length})
            </Button>
          )}

          <Box>
            <Button variant="contained" onClick={async () => await sendSecretaria()}>
              Verificar erros
            </Button>
          </Box>
        </Box>
      </VerifyErrorsContainer>
    </>
  );
}

export const VerifyErrorsMemo = React.memo(VerifyErrors);
