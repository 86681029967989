import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

import { getCargaHorariaAula } from '#shared/utils/cargaHoraria';
import { hoursToTextDuration } from '#shared/utils/timeTransform';

import { IPlanoAulaSchema } from '../schemas/planoAulaSchema';
import { IAulaInfo, ISavePlanoAulaResponse } from '../types/disciplina';
import { TipoModalidade } from '../types/enums/TipoModalidade';
import { IDiasSemanaMap, IDisciplinaInfo } from './disciplinaInfoSlice';

interface IPlanoAula {
  idPlanoEnsinoAula: number;
  idTurmaDisc: number;
  data: string;
  objetivos: string[];
  referencias: string[];
  chAed?: string;
  textoAed?: string;
  chEad?: string;
  chPresencial?: string;
  tipoAula?: string;
  conteudo: string;
  metodologias: string[];
  validacao: boolean;
  numAulas: number;
  diaSemana: string;
}

interface IPlanoAulasMap {
  [key: string]: IPlanoAula;
}

interface IKeyBoleanMap {
  [key: string]: boolean;
}

interface IUpdateItemAction {
  id: string;
  item: IPlanoAulaSchema;
}

interface IUpdateValidationAction {
  id: string;
  validation: boolean;
}

export interface IPlanoAulasFormState {
  planoAulas: IPlanoAulasMap;
  openStatus: IKeyBoleanMap;
  changedStatus: IKeyBoleanMap;
  openAllState: boolean;
}

interface IUpdateStatus {
  diasSemanas: IDiasSemanaMap;
  disciplina: IDisciplinaInfo;
}

const initialState: IPlanoAulasFormState = {
  planoAulas: {},
  openStatus: {},
  changedStatus: {},
  openAllState: true,
};

export const planoAulasFormSlice = createSlice({
  name: 'planoAulasForm',
  initialState,
  reducers: {
    loadingData: (state, { payload }: PayloadAction<IAulaInfo[]>) => {
      state.planoAulas = {};
      state.openStatus = {};
      state.changedStatus = {};
      state.openAllState = true;

      payload.forEach((item) => {
        const key = item.data;

        state.planoAulas[key] = item;
        state.openStatus[key] = state.openAllState;
      });
    },
    handleOpenAll: (state) => {
      Object.keys(state.openStatus).forEach((key) => {
        state.openStatus[key] = !state.openAllState;
      });

      state.openAllState = !state.openAllState;
    },
    changeOpenStatus: (state, { payload }: PayloadAction<string>) => {
      state.openStatus[payload] = !state.openStatus[payload];
    },
    updateItem: (state, { payload: { id, item } }: PayloadAction<IUpdateItemAction>) => {
      state.planoAulas[id] = {
        ...state.planoAulas[id],
        ...item,
        numAulas: Number(item.numAulas),
        tipoAula: item.tipoAula?.value,
      };

      state.changedStatus[id] = true;
    },
    updateValidation: (
      state,
      { payload: { id, validation } }: PayloadAction<IUpdateValidationAction>,
    ) => {
      state.planoAulas[id] = { ...state.planoAulas[id], validacao: validation };

      state.changedStatus[id] = true;
    },
    updateStatusSplit: (state, { payload }: PayloadAction<IUpdateStatus>) => {
      Object.entries(state.planoAulas).forEach(([key, aula]) => {
        if (aula.idPlanoEnsinoAula != null && aula.idPlanoEnsinoAula !== 0) {
          state.changedStatus[key] = true;

          const carga = getCargaHorariaAula({
            numeroAulas: aula.numAulas,
            tipoModalidade: payload.disciplina?.tipoModalidade ?? TipoModalidade.presencial,
            diaSemanaAula: aula.diaSemana,
            diasMap: payload.diasSemanas,
          });

          state.planoAulas[key].chPresencial = hoursToTextDuration(carga.presencial);
          state.planoAulas[key].chEad = hoursToTextDuration(carga.ead);
        }
      });
    },
    savePlanosAulas: (state, { payload }: PayloadAction<ISavePlanoAulaResponse[]>) => {
      payload.forEach((item) => {
        const key = item.dataAula;

        state.planoAulas[key].idPlanoEnsinoAula = item.idPlanoEnsinoAula;

        delete state.changedStatus[key];
      });
    },
  },
});

export const planoAulasFormActions = planoAulasFormSlice.actions;

export const planoAulasFormSelector = {
  openAll: (state: RootState) => state.planoAulasForm.openAllState,
  openStatus: (state: RootState) => state.planoAulasForm.openStatus,
  changedItems: (state: RootState) => state.planoAulasForm.changedStatus,
  planoAulas: (state: RootState) => state.planoAulasForm.planoAulas,
};
