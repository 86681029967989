import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peAvaliacoesFormSelector } from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { modalidadeAvaliacaoMap } from '#modules/disciplinas/types/enums/modalidadeAvaliacao';

interface IMapAvaliacoes {
  mapNatureza: Record<number, { natureza: string; peso: number; siglas: string[] }>;
  pesoPresenciais: number;
  siglasPresenciais: string[];
  pesoIndividuais: number;
  siglasIndividuais: string[];
}

export function ResumoModalidades() {
  const avaliacoes = useAppSelector(peAvaliacoesFormSelector.avaliacoes);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const getAvaliacoesResumo = () => {
    const avaliacoesMap = Object.values(avaliacoes).reduce<IMapAvaliacoes>(
      (map, avaliacao) => {
        if (avaliacao.tipo === 'media') {
          if (avaliacao.natureza != null) {
            if (map.mapNatureza[avaliacao.natureza.value.id] != null) {
              map.mapNatureza[avaliacao.natureza.value.id].peso += Number(avaliacao.peso);

              map.mapNatureza[avaliacao.natureza.value.id].siglas.push(avaliacao.sigla);
            } else {
              map.mapNatureza[avaliacao.natureza.value.id] = {
                natureza: avaliacao.natureza.label,
                peso: Number(avaliacao.peso),
                siglas: [avaliacao.sigla],
              };
            }

            if (avaliacao.natureza.value.presencial) {
              map.pesoPresenciais += Number(avaliacao.peso);
              map.siglasPresenciais.push(avaliacao.sigla);
            }
          }

          if (
            avaliacao.modalidade != null &&
            avaliacao.modalidade === modalidadeAvaliacaoMap.individual
          ) {
            map.pesoIndividuais += Number(avaliacao.peso);
            map.siglasIndividuais.push(avaliacao.sigla);
          }
        }

        return map;
      },
      {
        mapNatureza: {},
        pesoPresenciais: 0,
        siglasPresenciais: [],
        pesoIndividuais: 0,
        siglasIndividuais: [],
      },
    );

    const naturezas = Object.values(avaliacoesMap.mapNatureza).map(
      (x) => `${x.natureza} - ${x.peso.toFixed(2)}% - (${x.siglas.join(', ')})`,
    );

    return {
      naturezas,
      presencial: `${avaliacoesMap.pesoPresenciais.toFixed(
        2,
      )}% - (${avaliacoesMap.siglasPresenciais.join(', ')})`,
      individual: `${avaliacoesMap.pesoIndividuais.toFixed(
        2,
      )}% - (${avaliacoesMap.siglasIndividuais.join(', ')})`,
    };
  };

  const avaliacoesResumo = getAvaliacoesResumo();

  return (
    <Box
      sx={{
        my: '1rem',
        border: '1px solid #35353f',
        display: 'flex',
        alignItems: 'stretch',
        gap: '1rem',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: '1rem',
          borderRight: { xs: 'unset', md: '1px solid #35353f' },
          borderBottom: { xs: '1px solid #35353f', md: 'unset' },
        }}
      >
        <Typography fontSize="1.2rem" fontWeight={600} whiteSpace="pre-line">
          {disciplina?.textoAvaliacaoMec}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', maxWidth: '350px', padding: '1rem' }}>
        <Box>
          <Typography fontSize="1.2rem" fontWeight={600}>
            Tipos cadastrados
          </Typography>

          {avaliacoesResumo.naturezas.map((x) => (
            <Typography key={x} ml="1rem" fontSize="1rem" fontWeight={600}>
              {x}
            </Typography>
          ))}
        </Box>

        <Box my="0.5rem">
          <Typography fontSize="1.2rem" fontWeight={600}>
            % Presencial
          </Typography>

          <Typography ml="1rem" fontSize="1rem" fontWeight={600}>
            {avaliacoesResumo.presencial}
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="1.2rem" fontWeight={600}>
            % Individual
          </Typography>

          <Typography ml="1rem" fontSize="1rem" fontWeight={600}>
            {avaliacoesResumo.individual}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
