import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';

import { statusEnum } from '#shared/utils/status';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { FeedbackContainer } from './styles';

export function DisciplinaFeedback() {
  const feedback = useAppSelector(disciplinaInfoSelector.feedback);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);

  if (disciplinaStatus !== statusEnum.emValidacao) return <></>;

  return (
    <FeedbackContainer>
      <Typography component="h3">Feedback:</Typography>

      <Typography>{feedback}</Typography>
    </FeedbackContainer>
  );
}

export const DisciplinaFeedbackMemo = React.memo(DisciplinaFeedback);
