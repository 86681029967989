import { Box, styled } from '@mui/material';

export const Title = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0.25rem;
  background: #35649f;
  color: #fff;

  border-bottom: 0px;
  border-radius: 5px 5px 0 0;

  h3 {
    flex: 1;
    margin: 0 0.25rem;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1rem;
  }
`;

export const Content = styled(Box)`
  overflow: auto;
  padding: 1.5rem;
  background: #fff;

  border: 1px solid #696d7b;
  border-top: 0px;

  border-radius: 0 0 5px 5px;
`;
