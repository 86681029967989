import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import React from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useParams } from 'react-router-dom';

import { useAuth } from '#shared/hooks/auth';
import { useToast } from '#shared/hooks/toast';
import { useGet } from '#shared/services/useAxios';
import { IPagingResult } from '#shared/types/IPagingResult';
import { perfilEnum, statusColors, statusLabels } from '#shared/utils/status';

import { IDisciplina } from '#modules/disciplinas/types/disciplina';

import { DisciplinaCarouselCard } from './card';
import { CarouselContainer } from './styles';

const responsive: ResponsiveType = {
  lg: {
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
  },
  md: {
    breakpoint: { max: 1200, min: 900 },
    items: 3,
  },
  sm: {
    breakpoint: { max: 900, min: 600 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export function DisciplinasPendentes() {
  const params = useParams();
  const { user } = useAuth();

  const { toast } = useToast();

  const {
    error: disciplinasError,
    data: disciplinasData,
    loading: disciplinasLoading,
    send: getDisciplinas,
  } = useGet<IPagingResult<IDisciplina>>({
    url: '/disciplinas',
    lazy: true,
  });

  useEffect(() => {
    if (user.perfilSelected.id === perfilEnum.professor) {
      void getDisciplinas({ params: { status: '0,1,3' } });
    } else {
      void getDisciplinas({ params: { status: '2' } });
    }
  }, [getDisciplinas, user.perfilSelected.id]);

  useEffect(() => {
    if (disciplinasError != null) {
      toast({ message: disciplinasError, severity: 'error' });
    }
  }, [disciplinasError, toast]);

  const getDisciplinaInfo = () => {
    if (disciplinasData == null) {
      return [];
    }

    return disciplinasData.data
      .filter((disciplina) => disciplina.idTurmaDisc.toString() !== params.id)
      .map((disciplina) => {
        const statusColor = statusColors[user.perfilSelected.id][disciplina.status];
        const statusText = statusLabels[user.perfilSelected.id][disciplina.status];

        return {
          ...disciplina,
          statusColor: statusColor ?? '#aaaaaa',
          statusText: statusText ?? 'Sem Status',
        };
      });
  };

  const disciplinasInfo = getDisciplinaInfo();

  // console.log('DisciplinasPendentes', disciplinasError, disciplinasData, disciplinasLoading);

  return (
    <CarouselContainer id="disciplinas-pendentes">
      <Typography>Disciplinas Pendentes</Typography>

      {disciplinasLoading ? (
        <Box>
          <CircularProgress color="primary" size={50} />
        </Box>
      ) : disciplinasInfo.length === 0 ? (
        <Box>
          <Typography className="no-result">Nenhuma Disciplina Pendente</Typography>
        </Box>
      ) : (
        <Carousel
          responsive={responsive}
          arrows
          swipeable={false}
          draggable={false}
          containerClass="carousel-container"
        >
          {disciplinasInfo.map((disciplina) => (
            <DisciplinaCarouselCard
              key={`${disciplina.idTurmaDisc}_${disciplina.professor}`}
              disciplina={disciplina}
            />
          ))}
        </Carousel>
      )}
    </CarouselContainer>
  );
}

export const DisciplinasPendentesMemo = React.memo(DisciplinasPendentes);
