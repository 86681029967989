import { useAppSelector } from 'app/hooks';
import React from 'react';

import { useAuth } from '#shared/hooks/auth';
import { perfilEnum, statusEnum } from '#shared/utils/status';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { DisciplinaReopenMemo } from './reopen';
import { ResendValidationMemo } from './resendValidation';
import { SendCoordenacaoMemo } from './sendCoordenacao';
import { SendValidationMemo } from './sendValidation';
import { VerifyErrorsMemo } from './verifyErrors';

export function DisciplinasSend() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const idPlanoEnsino = useAppSelector(disciplinaInfoSelector.idPlanoEnsino);

  const { user } = useAuth();

  const canSendCord =
    user.perfilSelected.id === perfilEnum.professor &&
    (disciplinaStatus === statusEnum.emPreenchimento ||
      disciplinaStatus === statusEnum.emValidacao) &&
    idPlanoEnsino !== 0;

  const canSendValidation =
    (((disciplina?.modalidade ?? 'Presencial') === 'Mega Class' &&
      user.perfilSelected.id === perfilEnum.gestor) ||
      ((disciplina?.modalidade ?? 'Presencial') !== 'Mega Class' &&
        (user.perfilSelected.id === perfilEnum.gestor ||
          user.perfilSelected.id === perfilEnum.coordenador))) &&
    disciplinaStatus === statusEnum.comCoordenador &&
    idPlanoEnsino !== 0;

  const canResendValidation =
    (((disciplina?.modalidade ?? 'Presencial') === 'Mega Class' &&
      user.perfilSelected.id === perfilEnum.gestor) ||
      ((disciplina?.modalidade ?? 'Presencial') !== 'Mega Class' &&
        (user.perfilSelected.id === perfilEnum.gestor ||
          user.perfilSelected.id === perfilEnum.coordenador))) &&
    disciplinaStatus === statusEnum.naSecretaria &&
    idPlanoEnsino !== 0;

  return (
    <>
      {canSendCord && <SendCoordenacaoMemo />}

      {canSendValidation && <SendValidationMemo />}

      {disciplinaStatus === statusEnum.naSecretaria && <DisciplinaReopenMemo />}

      {canResendValidation && <ResendValidationMemo />}

      <VerifyErrorsMemo />
    </>
  );
}

export const DisciplinasSendMemo = React.memo(DisciplinasSend);
