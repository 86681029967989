import { Box, Container } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useMemo } from 'react';

import { LoadingMemo } from '#shared/components/Loading';
import { useAuth } from '#shared/hooks/auth';
import { useToast } from '#shared/hooks/toast';
import { useGet } from '#shared/services/useAxios';
import { IPagingResult } from '#shared/types/IPagingResult';
import { removeEmptyFields } from '#shared/utils/removeEmptyFields';

import { IDisciplina, IDisciplinasFilterAPI } from '#modules/disciplinas/types/disciplina';
import { DisciplinasTableMemo } from '#modules/home/components/DisciplinasTable';
import {
  disciplinasFilterActions,
  disciplinasFilterSelector,
} from '#modules/home/redux/disciplinasFilterSlice';

import { HomeInfoMemo } from './info';
import { HomePaginationMemo } from './pagination';
import { HomeContainer, MainContent } from './styles';

export function Home() {
  const filtro = useAppSelector(disciplinasFilterSelector.filtro);
  const page = useAppSelector(disciplinasFilterSelector.page);
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const { toast } = useToast();

  const apiParams = useMemo<IDisciplinasFilterAPI>(() => {
    return {
      ...removeEmptyFields(filtro),
      periodo: filtro.periodo?.id,
      status: filtro.status?.id,
      tipo_curso: filtro.tipoCurso?.id,
      filial: filtro.filial?.id,
      tipo_disciplina: filtro.tipoDisciplina?.id,
      modalidade: filtro.modalidade?.id,
      page,
    };
  }, [filtro, page]);

  const {
    send: getDisciplinas,
    error: disciplinasError,
    data: disciplinasData,
    loading: disciplinasLoading,
  } = useGet<IPagingResult<IDisciplina>>({
    url: '/disciplinas',
    lazy: true,
  });

  useEffect(() => {
    void getDisciplinas({ params: apiParams });
  }, [apiParams, getDisciplinas]);

  useEffect(() => {
    if (disciplinasError != null) {
      toast({ message: disciplinasError, severity: 'error' });
    }
  }, [disciplinasError, toast]);

  useEffect(() => {
    if (disciplinasData != null) {
      dispatch(disciplinasFilterActions.loadingDisciplinas({ disciplinasData, user }));
    }
  }, [disciplinasData, dispatch, user]);

  useEffect(() => {
    dispatch(disciplinasFilterActions.clearFilter());
  }, [dispatch, user.perfilSelected]);

  return (
    <Container maxWidth="xl">
      <HomeContainer>
        <LoadingMemo loading={disciplinasLoading} />

        <MainContent id="main-scroll">
          <HomeInfoMemo />

          <Box className="disciplinas">
            <DisciplinasTableMemo />

            <HomePaginationMemo />
          </Box>
        </MainContent>
      </HomeContainer>
    </Container>
  );
}
