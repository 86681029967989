import { Box, styled } from '@mui/material';

export const SendContainer = styled(Box)`
  display: flex;
  padding: 2rem 2rem 0;
  justify-content: flex-end;

  > button {
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin-right: 1.5rem;
    color: #fff;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    font-size: 0.9375rem;
    font-weight: 700;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ErrorsModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 95px);
  overflow: hidden;
`;

export const ErrorsList = styled(Box)`
  flex: 1;
  overflow: auto;

  h3 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0.5rem 0;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0.5rem 1rem;
  }

  h4 + div {
    margin-left: 1rem;
  }

  p {
    margin: 0.3rem 1rem;
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const SendValidationContainer = styled(Box)`
  padding: 1rem 2rem;
  box-shadow: 0px 2px 8px #35649f, inset 0px 1px 2px #35649f;
  background: #f7f7f7;
  border-radius: 20px;
  margin-top: 1rem;

  > p {
    font-family: 'Archivo';
    font-weight: 600;
    font-size: 1.375rem;
  }

  .MuiFormControlLabel-label {
    font-family: 'Archivo';
    font-size: 1.25rem;
  }
`;

export const FeedBackContainer = styled(Box)`
  padding: 20px 1rem 1rem;
  border: 1px solid #d0d0d0;
  margin-top: 1rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 16px;
    top: 0;
    left: 0;
    background-color: #35649f;
  }

  div.input {
    > label {
      font-size: 1rem;
      font-weight: 700;
      font-family: 'Montserrat';
      color: #35353f;
    }
  }

  div.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      background: #7678d7;
      box-shadow: 0px 2px 2px rgba(97, 97, 97, 0.25);
      border-radius: 10px;
      color: #fff;
      font-size: 0.9375rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        background: lightgrey;
        color: #999;
      }
    }

    > div p {
      margin-top: 10px;
      font-weight: 700;
    }
  }
`;

export const SendSecretariaContainer = styled(Box)`
  margin-right: 2rem;

  div.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      max-width: 350px;

      background: #5bab68;
      box-shadow: 0px 2px 2px rgba(97, 97, 97, 0.25);
      border-radius: 10px;
      color: #fff;
      font-size: 0.9375rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        background: lightgrey;
        color: #999;
      }
    }

    > div p {
      max-width: 300px;
      margin-top: 10px;
      font-weight: 700;
    }
  }
`;

export const ResendValidationContainer = styled(Box)`
  margin-right: 2rem;

  div.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      max-width: 500px;

      background: #5bab68;
      box-shadow: 0px 2px 2px rgba(97, 97, 97, 0.25);
      border-radius: 10px;
      color: #fff;
      font-size: 0.9375rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        background: lightgrey;
        color: #999;
      }
    }

    > div p {
      max-width: 300px;
      margin-top: 10px;
      font-weight: 700;
    }
  }
`;

export const VerifyErrorsContainer = styled(Box)`
  margin-right: 2rem;

  div.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 1rem;

    button {
      max-width: 350px;

      background: #228be6;
      box-shadow: 0px 2px 2px rgba(97, 97, 97, 0.25);
      border-radius: 10px;
      color: #fff;
      font-size: 0.9375rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        background: lightgrey;
        color: #999;
      }
    }

    > div p {
      max-width: 300px;
      margin-top: 10px;
      font-weight: 700;
    }
  }
`;

export const ReopenTextContainer = styled(Box)`
  padding: 3rem 2rem;
  box-shadow: 0px 2px 8px #35649f, inset 0px 1px 2px #35649f;
  background: #f7f7f7;
  border-radius: 20px;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    font-family: 'Archivo';
    font-weight: 600;
    font-size: 1.375rem;
  }
`;

export const ReopenButtonContainer = styled(Box)`
  margin-right: 2rem;

  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  button {
    box-shadow: 0px 2px 2px rgba(97, 97, 97, 0.25);
    border-radius: 10px;
    color: #fff;
    font-size: 0.9375rem;
  }
`;
