import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';

import { FormTextFieldMemo } from '#shared/components/form/FormTextField';
import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import {
  disciplinaInfoActions,
  disciplinaInfoSelector,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IObsAvaliacaoSchema,
  obsAvaliacaoSchema,
} from '#modules/disciplinas/schemas/obsAvaliacaoSchema';

export function AvaliacaoObservacao() {
  const disciplinaInfo = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IObsAvaliacaoSchema>({
    resolver: yupResolver(obsAvaliacaoSchema),
    mode: 'onSubmit',
  });

  const handleFormSubmit = (form: IObsAvaliacaoSchema) => {
    dispatch(disciplinaInfoActions.updateObsAvaliacao(form.obs));
  };

  const handleFormChange = async () => {
    await handleSubmit(handleFormSubmit)();
  };

  const cannotEdit = cannotEditDisciplina({ disciplinaStatus, user });

  return (
    <form noValidate style={{ marginTop: '1rem' }}>
      <FormTextFieldMemo
        control={control}
        label="Observacão"
        name="obs"
        placeholder="Digite aqui sua observação"
        marginType="no-margin"
        onBlur={handleFormChange}
        multiline
        minRows={3}
        defaultValue={disciplinaInfo?.Obs ?? ''}
        disabled={cannotEdit}
        errors={errors.obs}
      />
    </form>
  );
}

export const AvaliacaoObservacaoMemo = React.memo(AvaliacaoObservacao);
