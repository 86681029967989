import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FormSelectMemo } from '#shared/components/form/FormSelect';
import { FormTextFieldMemo } from '#shared/components/form/FormTextField';
import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  peObjetivosFormActions,
  peObjetivosFormSelector,
} from '#modules/disciplinas/redux/peObjetivosFormSlice';
import { IObjetivoSchema, objetivoSchema } from '#modules/disciplinas/schemas/objetivosSchema';

import { ObjetivoFormContainer } from './styles';
import { ObjetivoFormTitleMemo } from './title';

interface IObjetivoForm {
  item: 1 | 2 | 3;
}

const taxonomiaOptions: Record<1 | 2 | 3, string[]> = {
  1: ['Lembrar', 'Aplicar'],
  2: ['Sintetizar', 'Entender'],
  3: ['Analisar', 'Criar'],
};

export function ObjetivoForm({ item }: IObjetivoForm) {
  const competencias = useAppSelector(peObjetivosFormSelector.competencias);
  const perfis = useAppSelector(peObjetivosFormSelector.perfis);
  const disciplina = useAppSelector(peObjetivosFormSelector.selectedItem);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const formDefault = useAppSelector(peObjetivosFormSelector.formDefault);
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IObjetivoSchema>({
    resolver: yupResolver(objetivoSchema),
    mode: 'onSubmit',
  });

  const competenciasDefault = useMemo(() => {
    return disciplina == null
      ? []
      : formDefault.variado[item][disciplina.idTurmaDisc].competencias.map((comp) => ({
          item: comp,
        })) ?? [];
  }, [disciplina, formDefault.variado, item]);

  const perfisDefault = useMemo(() => {
    return disciplina == null
      ? []
      : formDefault.variado[item][disciplina.idTurmaDisc].perfis.map((perf) => ({ item: perf }));
  }, [disciplina, formDefault.variado, item]);

  useEffect(() => {
    reset({
      competencias: competenciasDefault,
      perfis: perfisDefault,
      descricao: formDefault.fixo[item].descricao,
      taxonomia: formDefault.fixo[item].taxonomia ?? null,
    });
  }, [competenciasDefault, formDefault, item, perfisDefault, reset]);

  const handleFormSubmit = (form: IObjetivoSchema) => {
    if (disciplina != null) {
      dispatch(
        peObjetivosFormActions.updateForm({
          idTurmaDisc: disciplina.idTurmaDisc,
          item,
          form: {
            descricao: form.descricao,
            taxonomia: form.taxonomia,
            perfis: form.perfis.map(({ item: numero }) => numero),
            competencias: form.competencias.map(({ item: numero }) => numero),
          },
        }),
      );
    }
  };

  const handleFormChange = async () => {
    await handleSubmit(handleFormSubmit)();
  };

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus });

  if (disciplina == null) return <></>;

  return (
    <ObjetivoFormContainer>
      <ObjetivoFormTitleMemo item={item} />

      <form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <FormTextFieldMemo
              control={control}
              label="Descrição"
              name="descricao"
              errors={errors.descricao}
              marginType="no-margin"
              onBlur={handleFormChange}
              defaultValue={formDefault.fixo[item].descricao}
              multiline
              disabled={cannotEdit}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormSelectMemo
              control={control}
              label="Taxonomia"
              name="taxonomia"
              options={taxonomiaOptions[item] ?? []}
              errors={errors.taxonomia}
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={formDefault.fixo[item].taxonomia}
              disabled={cannotEdit}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormSelectMemo
              control={control}
              label="Competências e Habilidades"
              name="competencias"
              options={competencias[disciplina.idTurmaDisc] ?? []}
              optionLabel="item"
              optionValue="item"
              errors={errors.competencias as any}
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={competenciasDefault}
              multiple
              disabled={cannotEdit}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormSelectMemo
              control={control}
              label="Perfil do Egresso"
              name="perfis"
              options={perfis[disciplina.idTurmaDisc] ?? []}
              optionLabel="item"
              optionValue="item"
              errors={errors.perfis as any}
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={perfisDefault}
              multiple
              disabled={cannotEdit}
            />
          </Grid>
        </Grid>
      </form>
    </ObjetivoFormContainer>
  );
}

export const ObjetivoFormMemo = React.memo(ObjetivoForm);
