import { Box, styled } from '@mui/material';

export const ObjetivoFormContainer = styled(Box)`
  margin-top: 2rem;
  padding: 1.5rem 1rem;
  border: 1px solid #35353f;
  border-radius: 10px;
  position: relative;
`;

export const ObjetivoFormTitleContainer = styled(Box)`
  position: absolute;
  padding: 4px;
  top: -17px;
  left: 9px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;

  > p {
    font-family: 'Archivo';
    font-size: 1.125rem;
    font-weight: 700;
    margin-right: 5px;
  }
`;
