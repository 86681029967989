import * as yup from 'yup';

export interface IAuthSchema {
  username: string;
  password: string;
}

export const authSchema = yup.object().shape({
  username: yup.string().required('O usuario é obrigatório'),
  password: yup.string().required('A senha é obrigatória'),
});
