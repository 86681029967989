import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getError } from '#shared/utils/getError';

import { axiosClient } from './axiosClient';

export interface ISendWithInput<T> {
  data?: T;
  error?: string;
}

interface IUseWithoutInput<T> {
  data?: T;
  error?: string;
  loading: boolean;
  send: (conf?: AxiosRequestConfig) => Promise<void>;
}

type IUseGet<T> = IUseWithoutInput<T> & {
  updateData: React.Dispatch<React.SetStateAction<T | undefined>>;
  sendGet: (conf?: AxiosRequestConfig) => Promise<ISendWithInput<T>>;
};

interface IUseWithInput<T, D> {
  loading: boolean;
  send: (input: D, conf?: AxiosRequestConfig) => Promise<ISendWithInput<T>>;
}

interface IUseDelete<T> {
  loading: boolean;
  send: () => Promise<ISendWithInput<T>>;
}

interface IUseGetParams {
  url: string;
  config?: AxiosRequestConfig;
  lazy?: boolean;
}

export function useGet<T>({ url, lazy, config }: IUseGetParams): IUseGet<T> {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(lazy !== true);

  const send = useCallback(
    async (conf?: AxiosRequestConfig) => {
      setLoading(true);

      try {
        const response = await axiosClient.get<T>(conf?.url ?? url, { ...config, ...conf });

        setData(response);
      } catch (e) {
        setError((current) => {
          const newError = getError(e);

          if (current === newError) {
            if (current[current.length - 1] === ' ') {
              return newError;
            }

            return `${newError} `;
          }

          return newError;
        });
      } finally {
        setLoading(false);
      }
    },
    [config, url],
  );

  const sendGet = useCallback(
    async (conf?: AxiosRequestConfig) => {
      setLoading(true);

      let dataGet;
      let errorGet;

      try {
        const response = await axiosClient.get<T>(conf?.url ?? url, { ...config, ...conf });

        dataGet = response;
      } catch (e) {
        errorGet = getError(e);
      }

      setLoading(false);

      return { data: dataGet, error: errorGet };
    },
    [config, url],
  );

  useEffect(() => {
    if (lazy !== true) {
      void send(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return useMemo(
    () => ({ data, error, send, updateData: setData, sendGet, loading }),
    [data, error, loading, send, sendGet],
  );
}

export function usePost<T, D>(url: string, config?: AxiosRequestConfig): IUseWithInput<T, D> {
  const [loading, setLoading] = useState(false);

  const send = async (input: D, conf?: AxiosRequestConfig) => {
    setLoading(true);

    let data;
    let error;

    try {
      const response = await axiosClient.post<T, D>(conf?.url ?? url, input, {
        ...config,
        ...conf,
      });

      data = response;
    } catch (e) {
      error = getError(e);
    }

    setLoading(false);

    return { data, error };
  };

  return { send, loading };
}

export function usePut<T, D>(url: string, config?: AxiosRequestConfig): IUseWithInput<T, D> {
  const [loading, setLoading] = useState(false);

  const send = async (input: D, conf?: AxiosRequestConfig) => {
    setLoading(true);

    let data;
    let error;

    try {
      const response = await axiosClient.put<T, D>(conf?.url ?? url, input, { ...config, ...conf });

      data = response;
    } catch (e) {
      error = getError(e);
    }

    setLoading(false);

    return { data, error };
  };

  return { send, loading };
}

export function usePatch<T, D>(url: string, config?: AxiosRequestConfig): IUseWithInput<T, D> {
  const [loading, setLoading] = useState(false);

  const send = async (input: D, conf?: AxiosRequestConfig) => {
    setLoading(true);

    let data;
    let error;

    try {
      const response = await axiosClient.patch<T, D>(conf?.url ?? url, input, {
        ...config,
        ...conf,
      });

      data = response;
    } catch (e) {
      error = getError(e);
    }

    setLoading(false);

    return { data, error };
  };

  return { send, loading };
}

export function useDelete<T = void>(url: string, config?: AxiosRequestConfig): IUseDelete<T> {
  const [loading, setLoading] = useState(false);

  const send = async (conf?: AxiosRequestConfig) => {
    setLoading(true);

    let error;

    try {
      await axiosClient.delete<T>(conf?.url ?? url, { ...config, ...conf });
    } catch (e) {
      error = getError(e);
    }

    setLoading(false);

    return { error };
  };

  return { send, loading };
}
