export function concatArrayStrings(lista: string[]) {
  const tamanho = lista.length;

  if (tamanho === 0) {
    return '';
  } else if (tamanho === 1) {
    return lista[0];
  } else {
    const primeiros = lista.slice(0, tamanho - 1);

    const ultimo = lista[tamanho - 1];

    return `${primeiros.join(', ')} e ${ultimo}`;
  }
}
