import { ArrowForward, FormatListBulleted } from '@mui/icons-material';
import { IconButton, Menu, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuDisciplina, MenuDisciplinaContainer, MenuItemDisciplina } from './styles';

interface IMenuItem {
  id: string;
  text: string;
}

function MenuItem({ id, text }: IMenuItem) {
  const scrollTo = () => {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <MenuItemDisciplina>
      <ArrowForward />

      <Typography onClick={scrollTo}>{text}</Typography>
    </MenuItemDisciplina>
  );
}

const MenuItemMemo = React.memo(MenuItem);

export function DisciplinaMenu() {
  const [goToAnchor, setGoToAnchor] = useState<HTMLElement | null>(null);

  const location = useLocation();

  if (!location.pathname.includes('/disciplinas/')) return <></>;

  return (
    <MenuDisciplinaContainer>
      <IconButton onClick={(e) => setGoToAnchor(e.currentTarget)}>
        <FormatListBulleted sx={{ color: '#fff', fontSize: '2.1875rem' }} />
      </IconButton>

      <Menu anchorEl={goToAnchor} open={goToAnchor != null} onClose={() => setGoToAnchor(null)}>
        <MenuDisciplina>
          <Typography>Ir para</Typography>

          <MenuItemMemo id="disciplinas-pendentes" text="Disciplinas Pendentes" />

          <MenuItemMemo id="ementa" text="Ementa" />

          <MenuItemMemo id="objetivos" text="Objetivos" />

          <MenuItemMemo id="plano" text="Plano de Aula" />

          <MenuItemMemo id="avaliacao" text="Instrumento, critérios, calendários de avaliações" />

          <MenuItemMemo id="descricao" text="Descrição da Interdipliscinaridade" />

          <MenuItemMemo id="integracao" text="Integração com a filosofia institucional" />

          <MenuItemMemo id="bibliografia-basica" text="Bibliografia Básica" />

          <MenuItemMemo id="bibliografia-complementar" text="Bibliografia Complementar" />

          <MenuItemMemo id="obs" text="Observações" />
        </MenuDisciplina>
      </Menu>
    </MenuDisciplinaContainer>
  );
}

export const DisciplinaMenuMemo = React.memo(DisciplinaMenu);
