import { Collapse } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import { planoAulasFormSelector } from '#modules/disciplinas/redux/planoAulasFormSlice';
import { IPlanoAulaSchema } from '#modules/disciplinas/schemas/planoAulaSchema';
import { IAulaInfo } from '#modules/disciplinas/types/disciplina';

import { PlanoAulaCardFormMemo } from '../PlanoAulaCardForm';
import { CardContent, PlanoAulaCardContainer } from './styles';
import { PlanoAulaCardTitleMemo } from './title';
import { PlanoAulaCardValidateMemo } from './validate';

interface IPlanoAulaCard {
  aula: IAulaInfo;
}

export interface IPlanoAulaForm {
  chEad: string;
  chPresencial: string;
  chAed: string;
  conteudo: string;
  compHab: string[];
  objetivos: string[];
  metodologias: string[];
  bibliografiaBasica: string[];
  bibliografiaComplementar: string[];
  artigos: string[];
}

export interface IAulaFormValidation {
  chAed?: string;
  objetivos: string[];
  conteudo: string;
  metodologias: string[];
  referencias: string[];
  validacao?: boolean;
}

interface IGetFormStatus {
  values: IAulaFormValidation;
  isValid: boolean;
  validacao: boolean;
  notSaved: boolean;
}

function getFormStatus({ values, isValid, validacao, notSaved }: IGetFormStatus) {
  if (notSaved) return '#1985ed';

  if (validacao) {
    return '#adb5bd';
  } else {
    const defaultForm: Partial<IPlanoAulaSchema> = {
      chAed: '',
      conteudo: '',
      metodologias: [],
      referencias: [],
      objetivos: [],
    };

    const valorAtual: Partial<IPlanoAulaSchema> = {
      chAed: values.chAed,
      conteudo: values.conteudo,
      metodologias: values.metodologias,
      referencias: values.referencias,
      objetivos: values.objetivos,
    };

    if (JSON.stringify(defaultForm) === JSON.stringify(valorAtual)) {
      return '#E05959';
    } else if (isValid) {
      return '#8CC59A';
    } else {
      return '#f49e1e';
    }
  }
}

export function PlanoAulaCard({ aula }: IPlanoAulaCard) {
  const openAll = useAppSelector(planoAulasFormSelector.openAll);

  const [open, setOpen] = useState(openAll);
  const [statusColor, setStatusColor] = useState(() => {
    const isValid = aula.conteudo != null && aula.conteudo !== '';

    return getFormStatus({
      values: { ...aula },
      isValid,
      validacao: aula.validacao,
      notSaved: false,
    });
  });

  useEffect(() => {
    // Isso faz com que ao abrir todos de uma vez o componente passe por um re-render 2x ao invez de uma;
    // 1 re-render vem do openAll ter sido alterado, e outro do open State ter sido alterado;
    setOpen(openAll);
  }, [openAll]);

  const getStatusColor = useCallback(
    (form: IPlanoAulaSchema, isValid: boolean) => {
      const color = getFormStatus({
        values: form,
        isValid,
        validacao: aula.validacao,
        notSaved: isValid,
      });

      setStatusColor(color);
    },
    [aula.validacao],
  );

  const getStatusColorValidation = useCallback(
    (validation: boolean) => {
      const color = getFormStatus({
        values: { ...aula },
        isValid: aula.conteudo != null && aula.conteudo !== '',
        validacao: validation,
        notSaved: false,
      });

      setStatusColor(color);
    },
    [aula],
  );

  const getStatusColorAlterations = useCallback(
    (notSaved: boolean, aulaAtual: IAulaFormValidation) => {
      const color = getFormStatus({
        values: aulaAtual,
        isValid: aulaAtual.conteudo != null && aulaAtual.conteudo !== '',
        validacao: aulaAtual.validacao ?? false,
        notSaved,
      });

      setStatusColor(color);
    },
    [],
  );

  // console.log('PlanoAulaCard');

  return (
    <PlanoAulaCardContainer color={statusColor}>
      <header onClick={() => setOpen(!open)} style={{ backgroundColor: statusColor }}>
        <PlanoAulaCardTitleMemo aula={aula} getStatusColorAlterations={getStatusColorAlterations} />

        <PlanoAulaCardValidateMemo
          aula={aula}
          getStatusColorValidation={getStatusColorValidation}
        />
      </header>

      <Collapse in={open} timeout={250}>
        <CardContent>
          <PlanoAulaCardFormMemo aula={aula} getStatusColor={getStatusColor} />
        </CardContent>
      </Collapse>
    </PlanoAulaCardContainer>
  );
}

export const PlanoAulaCardMemo = React.memo(PlanoAulaCard);
