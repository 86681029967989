import { Box, Paper, styled, Typography } from '@mui/material';

export const HomeContainer = styled(Box)``;

export const MainContent = styled(Box)`
  flex: 1;
  background: #f7f7f7;

  padding: 2rem 1rem 1rem;

  > h2 {
    margin-top: 2rem;
    font-weight: 700;
    font-size: 1.375rem;
    font-family: 'Archivo';
  }

  div.status {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  div.pagination {
    display: flex;
    justify-content: center;

    > div {
      > p {
        text-align: center;
        margin-top: 0.5rem;
        font-weight: 700;
      }
    }
  }

  overflow: auto;
`;

export const UserBarContainer = styled(Box)`
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-left: 1rem;
  }
`;

export const Banner = styled(Paper)`
  background-color: #e8923d;
  color: #e5e5e5;
  border-radius: 20px;
  position: relative;
  min-height: 228px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;

  @media only screen and (max-width: 700px) {
    display: none;
  }

  > div {
    max-width: calc(100% - 279px);

    p.name {
      font-size: 2rem;
      font-weight: 700;
    }

    p.message {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  > img {
    position: absolute;
    right: 10px;
    bottom: -15px;
    height: 276px;
    width: 279px;
  }
`;

interface IStatus {
  color_status: string;
}

export const Status = styled(Typography)<IStatus>`
  position: relative;
  margin-left: 16px;
  display: flex;
  align-items: center;

  font-size: 0.625rem;
  color: #717070;
  font-weight: 500;

  & + p {
    margin-left: 26px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: -16px;
    background: ${(props) => props.color_status};
  }
`;

export const FormStyled = styled('form')`
  padding: 0rem 1rem 1rem;

  > button {
    margin-top: 1rem;

    border-radius: 0;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.625rem;
    transition: filter 0.2s;
  }
`;
