import * as yup from 'yup';

export interface ICreditosSchema {
  creditos: string;
  creditosEad: string;
  diasEad: string[];
  creditosPresencial: string;
  diasPresencial: string[];
  diasAulas: string[];
}

export const creditosSchema = yup.object().shape({
  creditosPresencial: yup
    .string()
    .required('É obrigatório')
    .test({
      test: (value, context) => {
        const { creditos, creditosEad } = context.parent as ICreditosSchema;

        if (value == null || value === '') {
          return context.createError({ message: 'É obrigatório', path: 'peso' });
        }

        const isNumber = /^[0-9]+$/.test(value);

        if (!isNumber) {
          return context.createError({
            message: 'Deve ser um numero inteiro',
          });
        }

        const presencialNumero = Number(value);

        const eadNumero = creditosEad != null && creditosEad !== '' ? Number(creditosEad) : 0;

        const creditosNumero = Number(creditos);

        if (presencialNumero + eadNumero !== creditosNumero) {
          return context.createError({
            message: 'A soma do presencial com EAD deve ser igual ao numero de creditos',
          });
        }

        return true;
      },
    }),
  creditosEad: yup.string().required('É obrigatório'),
  diasPresencial: yup
    .array()
    .min(1, 'É obrigatório o preenchimento')
    .test({
      test: (diasPresencial, context) => {
        const { diasAulas, diasEad } = context.parent as ICreditosSchema;

        if (diasPresencial == null || diasPresencial.length < 1) {
          return context.createError({ message: 'É obrigatório' });
        }

        const diasMap = diasAulas.reduce<Record<string, boolean>>(
          (map, dia) => ({ ...map, [dia]: true }),
          {},
        );

        const mapEad = diasEad.reduce<Record<string, boolean | null>>((map, dia) => {
          map[dia] = true;

          diasMap[dia] = false;

          return map;
        }, {});

        const diaRepetido = diasPresencial.reduce<boolean>((_, dia) => {
          diasMap[dia] = false;

          return mapEad[dia] === true;
        }, false);

        if (diaRepetido) {
          return context.createError({
            message: 'Não pode repetir dias entre presencial e ead',
          });
        }

        const imcompleto = Object.values(diasMap).some((value) => value);

        if (imcompleto) {
          return context.createError({
            message:
              'Todos os dias da semana devem ser selecionados pelo menos uma vez pelo presencial ou ead',
          });
        }

        return true;
      },
    }),
  diasEad: yup.array().min(1, 'É obrigatório o preenchimento'),
});
