import { Box, styled, Table, TableBody, TableHead, TableRow } from '@mui/material';

export const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 8px;

  .hide-1000 {
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  .hide-750 {
    @media only screen and (max-width: 750px) {
      display: none;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  td {
    border: 0;
    cursor: pointer;
  }
`;

export const StyledTableHead = styled(TableHead)`
  th {
    border: 0;
    font-weight: 500;
    color: #35353f;

    border-bottom: 1px solid #aaa;

    span.MuiBadge-badge {
      background: #e8923d;
      color: #fff;
    }
  }
`;

interface IStyledTableRow {
  border_color: string;
  open: 0 | 1;
}

export const StyledTableRow = styled(TableRow)<IStyledTableRow>`
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #bbb;

  td {
    font-weight: 500;
    color: #616161;

    &:first-of-type {
      border-radius: 10px 0 0 10px;
    }

    &:last-of-type {
      border-radius: 0 10px 10px 0;
    }
  }

  td.status {
    background-color: ${(props) => props.border_color};
    max-width: 3rem;
    min-height: 3rem;
    width: 3rem;
  }

  ${({ open }) =>
    open === 1 &&
    `
      border-radius: 10px 10px 0 0;

      box-shadow: 0px 12px 0px 0px #f4f4f4, 0 -2px 0px 0px #f7f7f7,
        2px 10px 4px -2px #bbb, -2px 10px 4px -2px #bbb;

      td {
        &:first-of-type {
          border-radius: 10px 0 0 0;
        }

        &:last-of-type {
          border-radius: 0 10px 0 0;
        }
      }
    `}
`;

export const TableRowCollapse = styled(TableRow)<IStyledTableRow>`
  background-color: #f4f4f4;
  box-shadow: 0px 2px 4px #bbb;
  border-radius: 0 0 10px 10px;

  td {
    border-radius: 0 0 10px 10px;
  }
`;

export const TableRowCollapseContent = styled(Box)`
  padding: 0 1rem 1rem;
  cursor: default;

  > div {
    padding-top: 1rem;
    border-top: 2px solid rgba(113, 112, 112, 0.25);

    > div {
      display: flex;

      & + div {
        margin-top: 0.5rem;
      }

      &.botoes a {
        margin-right: 1.5rem;
      }
    }
  }
`;
