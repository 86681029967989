import { Box, styled } from '@mui/material';

interface ISiteContainer {
  path: string;
}

export const SiteContainer = styled(Box)<ISiteContainer>`
  margin-top: ${(props) => (props.path === '/auth' ? '0px' : '64px')};

  height: ${(props) => (props.path === '/auth' ? '100vh' : 'calc(100vh - 64px)')};
  overflow: auto;
  overflow-x: hidden;
`;
