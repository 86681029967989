import { Box } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Router } from '#shared/routes';

import { disciplinaInfoActions } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { SiteContainer } from './styles';

export function SiteLayout() {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    dispatch(disciplinaInfoActions.clearData());
  }, [dispatch, params]);

  return (
    <SiteContainer path={location.pathname} id="main-scroll">
      <Box sx={{ width: '100vw' }}>
        <Router />
      </Box>
    </SiteContainer>
  );
}
