import { TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';

import { DisciplinasTableFormButtonMemo } from './formButton';
import { DisciplinasTableFormPopoverMemo } from './formPopover';
import { StyledTable, StyledTableHead } from './styles';
import { DisciplinasTableBodyMemo } from './tableBody';

export function DisciplinasTable() {
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell sx={{ maxWidth: '3rem', minWidth: '3rem', width: '3rem' }} />
            <TableCell>Nível/Campus</TableCell>
            <TableCell>Curso</TableCell>
            <TableCell>Disciplina</TableCell>
            <TableCell>Professor</TableCell>
            <TableCell>Turno/Turma</TableCell>
            <TableCell className="hide-750">Modalidade</TableCell>
            <TableCell className="hide-1000">Período Letivo</TableCell>
            <TableCell className="hide-1000">Prazo</TableCell>
            <TableCell>
              <DisciplinasTableFormButtonMemo updateFilterAnchor={setFilterAnchor} />
            </TableCell>
          </TableRow>
        </StyledTableHead>

        <DisciplinasTableBodyMemo />
      </StyledTable>

      <DisciplinasTableFormPopoverMemo
        filterAnchor={filterAnchor}
        updateFilterAnchor={setFilterAnchor}
      />
    </>
  );
}

export const DisciplinasTableMemo = React.memo(DisciplinasTable);
