import { IconButton, IconButtonProps } from '@mui/material';
import React, { ReactNode } from 'react';

import { CustomTooltipMemo } from '../CustomTooltip';

export type ICIconButtonProps = Omit<IconButtonProps, 'action'> & {
  title: string;
  action: (event: React.MouseEvent<HTMLButtonElement>) => void;
  Icon: ReactNode;
};

export function CustomIconButton({ title, action, Icon, disabled, ...props }: ICIconButtonProps) {
  if (disabled === true)
    return (
      <IconButton onClick={action} disabled={true} {...props}>
        {Icon}
      </IconButton>
    );

  return (
    <CustomTooltipMemo title={title}>
      <IconButton onClick={action} {...props}>
        {Icon}
      </IconButton>
    </CustomTooltipMemo>
  );
}

export const CustomIconButtonMemo = React.memo(CustomIconButton);
