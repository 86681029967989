import { Box, Paper, styled } from '@mui/material';

export const CarouselContainer = styled(Box)`
  margin-top: 2rem;

  > p {
    font-family: 'Archivo';
    font-size: 1.375rem;
    color: #35353f;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .carousel-container {
    padding: 0.3rem;
  }

  .no-result {
    font-family: 'Archivo';
    font-size: 1.125rem;
    color: #35353f;
    font-weight: 700;
    margin-left: 1.5rem;
  }
`;

interface ICarouselCard {
  color: string;
}

export const CarouselCard = styled(Paper)<ICarouselCard>`
  max-width: 95%;
  min-height: 164px;
  height: 100%;
  /* width: 380px; */
  border-left: 11px solid ${({ color }) => color};
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  padding: 1rem 1rem 0;
  color: #35353f;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    transform: scale(1.03);
  }

  p.campus {
    font-size: 0.75rem;
    font-weight: 500;
    text-align: right;
  }

  p.disciplina {
    font-size: 1rem;
    margin: 0.3rem 0;
    font-weight: 700;
  }

  div.info {
    display: flex;
    align-items: center;

    p {
      font-size: 0.75rem;
    }

    p.label {
      font-weight: 500;
      margin-right: 0.5rem;
    }
  }

  p.status {
    padding: 1rem;

    font-size: 0.8125rem;
    font-weight: 600;
    text-align: center;

    margin-top: auto;
  }
`;
