import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { BoxInfo } from './styles';

export function PlanoEnsinoObservacoes() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  if (disciplina == null) return <></>;

  return (
    <BoxInfo id="obs">
      <header>
        <Typography>OBSERVAÇÕES</Typography>
      </header>

      <div>
        <Typography>{disciplina.observacoes}</Typography>
      </div>
    </BoxInfo>
  );
}
