interface IStatusMap {
  [key: number]: {
    [key: number]: string | undefined;
  };
}

export const statusColors: IStatusMap = {
  1: {
    0: '#E05959',
    1: '#35649F',
    2: '#FDBF5F',
    3: '#adb5bd',
    4: '#8CC59A',
  },
  2: {
    0: '#E05959',
    1: '#35649F',
    2: '#FDBF5F',
    3: '#adb5bd',
    4: '#8CC59A',
  },
  3: {
    0: '#E05959',
    1: '#FDBF5F',
    2: '#35649F',
    3: '#adb5bd',
    4: '#8CC59A',
  },
  4: {
    0: '#E05959',
    1: '#35649F',
    2: '#FDBF5F',
    3: '#adb5bd',
    4: '#8CC59A',
  },
};

export const statusLabels: IStatusMap = {
  4: {
    0: 'Não entregue',
    1: 'Em preenchimento',
    2: 'Aguardando validação',
    3: 'Em revisão',
    4: 'Validado',
  },
  1: {
    0: 'Não entregue',
    1: 'Em preenchimento',
    2: 'Aguardando validação',
    3: 'Em revisão',
    4: 'Validado',
  },
  2: {
    0: 'Não entregue',
    1: 'Em preenchimento',
    2: 'Aguardando validação',
    3: 'Em revisão',
    4: 'Validado',
  },
  3: {
    0: 'Não preenchido',
    1: 'Em preenchimento',
    2: 'Enviado para coordenação',
    3: 'Necessita revisão',
    4: 'Recebido na secretaria',
  },
};

export const perfis = {
  1: 'Gestor',
  2: 'Coordenador',
  3: 'Professor',
  4: 'Visualização',
};

export const perfilEnum = {
  gestor: 1,
  coordenador: 2,
  professor: 3,
  visualizacao: 4,
};

export const statusEnum = {
  naoPreenchido: 0,
  emPreenchimento: 1,
  comCoordenador: 2,
  emValidacao: 3,
  naSecretaria: 4,
};

interface IStatusDescMap {
  [key: number]: Array<{ name: string; color: string }>;
}

export const statusDescMap: IStatusDescMap = {
  1: [
    { name: 'Não entregue', color: '#E05959' },
    { name: 'Em preenchimento', color: '#35649F' },
    { name: 'Aguardando validação', color: '#FDBF5F' },
    { name: 'Em revisão', color: '#adb5bd' },
    { name: 'Validado', color: '#8CC59A' },
  ],
  4: [
    { name: 'Não entregue', color: '#E05959' },
    { name: 'Em preenchimento', color: '#35649F' },
    { name: 'Aguardando validação', color: '#FDBF5F' },
    { name: 'Em revisão', color: '#adb5bd' },
    { name: 'Validado', color: '#8CC59A' },
  ],
  2: [
    { name: 'Não entregue', color: '#E05959' },
    { name: 'Em preenchimento', color: '#35649F' },
    { name: 'Aguardando validação', color: '#FDBF5F' },
    { name: 'Em revisão', color: '#adb5bd' },
    { name: 'Validado', color: '#8CC59A' },
  ],
  3: [
    { name: 'Não preenchido', color: '#E05959' },
    { name: 'Em preenchimento', color: '#FDBF5F' },
    { name: 'Enviado para coordenação', color: '#35649F' },
    { name: 'Necessita revisão', color: '#adb5bd' },
    { name: 'Recebido na secretaria', color: '#8CC59A' },
  ],
};
