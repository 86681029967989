import { Grid, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peObjetivosFormSelector } from '#modules/disciplinas/redux/peObjetivosFormSlice';

import { CompetenciaPerfilTextContainer } from './styles';

export function CompetenciasPerfilText() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const perfis = useAppSelector(peObjetivosFormSelector.perfis);
  const competencias = useAppSelector(peObjetivosFormSelector.competencias);
  const gerenciada = useAppSelector(peObjetivosFormSelector.selectedItem);

  if (disciplina == null || gerenciada == null) return <></>;

  return (
    <CompetenciaPerfilTextContainer>
      <Grid container spacing={2}>
        <Grid className="item" item xs={12} lg={6}>
          <section>
            <header>
              <Typography>COMPETÊNCIAS E HABILIDADES</Typography>
            </header>

            <main>
              {competencias[gerenciada.idTurmaDisc].map((competencia) => (
                <Typography key={competencia.item}>
                  <b>{competencia.item}</b> - {competencia.value}
                </Typography>
              ))}
            </main>
          </section>
        </Grid>

        <Grid className="item" item xs={12} lg={6}>
          <section>
            <header>
              <Typography>PERFIL DO EGRESSO</Typography>
            </header>

            <main>
              {perfis[gerenciada.idTurmaDisc].map((perfil) => (
                <Typography key={perfil.item}>
                  <b>{perfil.item}</b> - {perfil.value}
                </Typography>
              ))}
            </main>
          </section>
        </Grid>
      </Grid>
    </CompetenciaPerfilTextContainer>
  );
}

export const CompetenciasPerfilTextMemo = React.memo(CompetenciasPerfilText);
