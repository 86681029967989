import { Box, Pagination, Typography } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import React from 'react';

import {
  disciplinasFilterActions,
  disciplinasFilterSelector,
} from '#modules/home/redux/disciplinasFilterSlice';

export function HomePagination() {
  const pagination = useAppSelector(disciplinasFilterSelector.pagination);

  const dispatch = useAppDispatch();

  const changePage = (_: any, newPage: number) => {
    dispatch(disciplinasFilterActions.updatePage(newPage));

    document.getElementById('main-scroll')?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box className="pagination">
      <Box>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={pagination.totalPages ?? 1}
          page={pagination.page ?? 1}
          onChange={changePage}
        />
        <Typography>{pagination.totalResults ?? 0} Resultados</Typography>
      </Box>
    </Box>
  );
}

export const HomePaginationMemo = React.memo(HomePagination);
