import * as yup from 'yup';

import { textDurationToHours } from '#shared/utils/timeTransform';

export interface IOption {
  label: string;
  value: string;
}

export const tipoAulaOptions: IOption[] = [
  {
    label: 'Teórica',
    value: 'T',
  },
  {
    label: 'Prática',
    value: 'P',
  },
];

export const tipoAulaMap: Record<string, IOption> = {
  T: {
    label: 'Teórica',
    value: 'T',
  },
  P: {
    label: 'Prática',
    value: 'P',
  },
};

export interface IPlanoAulaSchema {
  numAulas: string;
  chPresencial: string;
  chEad: string;
  chAed?: string;
  textoAed?: string;
  tipoAula?: IOption;
  objetivos: string[];
  conteudo: string;
  metodologias: string[];
  referencias: string[];
}

export const planoAulaSchema = yup.object().shape({
  chPresencial: yup
    .string()
    .required('A CH Presencial é obrigatória')
    .test({
      test: (value, context) => {
        const { chEad, numAulas } = context.parent as IPlanoAulaSchema;

        const numberAulas = Number(numAulas);
        const hoursEad = textDurationToHours(chEad);
        const hoursPresencial = textDurationToHours(value ?? '00:00');

        if (hoursPresencial % 0.75 !== 0) {
          return context.createError({
            message: 'Deve ser multiplo de 45 minutos',
          });
        }

        if (hoursEad / 1 + hoursPresencial / 0.75 !== numberAulas) {
          return context.createError({
            message: 'CH diferente de Numero de Aulas',
          });
        }

        return true;
      },
    }),
  chEad: yup
    .string()
    .required('A CH EAD é obrigatória')
    .test({
      test: (value, context) => {
        const hoursEad = textDurationToHours(value ?? '00:00');

        if (hoursEad % 1 !== 0) {
          return context.createError({
            message: 'Deve ser multiplo de 1 hora',
          });
        }

        return true;
      },
    }),
  tipoAula: yup.object().nullable().required('O tipo aula é obrigatório'),
  objetivos: yup.array().min(1, 'Os objetivos são obrigatórios'),
  conteudo: yup
    .string()
    .required('O Conteudo é obrigatório')
    .test({
      test: (value, context) => {
        if (value != null && (value.includes('&') || value.includes('<'))) {
          return context.createError({
            message: 'Não pode conter o caracter & ou <',
          });
        }

        return true;
      },
    }),
  metodologias: yup.array().min(1, 'As metodologias são obrigatórias'),
  referencias: yup.array().min(1, 'As referências são obrigatórias'),
  textoAed: yup
    .string()
    .nullable()
    .test({
      test: (value, context) => {
        const { chAed } = context.parent as IPlanoAulaSchema;

        if (chAed !== '' && chAed != null) {
          if (value == null || value === '') {
            return false;
          }
        } else if (value != null && value !== '') {
          return context.createError({
            message: 'Só deve estar preenchido se tiver com a CH AED preenchido',
          });
        }

        if (value != null && (value.includes('&') || value.includes('<'))) {
          return context.createError({
            message: 'Não pode conter o caracter & ou <',
          });
        }

        return true;
      },
      message: 'A atividade é obrigatória',
    }),
  chAed: yup
    .string()
    .nullable()
    .test({
      test: (value) => {
        if (value != null && value !== '' && value.match(/^[0-9]{2}:[0-5]{1}[0-9]{1}$/g) == null)
          return false;

        return true;
      },
      message: 'Deve estar no formato 00:00',
    }),
});
