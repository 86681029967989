import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FormSelectAsyncMemo } from '#shared/components/form/FormSelectAsync';
import { FormTextFieldMemo } from '#shared/components/form/FormTextField';
import { useToast } from '#shared/hooks/toast';
import { useGet } from '#shared/services/useAxios';

import {
  defaultDisciplinasFilters,
  disciplinasFilterActions,
  disciplinasFilterSelector,
  IDisciplinasFilter,
} from '#modules/home/redux/disciplinasFilterSlice';
import {
  IFilial,
  IModalidade,
  IPeriodo,
  IStatus,
  // ITipoCurso,
  ITipoDisciplina,
} from '#modules/home/types/filters';

import { FormStyled } from './styles';

export function FilterFormDisciplinas() {
  const filtro = useAppSelector(disciplinasFilterSelector.filtro);
  const dispatch = useAppDispatch();

  const { toast } = useToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset: resetForm,
  } = useForm<IDisciplinasFilter>();

  const {
    data: periodosData,
    error: periodosError,
    send: getPeriodos,
    loading: periodosLoading,
  } = useGet<IPeriodo[]>({
    url: '/periodos',
    lazy: true,
  });

  const {
    data: statusData,
    error: statusError,
    send: getStatus,
    loading: statusLoading,
  } = useGet<IStatus[]>({
    url: '/status',
    lazy: true,
  });

  // const {
  //   data: tiposCursoData,
  //   error: tiposCursoError,
  //   send: getTiposCurso,
  //   loading: tiposCursoLoading,
  // } = useGet<ITipoCurso[]>({
  //   url: '/tipos_curso',
  //   lazy: true,
  // });

  const {
    data: filiaisData,
    error: filiaisError,
    send: getFiliais,
    loading: filiaisLoading,
  } = useGet<IFilial[]>({
    url: '/filiais',
    lazy: true,
  });

  const {
    data: tiposDisciplinaData,
    error: tiposDisciplinaError,
    send: getTiposDisciplina,
    loading: tiposDisciplinaLoading,
  } = useGet<ITipoDisciplina[]>({
    url: '/tipos_disciplina',
    lazy: true,
  });

  const {
    data: modalidadesData,
    error: modalidadesError,
    send: getModalidades,
    loading: modalidadesLoading,
  } = useGet<IModalidade[]>({
    url: '/modalidades',
    lazy: true,
  });

  useEffect(() => {
    if (periodosError != null) {
      toast({ message: periodosError, severity: 'error' });

      return;
    }

    // if (tiposCursoError != null) {
    //   toast({ message: tiposCursoError, severity: 'error' });

    //   return;
    // }

    if (filiaisError != null) {
      toast({ message: filiaisError, severity: 'error' });

      return;
    }

    if (tiposDisciplinaError != null) {
      toast({ message: tiposDisciplinaError, severity: 'error' });

      return;
    }

    if (modalidadesError != null) {
      toast({ message: modalidadesError, severity: 'error' });

      return;
    }

    if (statusError != null) {
      toast({ message: statusError, severity: 'error' });
    }
  }, [periodosError, statusError, filiaisError, tiposDisciplinaError, modalidadesError, toast]);

  const periodosOptions = useMemo(() => {
    const options = periodosData == null ? [] : periodosData;

    if (filtro.periodo != null) {
      const filter = options.find((item) => item.id === filtro.periodo?.id);

      if (filter == null) {
        options.push(filtro.periodo);
      }
    }

    // options.push({ id: '2022/2', nome: '2022/2' });

    return options;
  }, [filtro.periodo, periodosData]);

  const statusOptions = useMemo(() => {
    const options = statusData == null ? [] : statusData;

    if (filtro.status != null) {
      const filter = options.find((item) => item.id === filtro.status?.id);

      if (filter == null) {
        options.push(filtro.status);
      }
    }

    return options;
  }, [filtro.status, statusData]);

  // const tiposCursoOptions = useMemo(() => {
  //   const options = tiposCursoData == null ? [] : tiposCursoData;

  //   if (filtro.tipoCurso != null) {
  //     const filter = options.find((item) => item.id === filtro.tipoCurso?.id);

  //     if (filter == null) {
  //       options.push(filtro.tipoCurso);
  //     }
  //   }

  //   return options;
  // }, [filtro.tipoCurso, tiposCursoData]);

  const filiaisOptions = useMemo(() => {
    const options = filiaisData == null ? [] : filiaisData;

    if (filtro.filial != null) {
      const filter = options.find((item) => item.id === filtro.filial?.id);

      if (filter == null) {
        options.push(filtro.filial);
      }
    }

    return options;
  }, [filtro.filial, filiaisData]);

  const tiposDisciplinaOptions = useMemo(() => {
    const options = tiposDisciplinaData == null ? [] : tiposDisciplinaData;

    if (filtro.tipoDisciplina != null) {
      const filter = options.find((item) => item.id === filtro.tipoDisciplina?.id);

      if (filter == null) {
        options.push(filtro.tipoDisciplina);
      }
    }

    return options;
  }, [filtro.tipoDisciplina, tiposDisciplinaData]);

  const modalidadesOptions = useMemo(() => {
    const options = modalidadesData == null ? [] : modalidadesData;

    if (filtro.modalidade != null) {
      const filter = options.find((item) => item.id === filtro.modalidade?.id);

      if (filter == null) {
        options.push(filtro.modalidade);
      }
    }

    return options;
  }, [filtro.modalidade, modalidadesData]);

  const clearFilters = () => {
    dispatch(disciplinasFilterActions.clearFilter());

    resetForm(defaultDisciplinasFilters);
  };

  return (
    <FormStyled
      onSubmit={handleSubmit((form) => dispatch(disciplinasFilterActions.updateFilter(form)))}
      noValidate
    >
      <FormTextFieldMemo
        control={control}
        name="professor"
        label="Professor"
        defaultValue={filtro.professor}
        errors={errors.professor}
      />

      <FormTextFieldMemo
        control={control}
        name="disciplina"
        label="Disciplina"
        defaultValue={filtro.disciplina}
        errors={errors.disciplina}
      />

      <FormTextFieldMemo
        control={control}
        name="curso"
        label="Curso"
        defaultValue={filtro.curso}
        errors={errors.curso}
      />

      <FormSelectAsyncMemo
        control={control}
        name="periodo"
        label="Periodo"
        options={periodosOptions}
        optionLabel="nome"
        optionValue="id"
        defaultValue={filtro.periodo}
        errors={errors.periodo as any}
        loading={periodosLoading}
        handleOpen={async () => await getPeriodos()}
      />

      <FormSelectAsyncMemo
        control={control}
        name="status"
        label="Status"
        options={statusOptions}
        optionLabel="nome"
        optionValue="id"
        defaultValue={filtro.status}
        errors={errors.status as any}
        loading={statusLoading}
        handleOpen={async () => await getStatus()}
      />

      {/* <FormSelectAsyncMemo
        control={control}
        name="tipoCurso"
        label="Tipo de Curso"
        options={tiposCursoOptions}
        optionLabel="nome"
        optionValue="id"
        defaultValue={filtro.tipoCurso}
        errors={errors.tipoCurso as any}
        loading={tiposCursoLoading}
        handleOpen={async () => await getTiposCurso()}
      /> */}

      <FormSelectAsyncMemo
        control={control}
        name="filial"
        label="Campus"
        options={filiaisOptions}
        optionLabel="nome"
        optionValue="id"
        defaultValue={filtro.filial}
        errors={errors.filial as any}
        loading={filiaisLoading}
        handleOpen={async () => await getFiliais()}
      />

      <FormSelectAsyncMemo
        control={control}
        name="tipoDisciplina"
        label="Tipo de Disciplina"
        options={tiposDisciplinaOptions}
        optionLabel="nome"
        optionValue="id"
        defaultValue={filtro.tipoDisciplina}
        errors={errors.tipoDisciplina as any}
        loading={tiposDisciplinaLoading}
        handleOpen={async () => await getTiposDisciplina()}
      />

      <FormSelectAsyncMemo
        control={control}
        name="modalidade"
        label="Modalidade"
        options={modalidadesOptions}
        optionLabel="nome"
        optionValue="id"
        defaultValue={filtro.modalidade}
        errors={errors.modalidade as any}
        loading={modalidadesLoading}
        handleOpen={async () => await getModalidades()}
      />

      <Button type="submit" variant="contained" fullWidth>
        Filtrar
      </Button>

      <Button variant="contained" fullWidth onClick={() => clearFilters()}>
        Limpar Filtros
      </Button>
    </FormStyled>
  );
}

export const FilterFormDisciplinasMemo = React.memo(FilterFormDisciplinas);
