import { Clear, QueryBuilder, Today } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { CalendarPickerView, DatePicker } from '@mui/x-date-pickers';
import React, { useCallback, useMemo } from 'react';
import { Control, Controller, ControllerRenderProps, FieldError } from 'react-hook-form';

import { CustomIconButtonMemo } from '#shared/components/CustomIconButton';

interface IFormDatePicker {
  name: string;
  label?: string;
  control: Control<any>;
  defaultValue?: Date | null;
  errors?: FieldError;
  disabled?: boolean;
  marginType?: 'no-margin' | 'left-margin';
  required?: boolean;
  customView?: CalendarPickerView[];
  customOnChange?: (value: any) => void;
}

interface IHandleChance {
  newValue: any;
  field: ControllerRenderProps<any, string>;
}

export function FormDatePicker({
  control,
  label,
  name,
  defaultValue,
  errors,
  disabled,
  marginType,
  required,
  customView,
  customOnChange,
}: IFormDatePicker) {
  const sxFixed = useMemo(() => {
    let marginTop: string | undefined = '1em';
    let marginLeft: string | undefined;

    if (marginType != null) {
      marginTop = undefined;
    }

    if (marginType === 'left-margin') {
      marginLeft = '1em';
    }

    return {
      marginTop,
      marginLeft,
      width: '100%',
    };
  }, [marginType]);

  const handleChange = useCallback(
    ({ field, newValue }: IHandleChance) => {
      field.onChange(newValue);

      if (customOnChange != null) {
        customOnChange(newValue);
      }

      field.onBlur();
    },
    [customOnChange],
  );

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? null}
        render={({ field }) => (
          <>
            <DatePicker
              onChange={(newValue) => handleChange({ field, newValue })}
              value={field.value}
              disabled={disabled}
              views={customView ?? ['year', 'month', 'day']}
              showToolbar
              showDaysOutsideCurrentMonth
              components={{
                ActionBar: ({ onSetToday, onClear }) => (
                  <Box
                    display="flex"
                    sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}
                  >
                    <Button
                      variant="text"
                      size="medium"
                      onClick={onSetToday}
                      fullWidth
                      disabled={disabled}
                    >
                      Hoje
                    </Button>

                    <Button
                      variant="text"
                      size="medium"
                      onClick={onClear}
                      fullWidth
                      disabled={disabled}
                    >
                      Limpar
                    </Button>
                  </Box>
                ),
                OpenPickerIcon: () => <Today fontSize="medium" />,
              }}
              OpenPickerButtonProps={{
                size: 'small',
                sx: { marginRight: '-0.5rem', marginLeft: '-0.4rem' },
              }}
              renderInput={(textFieldProps) => (
                <TextField
                  {...textFieldProps}
                  required={required}
                  onBlur={(e) => {
                    field.onBlur();

                    if (textFieldProps.onBlur != null) {
                      textFieldProps.onBlur(e);
                    }
                  }}
                  label={label}
                  name={name}
                  error={!(errors == null)}
                  helperText={errors != null ? errors.message : ''}
                  InputProps={{
                    ...textFieldProps.InputProps,
                    endAdornment: (
                      <>
                        {field.value != null && (
                          <CustomIconButtonMemo
                            action={() => handleChange({ field, newValue: null })}
                            title="Limpar"
                            size="small"
                            sx={{ zIndex: 10, padding: '2px' }}
                            Icon={<Clear fontSize="small" />}
                            disabled={disabled}
                          />
                        )}

                        <CustomIconButtonMemo
                          action={() => handleChange({ field, newValue: new Date() })}
                          title="Agora"
                          size="small"
                          sx={{ zIndex: 10, padding: '2px' }}
                          Icon={<QueryBuilder fontSize="small" />}
                          disabled={disabled}
                        />

                        {textFieldProps.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                  sx={sxFixed}
                />
              )}
            />
          </>
        )}
      />
    </>
  );
}

export const FormDatePickerMemo = React.memo(FormDatePicker);
