import { Close, Done, Save } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';

import { peObjetivosFormSelector } from '#modules/disciplinas/redux/peObjetivosFormSlice';

import { ObjetivoFormTitleContainer } from './styles';

interface IObjetivoFormTitle {
  item: number;
}

export function ObjetivoFormTitle({ item }: IObjetivoFormTitle) {
  const disciplina = useAppSelector(peObjetivosFormSelector.selectedItem);
  const changedStatus = useAppSelector(peObjetivosFormSelector.changedStatus);
  const validStatus = useAppSelector(peObjetivosFormSelector.validStatus);

  const getTextAlterations = () => {
    if (disciplina == null) {
      return '';
    }

    const changed = changedStatus[item]?.[disciplina.idTurmaDisc] ?? false;

    return changed ? '**' : '';
  };

  const textAlteration = getTextAlterations();

  if (disciplina == null) return <></>;

  return (
    <ObjetivoFormTitleContainer>
      <Typography>
        Objetivo {item} {textAlteration}
      </Typography>

      {changedStatus[item]?.[disciplina.idTurmaDisc] ? (
        <Save sx={{ color: '#1985ed', fontSize: '1.5rem' }} />
      ) : validStatus[disciplina.idTurmaDisc][item] ? (
        <Done sx={{ color: '#8CC59A', fontSize: '1.5rem' }} />
      ) : (
        <Close sx={{ color: '#E05959', fontSize: '1.5rem' }} />
      )}
    </ObjetivoFormTitleContainer>
  );
}

export const ObjetivoFormTitleMemo = React.memo(ObjetivoFormTitle);
