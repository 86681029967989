import { CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useState } from 'react';
import React from 'react';

import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';
import { mapModalidadeValidacao } from '#shared/utils/getModalidadeTipo';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { planoAulasFormActions } from '#modules/disciplinas/redux/planoAulasFormSlice';
import { IAulaInfo } from '#modules/disciplinas/types/disciplina';

import { ValidateIcon } from './styles';

interface IPlanoAulaCardValidate {
  aula: IAulaInfo;
  getStatusColorValidation: (validation: boolean) => void;
}

export function PlanoAulaCardValidate({ aula, getStatusColorValidation }: IPlanoAulaCardValidate) {
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const [validate, setValidate] = useState(aula.validacao);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const changeValidate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setValidate(!validate);

    getStatusColorValidation(!validate);

    dispatch(planoAulasFormActions.updateValidation({ id: aula.data, validation: !validate }));
  };

  const cannotEdit = cannotEditDisciplina({
    user,
    disciplinaStatus,
    tipoStatus: 'coordenador',
    tipoPerfil: mapModalidadeValidacao[disciplina?.modalidade ?? 'Presencial'] ?? 'coordenador',
  });

  if (cannotEdit) return <></>;

  return (
    <>
      <ValidateIcon
        Icon={
          validate ? (
            <IndeterminateCheckBox sx={{ color: '#fff' }} />
          ) : (
            <CheckBoxOutlineBlank sx={{ color: '#fff' }} />
          )
        }
        action={changeValidate}
        title={validate ? 'Desmarcar a validação' : 'Marcar para validação'}
      />
    </>
  );
}

export const PlanoAulaCardValidateMemo = React.memo(PlanoAulaCardValidate);
