import { useAppSelector } from 'app/hooks';
import React from 'react';
import { Control } from 'react-hook-form';

import { FormSelectMemo } from '#shared/components/form/FormSelect';
import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IAvaliacaoForm,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { IAvaliacaoSchema } from '#modules/disciplinas/schemas/avaliacaoSchema';

interface IRecuperacaoInput {
  avaliacao: IAvaliacaoForm;
  control: Control<IAvaliacaoSchema, any>;
  handleFormChange: () => Promise<void>;
  error?: any;
}

export function RecuperacaoInput({
  avaliacao,
  control,
  handleFormChange,
  error,
}: IRecuperacaoInput) {
  const avaliacoes = useAppSelector(peAvaliacoesFormSelector.avaliacoes);
  const changedStatus = useAppSelector(peAvaliacoesFormSelector.changedItems);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);

  const { user } = useAuth();

  const idRecuperacaoOptions = Object.values(avaliacoes).filter((av) => {
    const isSaved = av.idAvaliacao !== 0;
    const isMedia = av.tipo === 'media';
    const isAnother = av.idAvaliacao !== avaliacao.idAvaliacao;
    const isNotChanged = !changedStatus[av.id] ?? true;

    return isSaved && isMedia && isAnother && isNotChanged;
  });

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus });

  return (
    <FormSelectMemo
      id={`idRecuperacao-${avaliacao.id}`}
      control={control}
      name="idRecuperacao"
      options={idRecuperacaoOptions}
      errors={error}
      marginType="no-margin"
      customOnChange={handleFormChange}
      placeholder="Selecione a avaliação a ser recuperada"
      optionLabel="sigla"
      optionValue="idAvaliacao"
      defaultValue={
        avaliacao.idRecuperacao != null ? { idAvaliacao: Number(avaliacao.idRecuperacao) } : null
      }
      disabled={cannotEdit}
    />
  );
}

export const RecuperacaoInputMemo = React.memo(RecuperacaoInput);
