import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

import { IPagingResult } from '#shared/types/IPagingResult';
import { parseDateApi } from '#shared/utils/parseDateApi';
import { statusColors, statusLabels } from '#shared/utils/status';

import { IDisciplina, IDisciplinasInfo } from '#modules/disciplinas/types/disciplina';
import { IUser } from '#modules/users/types/user';

import {
  IPeriodo,
  IStatus,
  ITipoCurso,
  IFilial,
  ITipoDisciplina,
  IModalidade,
} from '../types/filters';

export interface IDisciplinasFilter {
  professor: string;
  disciplina: string;
  curso: string;
  periodo: IPeriodo | null;
  status: IStatus | null;
  tipoCurso: ITipoCurso | null;
  filial: IFilial | null;
  tipoDisciplina: ITipoDisciplina | null;
  modalidade: IModalidade | null;
}

export const defaultDisciplinasFilters: IDisciplinasFilter = {
  disciplina: '',
  curso: '',
  professor: '',
  periodo: null,
  status: null,
  tipoCurso: null,
  filial: null,
  tipoDisciplina: null,
  modalidade: null,
};

interface ILoadingDisciplinas {
  disciplinasData: IPagingResult<IDisciplina> | undefined;
  user: IUser;
}

export interface IDisciplinasFilterState {
  page: number;
  filtro: IDisciplinasFilter;
  disciplinasData: IDisciplinasInfo[];
  disciplinasPagination: {
    page: number;
    totalResults: number;
    totalPages: number;
  };
}

const initialState: IDisciplinasFilterState = {
  page: 1,
  filtro: defaultDisciplinasFilters,
  disciplinasData: [],
  disciplinasPagination: {
    page: 1,
    totalPages: 1,
    totalResults: 0,
  },
};

export const disciplinasFilterSlice = createSlice({
  name: 'disciplinasFilter',
  initialState,
  reducers: {
    loadingDisciplinas: (
      state,
      { payload: { disciplinasData, user } }: PayloadAction<ILoadingDisciplinas>,
    ) => {
      if (disciplinasData != null) {
        state.disciplinasData = disciplinasData.data.map<IDisciplinasInfo>((disciplina) => {
          const statusColor = statusColors[user.perfilSelected.id][disciplina.status];
          const statusName = statusLabels[user.perfilSelected.id][disciplina.status];

          let nivel_campus = disciplina.nivel;

          if (disciplina.campus != null) {
            nivel_campus = `${nivel_campus} - ${disciplina.campus}`;
          }

          return {
            ...disciplina,
            nivel_campus,
            prazo: parseDateApi(disciplina.dataInicio, 'dd/MM', '-'),
            statusColor: statusColor ?? '#aaaaaa',
            statusName: statusName ?? 'Sem status',
          };
        });

        state.disciplinasPagination = {
          page: disciplinasData.pagination.page,
          totalPages: disciplinasData.pagination.totalPages,
          totalResults: disciplinasData.pagination.totalResults,
        };
      }
    },
    updateFilter: (state, { payload }: PayloadAction<IDisciplinasFilter>) => {
      state.filtro = payload;
      state.page = 1;
    },
    clearFilter: (state) => {
      state.filtro = { ...defaultDisciplinasFilters };
      state.page = 1;
    },
    updatePage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
  },
});

export const disciplinasFilterActions = disciplinasFilterSlice.actions;

export const disciplinasFilterSelector = {
  page: (state: RootState) => state.disciplinasFilter.page,
  filtro: (state: RootState) => state.disciplinasFilter.filtro,
  disciplinas: (state: RootState) => state.disciplinasFilter.disciplinasData,
  pagination: (state: RootState) => state.disciplinasFilter.disciplinasPagination,
};
