import { Typography } from '@mui/material';

import { ObjetivosFormsMemo } from '../ObjetivosForm';
import { ObjetivosCursoSelectorMemo } from './selector';
import { ObjetivosContainer } from './styles';
import { CompetenciasPerfilTextMemo } from './text';

export function Objetivos() {
  // console.log('Objetivos');

  return (
    <ObjetivosContainer id="objetivos">
      <header>
        <Typography>OBJETIVOS</Typography>
      </header>

      <main>
        <ObjetivosCursoSelectorMemo />

        <CompetenciasPerfilTextMemo />

        <ObjetivosFormsMemo />
      </main>
    </ObjetivosContainer>
  );
}
