import { Print } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useCallback, useState } from 'react';

import { CustomIconButtonMemo } from '#shared/components/CustomIconButton';
import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';
import { useGet } from '#shared/services/useAxios';
import { statusEnum } from '#shared/utils/status';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { IDisciplinaReport } from '#modules/disciplinas/types/disciplina';

import { CopiarGerencialButton } from './CopiarGerencialButton';
import { PdfModalGerenciadaMemo } from './modalGerenciada';

interface IPdfGenerator {
  idTurmaDisc: number;
}

export function PdfGenerator({ idTurmaDisc }: IPdfGenerator) {
  const disciplinaInfo = useAppSelector(disciplinaInfoSelector.disciplina);

  const [openModal, setOpenModal] = useState(false);

  const { toast } = useToast();

  const { loading: reportLoading, sendGet: getReport } = useGet<IDisciplinaReport>({
    url: '',
    lazy: true,
  });

  const generatePdf = useCallback(
    async (id?: number) => {
      const { data, error } = await getReport({
        url: `/disciplinas/${idTurmaDisc}/report`,
        params: {
          idTurmaDiscGerenciada: id,
        },
      });

      if (error != null) {
        toast({ message: error, severity: 'error' });
      }

      if (data != null && data.pdf !== '') {
        const linkSource = `data:application/pdf;base64,${data?.pdf}`;
        const link = document.getElementById('pdf-link') as any;
        const fileName = `Plano Ensino_${idTurmaDisc}.pdf`;

        link.href = linkSource;
        link.download = fileName;
        link.click();
      }
    },
    [getReport, idTurmaDisc, toast],
  );

  const textButton =
    disciplinaInfo?.status === statusEnum.naSecretaria ? 'Gerar PDF' : 'Gerar PDF - Não Oficial';

  if (disciplinaInfo == null) return <></>;

  return (
    <>
      <LoadingMemo loading={reportLoading} />

      <PdfModalGerenciadaMemo
        generatePdf={generatePdf}
        closeModal={() => setOpenModal(false)}
        open={openModal}
      />

      {disciplinaInfo.gerencial ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '1rem',
            alignItems: 'center',
          }}
        >
          <CopiarGerencialButton idTurmaDisc={idTurmaDisc} />

          <Box></Box>

          <CustomIconButtonMemo
            Icon={
              <Print sx={{ color: '#35649F', fontSize: '2.1875rem', justifySelf: 'flex-end' }} />
            }
            action={() => setOpenModal(true)}
            title={textButton}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '1rem' }}>
          <CustomIconButtonMemo
            Icon={<Print sx={{ color: '#35649F', fontSize: '2.1875rem' }} />}
            action={async () => await generatePdf()}
            title={textButton}
          />
        </Box>
      )}

      <a id="pdf-link" style={{ display: 'none' }} />
    </>
  );
}

export const PdfGeneratorMemo = React.memo(PdfGenerator);
