import { yupResolver } from '@hookform/resolvers/yup';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';

import { FormNumberFieldMemo } from '#shared/components/form/FormNumberField';
import { FormSelectMemo } from '#shared/components/form/FormSelect';
import { useAuth } from '#shared/hooks/auth';
import { getCargaHorariaPE } from '#shared/utils/cargaHoraria';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import {
  disciplinaInfoActions,
  disciplinaInfoSelector,
  IDisciplinaInfo,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { creditosSchema, ICreditosSchema } from '#modules/disciplinas/schemas/creditosSchema';
import { TipoModalidade } from '#modules/disciplinas/types/enums/TipoModalidade';

import { CargaHorariaTableContainer, TableStyled } from './styles';

interface IPECargaHorariaForm {
  disciplina: IDisciplinaInfo;
}

function PECargaHorariaForm({ disciplina }: IPECargaHorariaForm) {
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreditosSchema>({
    resolver: yupResolver(creditosSchema),
    mode: 'onSubmit',
  });

  const handleFormSubmit = (form: ICreditosSchema) => {
    dispatch(
      disciplinaInfoActions.updateCreditosDisciplina({
        creditosEad: Number(form.creditosEad),
        creditosPresencial: Number(form.creditosPresencial),
        diasEad: form.diasEad,
        diasPresencial: form.diasPresencial,
      }),
    );
  };

  const handleFormChange = async () => {
    await handleSubmit(handleFormSubmit)();
  };

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus, tipoPerfil: 'coordenador' });

  return (
    <TableRow>
      <TableCell colSpan={2}>
        <FormNumberFieldMemo
          sx={{ display: 'none' }}
          control={control}
          name="creditos"
          marginType="no-margin"
          scale={0}
          onBlur={handleFormChange}
          defaultValue={disciplina.creditos.toString()}
          disabled={cannotEdit}
        />

        <FormSelectMemo
          sx={{ display: 'none' }}
          control={control}
          name="diasAulas"
          options={disciplina.diasAulas}
          customOnChange={handleFormChange}
          defaultValue={disciplina.diasAulas}
          multiple
          disabled={cannotEdit}
        />
      </TableCell>

      <TableCell colSpan={2}>
        <FormNumberFieldMemo
          control={control}
          name="creditosPresencial"
          label="Creditos Presencial"
          errors={errors.creditosPresencial}
          marginType="no-margin"
          scale={0}
          onBlur={handleFormChange}
          defaultValue={disciplina.creditosPresencial?.toString() ?? ''}
          disabled={cannotEdit}
        />

        <FormSelectMemo
          control={control}
          label="Dias Semana Presencial"
          name="diasPresencial"
          options={disciplina.diasAulas}
          errors={errors.diasPresencial as any}
          customOnChange={handleFormChange}
          defaultValue={disciplina.diasPresencial ?? []}
          multiple
          helperText=" "
          disabled={cannotEdit}
        />
      </TableCell>

      <TableCell colSpan={2}>
        <FormNumberFieldMemo
          control={control}
          name="creditosEad"
          label="Creditos Ead"
          errors={errors.creditosEad}
          marginType="no-margin"
          scale={0}
          onBlur={handleFormChange}
          defaultValue={disciplina.creditosEad?.toString() ?? ''}
          disabled={cannotEdit}
        />

        <FormSelectMemo
          control={control}
          label="Dias Semana Ead"
          name="diasEad"
          options={disciplina.diasAulas}
          errors={errors.diasEad as any}
          customOnChange={handleFormChange}
          defaultValue={disciplina.diasEad ?? []}
          multiple
          helperText=" "
          disabled={cannotEdit}
        />
      </TableCell>
    </TableRow>
  );
}

const PECargaHorariaFormMemo = React.memo(PECargaHorariaForm);

export function PlanoEnsinoCargaHorariaTable() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const { creditosEad, creditosPresencial } = useAppSelector(disciplinaInfoSelector.creditos);

  const cargaHoraria = getCargaHorariaPE({
    curso: disciplina?.curso ?? '',
    creditos: disciplina?.creditos ?? 0,
    tipoModalidade: disciplina?.tipoModalidade ?? (TipoModalidade.presencial as any),
    creditosEad,
    creditosPresencial,
  });

  if (disciplina == null) return <></>;

  return (
    <CargaHorariaTableContainer>
      <TableStyled cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>PERÍODO LETIVO</TableCell>
            <TableCell>CRÉDITOS</TableCell>
            <TableCell>CARGA HORÁRIA EM SALA DE AULA (H)</TableCell>
            <TableCell>CARGA HORÁRIA EM ESPAÇOS DIVERSIFICADOS(H)</TableCell>
            <TableCell>CARGA HORÁRIA EAD (H)</TableCell>
            <TableCell>CARGA HORÁRIA Total (H)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{disciplina.periodoLetivo}</TableCell>
            <TableCell>{disciplina.creditos}</TableCell>
            <TableCell>{cargaHoraria.chPresencial ?? 'Sem Valor'}</TableCell>
            <TableCell>{cargaHoraria.chAed ?? 'Sem Valor'}</TableCell>
            <TableCell>{cargaHoraria.chEad ?? 'Sem Valor'}</TableCell>
            <TableCell>{cargaHoraria.chTotal ?? 'Sem Valor'}</TableCell>
          </TableRow>
          {disciplina.tipoModalidade === TipoModalidade.split && (
            <PECargaHorariaFormMemo disciplina={disciplina} />
          )}
        </TableBody>
      </TableStyled>
    </CargaHorariaTableContainer>
  );
}
