import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  planoAulasFormActions,
  planoAulasFormSelector,
} from '#modules/disciplinas/redux/planoAulasFormSlice';

import { PlanoAulaCardMemo } from '../PlanoAulaCard';
import { PlanoAulaCargaHorariaMemo } from './cargaHoraria';
import { PlanoAulasContainer, Status } from './styles';

export function PlanoAulas() {
  const allOpen = useAppSelector(planoAulasFormSelector.openAll);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const dispatch = useAppDispatch();

  if (disciplina == null) return <></>;

  // console.log('Plano Aulas');

  return (
    <PlanoAulasContainer id="plano">
      <header>
        <Typography>PLANO DE AULA</Typography>
      </header>

      <main>
        <Box className="info">
          <Box>
            <Status color_status="#8CC59A">Preenchido</Status>
            <Status color_status="#f49e1e">Incompleto</Status>
            <Status color_status="#E05959">Não Preenchido</Status>
            <Status color_status="#adb5bd">Validação</Status>
            <Status color_status="#1985ed">Não Está Salvo</Status>
          </Box>

          <Box>
            <Button variant="text" onClick={() => dispatch(planoAulasFormActions.handleOpenAll())}>
              {allOpen ? 'Fechar' : 'Abrir'} Todos
            </Button>
          </Box>
        </Box>

        <Box>
          {disciplina.aulas.map((aula) => (
            <PlanoAulaCardMemo aula={aula} key={aula.data} />
          ))}
        </Box>

        <PlanoAulaCargaHorariaMemo />
      </main>
    </PlanoAulasContainer>
  );
}
