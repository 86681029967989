import { Box, styled } from '@mui/material';

export const ObjetivosContainer = styled(Box)`
  margin-bottom: 20px;

  > header {
    background: #35649f;
    border: 2px solid #35353f;
    padding: 10px;

    p {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 1.375rem;
    }
  }

  > main {
    border: 2px solid #35353fcc;
    border-top: 0;
    padding: 1rem;
  }
`;

export const CompetenciaPerfilTextContainer = styled(Box)`
  display: flex;
  align-items: stretch;

  .item {
    display: flex;
  }

  section {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + section {
      margin-left: 1rem;
    }

    > header {
      background: #35649f;
      border: 2px solid #35353f;
      padding: 5px;

      > p {
        color: #fff;
        text-align: center;
        font-family: 'Archivo';
        font-size: 1.125rem;
      }
    }

    > main {
      border: 2px solid #35353fcc;
      border-top: 0;
      padding: 10px;
      flex: 1;

      > p {
        font-family: 'Archivo';
        font-size: 1.125rem;

        & + p {
          margin-top: 0.5rem;
        }
      }
    }
  }
`;
