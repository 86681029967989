import { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { TitleProvider } from './title';
import { ToastProvider } from './toast';

interface IAppProvider {
  children: ReactNode;
}

export function AppProvider({ children }: IAppProvider) {
  return (
    <ToastProvider>
      <TitleProvider>
        <AuthProvider>{children}</AuthProvider>
      </TitleProvider>
    </ToastProvider>
  );
}
