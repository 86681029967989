import { Box, ToggleButton, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { Control } from 'react-hook-form';

import { FormToggleGroupMemo } from '#shared/components/form/FormToggleGroup';
import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { IPlanoAulaSchema } from '#modules/disciplinas/schemas/planoAulaSchema';
import { IAulaInfo } from '#modules/disciplinas/types/disciplina';

import { ReferenciasInput } from './styles';

interface IPlanoAulaCardFormReferencias {
  aula: IAulaInfo;
  control: Control<IPlanoAulaSchema, any>;
  handleFormChange: () => Promise<void>;
  errors?: any;
}

const normalReferenciasOptions = [
  <ToggleButton value="B1" key="B1">
    <Typography>B1</Typography>
  </ToggleButton>,
  <ToggleButton value="B2" key="B2">
    <Typography>B2</Typography>
  </ToggleButton>,
  <ToggleButton value="B3" key="B3" sx={{ marginRight: '40%' }}>
    <Typography>B3</Typography>
  </ToggleButton>,
  <ToggleButton value="C1" key="C1">
    <Typography>C1</Typography>
  </ToggleButton>,
  <ToggleButton value="C2" key="C2">
    <Typography>C2</Typography>
  </ToggleButton>,
  <ToggleButton value="C3" key="C3">
    <Typography>C3</Typography>
  </ToggleButton>,
  <ToggleButton value="C4" key="C4">
    <Typography>C4</Typography>
  </ToggleButton>,
  <ToggleButton value="C5" key="C5">
    <Typography>C5</Typography>
  </ToggleButton>,
];

const artigosReferenciasOptions = [
  <ToggleButton value="A1" key="A1">
    <Typography>A1</Typography>
  </ToggleButton>,
  <ToggleButton value="A2" key="A2">
    <Typography>A2</Typography>
  </ToggleButton>,
  <ToggleButton value="A3" key="A3">
    <Typography>A3</Typography>
  </ToggleButton>,
];

const referenciasOptions: Record<'normal' | 'artigo', JSX.Element[]> = {
  normal: normalReferenciasOptions,
  artigo: [...normalReferenciasOptions, ...artigosReferenciasOptions],
};

export function PlanoAulaCardFormReferencias({
  errors,
  aula,
  control,
  handleFormChange,
}: IPlanoAulaCardFormReferencias) {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);

  const { user } = useAuth();

  const type = disciplina?.artigos != null && disciplina.artigos !== '' ? 'artigo' : 'normal';

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus });

  return (
    <ReferenciasInput error={errors.referencias != null ? 1 : 0}>
      <label htmlFor={`referencias-${aula.data}`}>Referências Bibliográficas</label>

      <Box>
        <FormToggleGroupMemo
          id={`referencias-${aula.data}`}
          control={control}
          defaultValue={aula.referencias}
          name="referencias"
          size="small"
          customOnChange={handleFormChange}
          customOptions={referenciasOptions[type]}
          disabled={cannotEdit}
        />
      </Box>

      <Typography className="error">
        {errors.referencias != null
          ? Array.isArray(errors.referencias)
            ? errors.referencias[0].message
            : errors.referencias.message
          : `   `}
      </Typography>
    </ReferenciasInput>
  );
}

export const PlanoAulaCardFormReferenciasMemo = React.memo(PlanoAulaCardFormReferencias);
