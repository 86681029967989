import * as yup from 'yup';

export interface IObsAvaliacaoSchema {
  obs: string;
}

export const obsAvaliacaoSchema = yup.object().shape({
  obs: yup
    .string()
    .max(4000, 'Não pode ter mais de 4000 caracteres')
    .test({
      test: (value, context) => {
        if (value != null && (value.includes('&') || value.includes('<'))) {
          return context.createError({
            message: 'Não pode conter o caracter & ou <',
          });
        }

        return true;
      },
    }),
});
