import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import logo from '#static/disciplinas/logo_unasp.png';

import { PlanoEnsinoHeaderContainer } from './styles';

export function PlanoEnsinoHeader() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  if (disciplina == null) return <></>;

  return (
    <PlanoEnsinoHeaderContainer>
      <Box className="logo">
        <img src={logo} />
      </Box>

      <Box className="curso">
        <Typography>
          Campus / Modalidade: {disciplina.campus} / {disciplina.modalidade}
        </Typography>

        <Typography>Curso: {disciplina.curso}</Typography>

        <Typography>Turma: {disciplina.codTurma}</Typography>
      </Box>
    </PlanoEnsinoHeaderContainer>
  );
}
