import { createTheme, ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { store } from 'app/store';
import { ptBR } from 'date-fns/locale';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { SiteAppBar } from '#shared/components/AppBar';
import { Navbar } from '#shared/components/Navbar';
import { SiteLayout } from '#shared/components/SiteLayout';
import { AppProvider } from '#shared/hooks';
import { ScrollToTop } from '#shared/routes/scrollToTop';
import { cssGlobal } from '#shared/styles/global.styles';

const theme = createTheme({
  palette: {
    primary: { main: '#020560' },
  },
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
  },
});

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <GlobalStyles styles={cssGlobal} />

      <Provider store={store}>
        <AppProvider>
          <BrowserRouter>
            <ScrollToTop />

            <LocalizationProvider dateAdapter={DateAdapter} adapterLocale={ptBR}>
              {/* <Navbar /> */}

              <SiteAppBar />

              <SiteLayout />
            </LocalizationProvider>
          </BrowserRouter>
        </AppProvider>
      </Provider>
    </ThemeProvider>
  );
}
