import { Box } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';
import { useGet } from '#shared/services/useAxios';

import { DisciplinasSendMemo } from '#modules/disciplinas/components/DisciplinaSendButton';
import { DisciplinasPendentesMemo } from '#modules/disciplinas/components/DisciplinasPendentes';
import { PlanoEnsinoMemo } from '#modules/disciplinas/components/PlanoEnsino';
import { PopupInfoMemo } from '#modules/disciplinas/components/PopupInfo';
import { disciplinaInfoActions } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peAvaliacoesFormActions } from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { IDisciplinaGet } from '#modules/disciplinas/types/disciplina';

// import { DisciplinasBannerDuvidasMemo } from './banner';
import { DisciplinaFeedbackMemo } from './feedback';
import { DisciplinasHeaderMemo } from './header';
import { DisciplinaMenuMemo } from './menu';
import { DisciplinasSaveMemo } from './save';
import { DisciplinaContainer } from './styles';

export function Disciplinas() {
  const { toast } = useToast();
  const params = useParams();
  const dispatch = useAppDispatch();

  const {
    data: disciplinaData,
    error: disciplinaError,
    loading: disciplinaLoading,
    send: getDisciplinas,
  } = useGet<IDisciplinaGet>({ url: '', lazy: true });

  useEffect(() => {
    void getDisciplinas({ url: `/disciplinas/${params.id}` });

    void dispatch(peAvaliacoesFormActions.getNaturezasAsync());
  }, [dispatch, getDisciplinas, params.id]);

  useEffect(() => {
    if (disciplinaError != null) {
      toast({ message: disciplinaError, severity: 'error' });
    }
  }, [disciplinaError, toast]);

  useEffect(() => {
    if (disciplinaData != null) {
      dispatch(disciplinaInfoActions.updateDisciplinaInfo(disciplinaData));
    }
  }, [disciplinaData, dispatch, params]);

  return (
    <DisciplinaContainer>
      <LoadingMemo loading={disciplinaLoading} />

      <DisciplinasSaveMemo />

      <DisciplinaMenuMemo />

      <DisciplinasHeaderMemo />

      <PopupInfoMemo />

      <main>
        <Box>
          {/* <DisciplinasBannerDuvidasMemo /> */}

          <DisciplinasPendentesMemo />

          <DisciplinaFeedbackMemo />

          <PlanoEnsinoMemo />

          <DisciplinasSendMemo />
        </Box>
      </main>
    </DisciplinaContainer>
  );
}
