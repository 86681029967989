import { Box, styled } from '@mui/material';

interface IReferenciaInput {
  error: 0 | 1;
}

export const ReferenciasInput = styled(Box)<IReferenciaInput>`
  display: flex;
  flex: 1;
  flex-direction: column;

  > p {
    font-weight: 600;
    font-family: 'Montserrat';
    color: #35353f;
  }

  .row + .row {
    margin-top: 1rem;
  }

  p.error {
    color: #d32f2f;
    font-size: 0.65rem;
    margin-top: 3px;
    margin-left: 14px;
  }

  > div {
    padding: 0.5rem;
    border: 1px solid ${({ error }) => (error === 1 ? '#d32f2f' : '#aaa')};
    flex: 1;

    .MuiToggleButtonGroup-root {
      display: flex;
      flex-wrap: wrap;

      & + div {
        margin-top: 1rem;
      }

      .MuiButtonBase-root {
        border: 1px solid #aaa;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        margin-left: 10px !important;
        margin-top: 10px !important;

        /* & + .MuiButtonBase-root {
          margin-left: 10px;
        } */

        &.Mui-selected {
          background: #28639c;
          margin-left: 10px;
          color: #fff;
        }
      }
    }
  }
`;

export const FormAulasStyled = styled('form')`
  div.input {
    > p,
    label {
      font-weight: 600;
      font-family: 'Montserrat';
      color: #35353f;
    }
  }

  div.referencia {
    margin: auto;
    width: 250px;
    min-height: 180px;
    display: flex;
  }
`;
