import { CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useState } from 'react';
import React from 'react';

import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';
import { mapModalidadeValidacao } from '#shared/utils/getModalidadeTipo';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IAvaliacaoForm,
  peAvaliacoesFormActions,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

import { FloatIcon } from './styles';

interface IAvaliacaoCardValidate {
  avaliacao: IAvaliacaoForm;
  getStatusColorValidation: (validation: boolean) => void;
}

export function AvaliacaoCardValidate({
  avaliacao,
  getStatusColorValidation,
}: IAvaliacaoCardValidate) {
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const [validate, setValidate] = useState(avaliacao.validacao ?? false);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const changeValidate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setValidate(!validate);

    getStatusColorValidation(!validate);

    dispatch(peAvaliacoesFormActions.updateValidation({ id: avaliacao.id, validation: !validate }));
  };

  const cannotEdit = cannotEditDisciplina({
    user,
    disciplinaStatus,
    tipoStatus: 'coordenador',
    tipoPerfil: mapModalidadeValidacao[disciplina?.modalidade ?? 'Presencial'] ?? 'coordenador',
  });

  if (cannotEdit) return <></>;

  return (
    <>
      <FloatIcon
        Icon={
          validate ? (
            <IndeterminateCheckBox sx={{ color: '#fff' }} />
          ) : (
            <CheckBoxOutlineBlank sx={{ color: '#fff' }} />
          )
        }
        action={changeValidate}
        title={validate ? 'Desmarcar a validação' : 'Marcar para validação'}
      />
    </>
  );
}

export const AvaliacaoCardValidateMemo = React.memo(AvaliacaoCardValidate);
