import { Tune } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useMemo } from 'react';

import { removeEmptyFields } from '#shared/utils/removeEmptyFields';

import { disciplinasFilterSelector } from '#modules/home/redux/disciplinasFilterSlice';

interface IDisciplinasTableFormButton {
  updateFilterAnchor: (element: HTMLElement | null) => void;
}

export function DisciplinasTableFormButton({ updateFilterAnchor }: IDisciplinasTableFormButton) {
  const filtro = useAppSelector(disciplinasFilterSelector.filtro);

  const activeFiltersNumber = useMemo(() => {
    return Object.values(removeEmptyFields(filtro, true)).filter((data) => data).length;
  }, [filtro]);

  return (
    <Badge badgeContent={activeFiltersNumber}>
      <IconButton size="small" onClick={(e) => updateFilterAnchor(e.currentTarget)}>
        <Tune />
      </IconButton>
    </Badge>
  );
}

export const DisciplinasTableFormButtonMemo = React.memo(DisciplinasTableFormButton);
