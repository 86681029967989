export const metodologias = [
  'Aula expositiva dialogada',
  'Aula Invertida',
  'Aulas de Campo',
  'Atividades de aprendizagem',
  'Discussão e Debate',
  'Dramatização',
  'Ensino com pesquisa',
  'Ensino Individualizado (orientação)',
  'Estudo de Caso',
  'Estudo de texto',
  'Estudo Dirigido',
  'Estudo do Meio',
  'Excursões',
  'Exposições',
  'Foruns de discussão',
  'Grupos de Oposição',
  'Grupos de Verbalização e de Observação',
  'Jogos',
  'Júri Simulado',
  'Laboratório',
  'Mapa Conceitual',
  'Oficinas',
  'Painel',
  'PBL - Problem Based Learning',
  'Pequenos Grupos',
  'Portfólio',
  'Problematização',
  'Projetos',
  'Resolução de Exercícios',
  'Seminário',
  'Simpósio',
  'Simulação Realística',
  'TBL - Team Based Learning',
  'Tempestade Cerebral',
  'Visitas Técnicas',
];
