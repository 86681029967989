import { Box } from '@mui/material';
import React from 'react';

import { ObjetivoFormMemo } from './form';

export function ObjetivosForms() {
  return (
    <Box>
      <ObjetivoFormMemo item={1} />

      <ObjetivoFormMemo item={2} />

      <ObjetivoFormMemo item={3} />
    </Box>
  );
}

export const ObjetivosFormsMemo = React.memo(ObjetivosForms);

/**
 * São 3 Forms independentes (Para o objetivo 1, 2 e 3)
 *
 * Cada um desses forms vão ter que selecionar as competencias, perfis, taxionomia e descrição do objetivo
 *
 * Existem as disciplinas gerencias, nelas cada curso vai ter que preencher
 */
