import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from 'app/hooks';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { DisciplinaTableContainer, TableStyled } from './styles';

export function PlanoEnsinoDisciplinaTable() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  if (disciplina == null) return <></>;

  return (
    <DisciplinaTableContainer>
      <TableStyled cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>DISCIPLINA</TableCell>
            <TableCell>CÓD. DISCIPLINA</TableCell>
            <TableCell>PROFESSOR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{disciplina.disciplina}</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>{disciplina.codDisciplina}</TableCell>
            <TableCell>{disciplina.professor}</TableCell>
          </TableRow>
        </TableBody>
      </TableStyled>
    </DisciplinaTableContainer>
  );
}
