import { Button } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { CustomSelectMemo } from '#shared/components/inputs/CustomSelect';

import { peObjetivosFormSelector } from '#modules/disciplinas/redux/peObjetivosFormSlice';

interface ISelectItem {
  idTurmaDisc: number;
  name: string;
}

interface IPdfModalGerenciada {
  open: boolean;
  closeModal: () => void;
  generatePdf: (id: number) => Promise<void>;
}

export function PdfModalGerenciada({ open, closeModal, generatePdf }: IPdfModalGerenciada) {
  const options = useAppSelector(peObjetivosFormSelector.itemsOptions);

  const [turma, setTurma] = useState<ISelectItem | null>(null);

  return (
    <CustomDialogMemo open={open} title="Selecione uma turma" closeModal={closeModal} maxWidth="sm">
      <CustomSelectMemo
        label="Curso"
        onChange={(item) => setTurma(item)}
        options={options}
        value={turma}
        optionLabel="name"
        optionValue="idTurmaDisc"
        fullWidth
        marginType="no-margin"
      />

      {turma != null && (
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: '1rem' }}
          onClick={async () => await generatePdf(turma.idTurmaDisc)}
        >
          Gerar Relatório
        </Button>
      )}
    </CustomDialogMemo>
  );
}

export const PdfModalGerenciadaMemo = React.memo(PdfModalGerenciada);
