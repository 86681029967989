import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

export interface IToggleOption {
  value: string;
  label: string;
  className?: string;
}

type IFormToggleGroup = ToggleButtonGroupProps & {
  name: string;
  control: Control<any>;
  defaultValue: string[];
  marginType?: 'no-margin' | 'left-margin';
  options?: IToggleOption[];
  customOptions?: JSX.Element[];
  customOnChange?: (value: any) => void;
};

export function FormToggleGroup({
  name,
  control,
  defaultValue,
  options = [{ label: 'teste', value: 'teste' }],
  customOnChange,
  customOptions,
  ...props
}: IFormToggleGroup) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <ToggleButtonGroup
          {...props}
          {...field}
          onChange={(_, value) => {
            field.onChange(value);

            field.onBlur();

            if (customOnChange != null) {
              customOnChange(value);
            }
          }}
        >
          {customOptions != null
            ? customOptions
            : options.map((option) => (
                <ToggleButton key={option.value} value={option.value} className={option.className}>
                  <Typography>{option.label}</Typography>
                </ToggleButton>
              ))}
        </ToggleButtonGroup>
      )}
    />
  );
}

export const FormToggleGroupMemo = React.memo(FormToggleGroup);
