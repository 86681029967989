import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { disciplinaInfoSlice } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peAvaliacoesFormSlice } from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { peObjetivosFormSlice } from '#modules/disciplinas/redux/peObjetivosFormSlice';
import { planoAulasFormSlice } from '#modules/disciplinas/redux/planoAulasFormSlice';
import { disciplinasFilterSlice } from '#modules/home/redux/disciplinasFilterSlice';

export const store = configureStore({
  reducer: {
    planoAulasForm: planoAulasFormSlice.reducer,
    disciplinaInfo: disciplinaInfoSlice.reducer,
    peAvaliacoesForm: peAvaliacoesFormSlice.reducer,
    peObjetivosForm: peObjetivosFormSlice.reducer,
    disciplinasFilter: disciplinasFilterSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
