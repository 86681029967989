import { Routes, Route } from 'react-router-dom';

import { PrivateRoute } from '#shared/routes/private';

import { Disciplinas } from '../pages/Disciplinas';

export function DisciplinasRoutes() {
  return (
    <Routes>
      <Route
        path="/:id"
        element={
          <PrivateRoute>
            <Disciplinas />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
