import { Box, styled } from '@mui/material';

export const AvaliacaoContainer = styled(Box)`
  margin-bottom: 20px;

  > header {
    background: #35649f;
    border: 2px solid #35353f;
    padding: 10px;

    p {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 1.375rem;
    }
  }

  > main {
    border: 2px solid #35353fcc;
    border-top: 0;
    padding: 1rem;

    div.info {
      display: flex;
      justify-content: space-between;

      margin-bottom: 1rem;

      > div {
        display: flex;
        align-items: center;
      }
    }

    button.add {
      margin-top: 1rem;
      border-radius: 10px 10px 0 0;
      font-family: 'Archivo';
      font-size: 1.25rem;
    }
  }
`;

export const FormulaMediaContainer = styled(Box)`
  margin-top: 2rem;
  border: 1px solid #35353f;
  display: flex;
  align-items: stretch;

  .formula {
    padding: 1rem;
  }

  .resumo {
    padding: 1rem;
    border-left: 1px solid #35353f;
    height: 100%;

    @media only screen and (max-width: 900px) {
      border-top: 1px solid #35353f;
      border-left: 0;
    }

    > span {
      margin-left: 15px;
      font-size: 1.125rem;
      font-family: 'Archivo';
      font-weight: 600;
    }
  }

  p {
    font-family: 'Archivo';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 35px;
  }
`;
