import { Typography, Box, Button } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import React, { useState } from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';
import { usePost } from '#shared/services/useAxios';
import { CustomButton } from '#shared/styledComponents/common';

import {
  disciplinaInfoSelector,
  disciplinaInfoActions,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peAvaliacoesFormSelector } from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { planoAulasFormSelector } from '#modules/disciplinas/redux/planoAulasFormSlice';
import {
  IErrorValidation,
  IDisciplinaValidationResponse,
  IDisciplinaReopen,
} from '#modules/disciplinas/types/disciplina';

import { DisciplinasSendErrorsModalMemo } from './errorsModal';
import { SendSecretariaContainer } from './styles';

export function SendSecretaria() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaIdPlanoEnsino = useAppSelector(disciplinaInfoSelector.idPlanoEnsino);
  const aulas = useAppSelector(planoAulasFormSelector.planoAulas);
  const avaliacoes = useAppSelector(peAvaliacoesFormSelector.avaliacoes);

  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState<IErrorValidation[] | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const { toast } = useToast();

  const { loading: validateLoading, send: validateDisciplina } = usePost<
    IDisciplinaValidationResponse,
    IDisciplinaReopen
  >(`/disciplinas/validation/${disciplina?.idTurmaDisc}/validate`);

  const sendSecretaria = async () => {
    if (disciplina?.idTurmaDisc != null) {
      const { data, error } = await validateDisciplina({ idPlanoEnsino: disciplinaIdPlanoEnsino });

      if (error != null) {
        toast({ message: error, severity: 'error' });
      }

      if (data != null) {
        setOpenDialog(false);

        if (data.valid) {
          dispatch(disciplinaInfoActions.updateStatus(4));

          toast({ message: 'Plano de ensino enviado para secretaria', severity: 'success' });

          setErrorsValidation(null);
        } else {
          setErrorsValidation(data.errorsList);

          setOpenErrorDialog(true);
        }
      }
    }
  };

  const canSendSecretaria =
    Object.values(aulas).filter((aula) => aula.validacao).length === 0 &&
    Object.values(avaliacoes).filter((avaliacao) => avaliacao.validacao === true).length === 0;

  return (
    <>
      <LoadingMemo loading={validateLoading} />

      <DisciplinasSendErrorsModalMemo
        open={openErrorDialog}
        errors={errorsValidation ?? []}
        closeModal={() => setOpenErrorDialog(false)}
      />

      <CustomDialogMemo
        open={openDialog}
        title="Confirmação"
        closeModal={() => setOpenDialog(false)}
        maxWidth="xs"
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            textAlign: 'justify',
          }}
        >
          Apertar esse botão, significa que o arquivo foi preenchido da maneira correta e será
          encaminhado para a secretaria.
        </Typography>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '0.9375rem',
            fontFamily: 'Montserrat',
            mt: '1rem',
            textAlign: 'justify',
          }}
        >
          Sendo assim, tem certeza que deseja enviar o arquivo?
        </Typography>

        <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center' }}>
          <CustomButton
            variant="contained"
            custom_color="#5BAB68"
            fullWidth
            sx={{ mr: '1rem' }}
            onClick={async () => await sendSecretaria()}
          >
            Sim
          </CustomButton>

          <CustomButton
            variant="contained"
            custom_color="#E03E1A"
            fullWidth
            onClick={() => setOpenDialog(false)}
          >
            Não
          </CustomButton>
        </Box>
      </CustomDialogMemo>

      <SendSecretariaContainer>
        <Box className="button">
          {errorsValidation != null && (
            <Button variant="contained" onClick={() => setOpenErrorDialog(true)}>
              Mostrar erros ({errorsValidation.length})
            </Button>
          )}

          <Box>
            <Button
              disabled={!canSendSecretaria}
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              Enviar arquivo para a secretaria
            </Button>

            {!canSendSecretaria && (
              <Typography>Retire as validações das aulas e avaliações antes de enviar</Typography>
            )}
          </Box>
        </Box>
      </SendSecretariaContainer>
    </>
  );
}

export const SendSecretariaMemo = React.memo(SendSecretaria);
