import { Cached, Warning } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { red } from '@mui/material/colors';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { CustomIconButton } from '#shared/components/CustomIconButton';
import { CustomTooltipMemo } from '#shared/components/CustomTooltip';
import { FormAutoComplete } from '#shared/components/form/FormAutoComplete';
import { getOptionsForm } from '#shared/utils/getOptionsForm';

import {
  peAvaliacoesFormActions,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

import { INaturezasSelectInputProps } from './types';

export function NaturezasSelectInput({
  control,
  error,
  disabled,
  handleFormChange,
  defaultValue,
}: INaturezasSelectInputProps) {
  const statusNaturezas = useAppSelector(peAvaliacoesFormSelector.statusNaturezas);
  const errorNaturezas = useAppSelector(peAvaliacoesFormSelector.errorNaturezas);
  const naturezas = useAppSelector(peAvaliacoesFormSelector.naturezas);

  const dispatch = useAppDispatch();

  const options = getOptionsForm({
    data: naturezas,
    transformData: (natureza) => ({
      label: natureza.nome,
      value: natureza,
    }),
    defaultOption: defaultValue,
  }).sort((x, y) => (x.value.presencial && !y.value.presencial ? -1 : 1));

  function HandleGetAreas() {
    void dispatch(peAvaliacoesFormActions.getNaturezasAsync());
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
      }}
    >
      <FormAutoComplete
        control={control}
        name="natureza"
        label="Tipo de avaliação"
        options={options}
        defaultValue={defaultValue}
        formError={error}
        onChange={handleFormChange}
        AutoCompleteProps={{
          filterSelectedOptions: false,
          disabled: disabled || statusNaturezas !== 'fulfilled',
          fullWidth: true,
          groupBy: (o) => (o.value.presencial ? 'presencial' : 'ead'),
        }}
      />

      {statusNaturezas === 'pending' && <CircularProgress size={20} />}

      {statusNaturezas === 'rejected' && (
        <>
          <CustomTooltipMemo title={errorNaturezas ?? 'Aconteceu algum erro ao carregar as areas'}>
            <Warning sx={{ ml: '1rem', color: red[700] }} />
          </CustomTooltipMemo>

          <CustomIconButton
            color="inherit"
            Icon={<Cached />}
            title="Tentar Novamente"
            action={HandleGetAreas}
          />
        </>
      )}
    </Box>
  );
}
