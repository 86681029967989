import { Box, Collapse } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import {
  IAvaliacaoForm,
  IAvaliacaoType,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { IAvaliacaoSchema } from '#modules/disciplinas/schemas/avaliacaoSchema';

import { AvaliacaoCardFormMemo } from '../AvaliacaoCardForm';
import { AvaliacaoCardDeleteMemo } from './delete';
import { AvaliacaoCardContainer, CardContent } from './styles';
import { AvaliacaoCardTitleMemo } from './title';
import { AvaliacaoCardValidateMemo } from './validate';

interface IAvaliacaoCard {
  avaliacao: IAvaliacaoForm;
  numero: number;
}

interface IGetFormStatus {
  values: {
    tipo: IAvaliacaoType;
    data: string | null;
    sigla: string;
    nome: string;
    peso: string;
    bonus: string;
    idRecuperacao: string | null;
  };
  isValid: boolean;
  validacao: boolean;
  notSaved: boolean;
}

type IFormMap = {
  [key in IAvaliacaoType]: Partial<IAvaliacaoForm>;
};

function getFormStatus({ values, isValid, validacao, notSaved }: IGetFormStatus) {
  if (notSaved) return '#1985ed';

  if (validacao) {
    return '#adb5bd';
  } else {
    const defaultFormMap: IFormMap = {
      media: {
        data: null,
        sigla: '',
        nome: '',
        peso: '',
      },
      bonus: {
        data: null,
        sigla: '',
        nome: '',
        bonus: '',
      },
      recuperacao: {
        data: null,
        sigla: '',
        idRecuperacao: null,
      },
    };

    const valuesFormMap: IFormMap = {
      media: {
        data: values.data,
        sigla: values.sigla,
        nome: values.nome,
        peso: values.peso,
      },
      bonus: {
        data: values.data,
        sigla: values.sigla,
        nome: values.nome,
        bonus: values.bonus,
      },
      recuperacao: {
        data: values.data,
        sigla: values.sigla,
        idRecuperacao: values.idRecuperacao,
      },
    };

    if (
      JSON.stringify(defaultFormMap[values.tipo]) === JSON.stringify(valuesFormMap[values.tipo])
    ) {
      return '#E05959';
    } else if (isValid) {
      return '#8CC59A';
    } else {
      return '#f49e1e';
    }
  }
}

export function AvaliacaoCard({ avaliacao, numero }: IAvaliacaoCard) {
  const openAll = useAppSelector(peAvaliacoesFormSelector.openAll);

  const [open, setOpen] = useState(openAll);
  const [statusColor, setStatusColor] = useState(() => {
    const isValid = avaliacao.sigla != null;

    return getFormStatus({
      values: {
        ...avaliacao,
        nome: avaliacao.nome ?? '',
        peso: avaliacao.peso ?? '',
        bonus: avaliacao.bonus ?? '',
        idRecuperacao: avaliacao.idRecuperacao ?? null,
      },
      isValid,
      validacao: avaliacao.validacao ?? false,
      notSaved: false,
    });
  });

  useEffect(() => {
    // Isso faz com que ao abrir todos de uma vez o componente passe por um re-render 2x ao invez de uma;
    // 1 re-render vem do openAll ter sido alterado, e outro do open State ter sido alterado;
    setOpen(openAll);
  }, [openAll]);

  const getStatusColor = useCallback(
    (form: IAvaliacaoSchema, isValid: boolean) => {
      const color = getFormStatus({
        values: {
          ...form,
          nome: form.nome ?? '',
          peso: form.peso ?? '',
          bonus: form.bonus ?? '',
          idRecuperacao: form.idRecuperacao?.idAvaliacao ?? null,
          data: form.data != null ? form.data.toISOString() : null,
        },
        isValid,
        validacao: avaliacao.validacao ?? false,
        notSaved: isValid,
      });

      setStatusColor(color);
    },
    [avaliacao.validacao],
  );

  const getStatusColorValidation = useCallback(
    (validation: boolean) => {
      const color = getFormStatus({
        values: {
          ...avaliacao,
          nome: avaliacao.nome ?? '',
          peso: avaliacao.peso ?? '',
          bonus: avaliacao.bonus ?? '',
          idRecuperacao: avaliacao.idRecuperacao ?? null,
        },
        isValid: avaliacao.sigla != null,
        validacao: validation,
        notSaved: false,
      });

      setStatusColor(color);
    },
    [avaliacao],
  );

  const getStatusColorAlterations = useCallback(
    (notSaved: boolean) => {
      const color = getFormStatus({
        values: {
          ...avaliacao,
          nome: avaliacao.nome ?? '',
          peso: avaliacao.peso ?? '',
          bonus: avaliacao.bonus ?? '',
          idRecuperacao: avaliacao.idRecuperacao ?? null,
        },
        isValid: avaliacao.sigla != null,
        validacao: avaliacao.validacao ?? false,
        notSaved,
      });

      setStatusColor(color);
    },
    [avaliacao],
  );

  return (
    <AvaliacaoCardContainer color={statusColor}>
      <header>
        <Box className="clickable" onClick={() => setOpen(!open)}>
          <AvaliacaoCardTitleMemo
            avaliacao={avaliacao}
            numero={numero}
            getStatusColorAlterations={getStatusColorAlterations}
          />
        </Box>

        <AvaliacaoCardDeleteMemo avaliacao={avaliacao} numero={numero} />

        <AvaliacaoCardValidateMemo
          avaliacao={avaliacao}
          getStatusColorValidation={getStatusColorValidation}
        />
      </header>

      <Collapse in={open} timeout={250}>
        <CardContent>
          <AvaliacaoCardFormMemo
            avaliacao={avaliacao}
            getStatusColor={getStatusColor}
            numero={numero}
          />
        </CardContent>
      </Collapse>
    </AvaliacaoCardContainer>
  );
}

export const AvaliacaoCardMemo = React.memo(AvaliacaoCard);
