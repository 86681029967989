import * as yup from 'yup';

import { IItemValue } from '../types/disciplina';

export interface IObjetivoSchema {
  descricao: string;
  competencias: IItemValue[];
  perfis: IItemValue[];
  taxonomia: string | null;
}

export const objetivoSchema = yup.object().shape({
  descricao: yup
    .string()
    .required('A descricao é obrigatória')
    .test({
      test: (value, context) => {
        if (value != null && (value.includes('&') || value.includes('<'))) {
          return context.createError({
            message: 'Não pode conter o caracter & ou <',
          });
        }

        return true;
      },
    }),
  taxonomia: yup.string().nullable().required('A taxonomia é obrigatória'),
  perfis: yup.array().min(1, 'Os perfis são obrigatórios'),
  competencias: yup.array().min(1, 'As competencias são obrigatórias'),
});
