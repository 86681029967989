import { Box, styled, Typography } from '@mui/material';

export const PlanoAulasContainer = styled(Box)`
  margin-bottom: 20px;

  > header {
    background: #35649f;
    border: 2px solid #35353f;
    padding: 10px;

    p {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 1.375rem;
    }
  }

  > main {
    border: 2px solid #35353fcc;
    border-top: 0;
    padding: 1rem;

    div.info {
      display: flex;
      justify-content: space-between;

      margin-bottom: 1rem;

      > div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

interface IStatus {
  color_status: string;
}

export const Status = styled(Typography)<IStatus>`
  position: relative;
  margin-left: 16px;
  display: flex;
  align-items: center;

  font-size: 0.625rem;
  color: #717070;
  font-weight: 500;

  & + p {
    margin-left: 26px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: -16px;
    background: ${(props) => props.color_status};
  }
`;

export const TotalPlanoAula = styled(Box)`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */

  margin-top: 3rem;
  border: 1px solid #35353f;

  div.title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #35353f;

    p {
      font-family: 'Archivo';
      font-size: 1.125rem;
      font-weight: 600;
      color: #35353f;
    }
  }

  div.resultados {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    > div {
      max-width: 1300px;
    }

    div.icon {
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }

    p {
      font-family: 'Archivo';
      font-size: 0.9375rem;
      color: #35353f;
      text-align: center;

      &.label {
        font-weight: 700;
      }

      &.valor {
        font-weight: 500;
        text-align: center;
      }
    }
  }
`;
