import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// StrictMode vai fazer renderizer algumas coisas 2 vezes em dev para testa algumas coisas,
// Deixar desativado caso voce queira chegar problemas de re-renders
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
