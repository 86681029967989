import * as yup from 'yup';

import { IAutoCompleteOptions } from '#shared/types/autocomplete';

import { IAvaliacaoType } from '../redux/peAvaliacoesFormSlice';
import { INaturezaAvaliacao } from '../types/disciplina';

export interface IAvaliacaoSchema {
  data: Date | null;
  sigla: string;
  nome?: string;
  peso?: string;
  idRecuperacao?: { idAvaliacao: string; sigla: string } | null;
  bonus?: string;
  tipo: IAvaliacaoType;
  aed?: boolean;
  modalidade?: number;
  natureza: IAutoCompleteOptions<INaturezaAvaliacao> | null;
}

export const avaliacaoSchema = yup.object().shape({
  sigla: yup
    .string()
    .required('A sigla é obrigatória')
    .max(9, 'A Sigla deve ter no máximo 9 caracteres')
    .test({
      test: (value, context) => {
        if (value != null && (value.includes('&') || value.includes('<'))) {
          return context.createError({
            message: 'Não pode conter o caracter & ou <',
          });
        }

        return true;
      },
    }),
  data: yup.date().nullable().required('A data é obrigatória'),
  natureza: yup
    .object()
    .nullable()
    .test({
      test: (value, context) => {
        const { tipo } = context.parent as IAvaliacaoSchema;

        if (tipo === 'media') {
          if (value == null) {
            return false;
          }
        }

        return true;
      },
      message: 'Campo obrigatório',
    }),
  modalidade: yup
    .string()
    .nullable()
    .test({
      test: (value, context) => {
        const { tipo } = context.parent as IAvaliacaoSchema;

        if (tipo === 'media') {
          if (value == null) {
            return false;
          }
        }

        return true;
      },
      message: 'Campo obrigatório',
    }),
  peso: yup.string().test({
    test: (value, context) => {
      const { tipo } = context.parent as IAvaliacaoSchema;

      if (tipo === 'media') {
        if (value == null || value === '') {
          return context.createError({ message: 'O peso é obrigatório' });
        }

        const isNumber = /^[0-9]+$/.test(value);

        const number = Number(value);

        if (!isNumber || number < 1 || number > 100) {
          return false;
        }
      }

      return true;
    },
    message: 'Deve ser um numero inteiro entre 1 e 100',
  }),
  bonus: yup.string().test({
    test: (value, context) => {
      const { tipo } = context.parent as IAvaliacaoSchema;

      if (tipo === 'bonus') {
        if (value == null || value === '') {
          return context.createError({ message: 'O bonus é obrigatório' });
        }

        const isNumber = /^([0-9]{1,2})(,{0,1})([0-9]{0,2})$/.test(value);

        const number = Number(value);

        if (!isNumber || number < 0 || number > 10) {
          return false;
        }
      }

      return true;
    },
    message: 'Deve ser um numero entre 1 e 10',
  }),
  idRecuperacao: yup
    .object()
    .nullable()
    // .transform((value) => value?.idAvaliacao ?? null)
    .test({
      test: (value, context) => {
        const { tipo } = context.parent as IAvaliacaoSchema;

        if (tipo === 'recuperacao') {
          if (value == null) {
            return false;
          }
        }

        return true;
      },
      message: 'A recuperação é obrigatória',
    }),
  nome: yup
    .string()
    .max(90, 'Deve possuir no maximo 90 caracteres')
    .test({
      test: (value, context) => {
        const { tipo } = context.parent as IAvaliacaoSchema;

        if (tipo !== 'recuperacao') {
          if (value == null || value === '') {
            return false;
          }
        }

        if (value != null && (value.includes('&') || value.includes('<'))) {
          return context.createError({
            message: 'Não pode conter o caracter & ou <',
          });
        }

        return true;
      },
      message: 'O instrumento de Avaliação é obrigatório',
    }),
});
