import { Box, IconButton, Paper, styled } from '@mui/material';

export const DisciplinaContainer = styled(Box)`
  min-height: 100vh;
  margin-left: 270px;

  @media only screen and (max-width: 1300px) {
    margin-left: 0;
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 3rem;
    background: #f7f7f7;
    max-width: 1560px;

    margin: auto;

    h1 {
      color: #35353f;
      font-size: 1.875rem;
      font-weight: 800;
      font-family: 'Archivo';
    }
  }

  > main {
    height: 100%;
    max-width: 1560px;

    margin: auto;

    padding: 1rem 0rem;

    > div {
      background: #f7f7f7;
      padding: 1rem;
    }
  }
`;

export const BannerDuvidas = styled(Paper)`
  background-color: #cfe6fd;
  color: #35353f;
  border-radius: 20px;
  position: relative;
  min-height: 228px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;

  > div {
    max-width: calc(100% - 321px);

    > p {
      font-weight: 500;
      font-size: 1.5rem;
    }

    > div {
      display: flex;
      align-items: center;

      > p {
        margin-left: 5px;
        font-size: 0.9375rem;
        font-weight: 500;
      }
    }
  }

  > img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 321px;
  }
`;

export const SaveButton = styled(IconButton)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  background: #020560;
  color: #fff;

  &:hover {
    background: #020560;
  }
`;

export const FeedbackContainer = styled(Box)`
  margin-top: 2rem;
  border: 1px solid #d0d0d0;
  padding: 20px 1rem 1rem;

  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 16px;
    top: 0;
    left: 0;
    background-color: #7678d7;
  }

  > h3 {
    font-family: 'Archivo';
    font-weight: 700;
    font-size: 1.375rem;
    margin-bottom: 0.5rem;
  }

  > p {
    font-family: 'Archivo';
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const MenuContainer = styled(Box)`
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  width: 270px;
  background: #020560;
  border-top: 1px solid #f5f6f5;

  @media only screen and (max-width: 1300px) {
    display: none;
  }
`;

export const MenuDisciplina = styled(Box)`
  width: 100%;

  padding: 1rem;

  > p {
    color: #f5f6f5;
    font-family: 'Archivo';
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

export const MenuItemDisciplina = styled(Box)`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 0.5rem;
  }

  > p {
    color: #f5f6f5;
    font-size: 1rem;
    font-family: 'Archivo';
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
      font-weight: 700;
    }
  }
`;
