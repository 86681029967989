import { Autocomplete, styled } from '@mui/material';
import { Popper } from '@mui/material';

export const PopperStyled = styled(Popper)`
  border: 1px solid #666;
  border-radius: 5px;
`;

export const AutocompleteStyled = styled(Autocomplete)`
  .Mui-disabled {
    opacity: 0.75 !important;
  }
`;
