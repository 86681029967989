import { styled } from '@mui/material';

export const StyledAvaliacaoForm = styled('form')`
  div.radio {
    display: flex;
    justify-content: center;

    label + label {
      margin-left: 4rem;

      @media only screen and (max-width: 700px) {
        margin-left: 1rem;
      }
    }
  }

  div.input-row {
    margin-top: 1rem;

    display: flex;

    div.input {
      & + div.input {
        margin-left: 1rem;
      }

      > p {
        font-weight: 600;
        font-family: 'Montserrat';
        color: #35353f;
      }
    }
  }
`;
