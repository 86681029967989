export const mapDiaTextoNumero: Record<string, string> = {
  // eslint-disable-next-line prettier/prettier
  'domingo': '1',
  'segunda-feira': '2',
  'terça-feira': '3',
  'quarta-feira': '4',
  'quinta-feira': '5',
  'sexta-feira': '6',
  // eslint-disable-next-line prettier/prettier
  'sábado': '7',
};

export const mapDiaNumeroTexto: Record<string, string> = {
  '1': 'domingo',
  '2': 'segunda-feira',
  '3': 'terça-feira',
  '4': 'quarta-feira',
  '5': 'quinta-feira',
  '6': 'sexta-feira',
  '7': 'sábado',
};
