import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface ILoadingProps {
  loading: boolean;
}

export function Loading({ loading }: ILoadingProps) {
  return (
    <Backdrop open={loading} sx={{ zIndex: 9999 }}>
      <CircularProgress color="primary" size={75} />
    </Backdrop>
  );
}

export const LoadingMemo = React.memo(Loading);
