import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { DisciplinasRoutes } from '#modules/disciplinas/routes';
import { Home } from '#modules/home/pages/Home';
import { Auth } from '#modules/users/pages/Auth';

import { PrivateRoute } from './private';

export function Router() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');

        const element = document.getElementById(id);

        if (element != null) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      <Route
        path="/disciplinas/*"
        element={
          <PrivateRoute>
            <DisciplinasRoutes />
          </PrivateRoute>
        }
      />

      <Route path="/auth" element={<Auth />} />
    </Routes>
  );
}
