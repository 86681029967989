import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';

import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';

import {
  peAvaliacoesFormActions,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

export function DeleteStatusAvaliacoes() {
  const deleteLoading = useAppSelector(peAvaliacoesFormSelector.loadingDelete);
  const deleteError = useAppSelector(peAvaliacoesFormSelector.deleteError);
  const dispatch = useAppDispatch();

  const { toast } = useToast();

  useEffect(() => {
    if (deleteError != null) {
      toast({ message: deleteError, severity: 'error' });

      dispatch(peAvaliacoesFormActions.showError());
    }
  }, [deleteError, dispatch, toast]);

  return <LoadingMemo loading={deleteLoading} />;
}

export const DeleteStatusAvaliacoesMemo = React.memo(DeleteStatusAvaliacoes);
