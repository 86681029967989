import { Box, Paper, styled } from '@mui/material';

import { CustomIconButtonMemo } from '#shared/components/CustomIconButton';

interface IAvaliacaoCardContainer {
  color: string;
}

export const AvaliacaoCardContainer = styled(Paper)<IAvaliacaoCardContainer>`
  border-radius: 10px 10px 5px 5px;

  & + div {
    margin-top: 1rem;
  }

  > header {
    border-radius: 10px 10px 0 0;
    background: ${({ color }) => color};
    cursor: pointer;
    position: relative;

    > div.clickable {
      width: calc(100% - 40px);
      height: 100%;
      padding: 0.5rem;

      > p {
        font-size: 1.25rem;
        font-family: 'Archivo';
        text-align: center;
        color: #fff;
      }
    }
  }
`;

export const CardContent = styled(Box)`
  padding: 1rem;

  div.type {
    display: flex;
    justify-content: center;

    label + label {
      margin-left: 4rem;
    }
  }
`;

export const FloatIcon = styled(CustomIconButtonMemo)`
  position: absolute;

  right: 5px;
  top: 5px;
`;
