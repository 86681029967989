import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { IAutoCompleteOptions } from '#shared/types/autocomplete';

import type { IFormAutoCompleteProps } from './types';

export function FormAutoComplete<T extends FieldValues, D>({
  name,
  label,
  control,
  defaultValue,
  AutoCompleteProps,
  options,
  TextFieldProps,
  formError,
  loading,
  onChange,
}: IFormAutoCompleteProps<T, D>) {
  function getOptionLabel(option: IAutoCompleteOptions<D>) {
    return option.label;
  }

  function isOptionEqualToValue(option: IAutoCompleteOptions<D>, value: IAutoCompleteOptions<D>) {
    return option.label === value.label;
  }

  const inputError = formError != null;

  const helperText = inputError ? formError.message : TextFieldProps?.helperText;

  const defaultValueInput = defaultValue ?? (AutoCompleteProps?.multiple === true ? [] : null);

  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      defaultValue={defaultValueInput as any}
      render={({ field }) => (
        <Autocomplete<IAutoCompleteOptions<D>, boolean | undefined, boolean | undefined, false>
          {...field}
          {...AutoCompleteProps}
          noOptionsText="Nenhuma opção disponivel"
          options={options ?? []}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(e, data) => {
            field.onChange(data);

            field.onBlur();

            if (onChange != null) {
              onChange(e, data);
            }
          }}
          renderOption={
            AutoCompleteProps?.renderOption != null
              ? AutoCompleteProps?.renderOption
              : (props, option) => (
                  <li {...props} key={option.label}>
                    {option.label}
                  </li>
                )
          }
          renderTags={
            AutoCompleteProps?.renderTags != null
              ? AutoCompleteProps.renderTags
              : (tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip {...getTagProps({ index })} key={option.label} label={option.label} />
                  ))
          }
          renderInput={(params) => (
            <TextField
              {...TextFieldProps}
              {...params}
              name={name}
              label={label}
              error={inputError}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading === true ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
