import { ReportProblem } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { addMonths, isBefore } from 'date-fns';
import React, { useState } from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { CustomButton } from '#shared/styledComponents/common';

import { PopupContainer } from './styles';

const SHOW_MODAL_KEY = '@plano_ensino:showModalInfo';
const EXPIRES_AT_MODAL = '@plano_ensino:expiresAtModal';

export function PopupInfo() {
  const [open, setOpen] = useState(() => {
    const showModalInfokey = localStorage.getItem(SHOW_MODAL_KEY);
    const expiresAtKey = localStorage.getItem(EXPIRES_AT_MODAL);

    const expiresAtDate = expiresAtKey != null ? new Date(expiresAtKey) : null;

    const showModal = showModalInfokey === 'true';

    const optionExpired = expiresAtDate == null || isBefore(expiresAtDate, new Date());

    return showModal || optionExpired;
  });

  function handleCloseSemiPermantent() {
    const newExpireDate = addMonths(new Date(), 5);

    localStorage.setItem(SHOW_MODAL_KEY, 'false');
    localStorage.setItem(EXPIRES_AT_MODAL, newExpireDate.toISOString());

    setOpen(false);
  }

  return (
    <CustomDialogMemo
      open={open}
      closeModal={() => setOpen(false)}
      title="IMPORTANTE"
      maxWidth="md"
      zIndex={9999999}
    >
      <PopupContainer>
        <ReportProblem color="error" />

        <Box className="text">
          <Typography sx={{ fontSize: '1.4rem', color: red[800] }}>IMPORTANTE</Typography>

          <Typography sx={{ fontSize: '1.2rem', color: red[800] }}>
            O PLANO DE ENSINO NÃO SALVA AUTOMATICAMENTE
          </Typography>

          <Typography sx={{ fontSize: '1rem' }}>
            Para isso, o professor <b>PRECISA</b> clicar no ícone disquete desenhado na lateral
            direita da tela.
          </Typography>

          <Typography sx={{ fontSize: '1rem' }}>
            Faça isso sempre que alimentar alguma parte do plano para garantir o salvamento.
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="1rem"
            mt="2rem"
          >
            <Button variant="contained" onClick={() => setOpen(false)}>
              Fechar
            </Button>

            <CustomButton custom_color={grey[400]} onClick={handleCloseSemiPermantent}>
              Não Mostrar isso novamente
            </CustomButton>
          </Box>
        </Box>
      </PopupContainer>
    </CustomDialogMemo>
  );
}

export const PopupInfoMemo = React.memo(PopupInfo);
