import { IDiasSemanaMap } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { ITipoModalidade } from '#modules/disciplinas/types/disciplina';
import { TipoModalidade } from '#modules/disciplinas/types/enums/TipoModalidade';

export interface ICargaHoraria {
  chDiversificado: number | null;
  chEad: number | null;
  chPresencial: number | null;
  chTotal: number;
}

interface IGetCargaHorariaPe {
  tipoModalidade: ITipoModalidade;
  creditos: number;
  curso: string;
  creditosEad?: number;
  creditosPresencial?: number;
}

export function getCargaHorariaPE({
  tipoModalidade,
  creditos,
  creditosEad,
  creditosPresencial,
  curso,
}: IGetCargaHorariaPe) {
  let ead = 0;
  let presencial = 0;

  if (tipoModalidade === TipoModalidade.ead) {
    ead = creditos;
  } else if (tipoModalidade === TipoModalidade.presencial) {
    presencial = creditos;
  } else if (tipoModalidade === TipoModalidade.split) {
    ead = creditosEad ?? 0;
    presencial = creditosPresencial ?? 0;
  }

  const pesoAed = curso === 'Teologia' ? 4.5 : 5;
  const pesoPresencial = curso === 'Teologia' ? 13.5 : 15;
  const pesoTotal = curso === 'Teologia' ? 18 : 20;

  return {
    chAed: presencial * pesoAed,
    chEad: ead * pesoTotal,
    chPresencial: presencial * pesoPresencial,
    chTotal: creditos * pesoTotal,
  };
}

interface IGetCargaHorariaAula {
  tipoModalidade: ITipoModalidade;
  numeroAulas: number;
  diasMap?: IDiasSemanaMap;
  diaSemanaAula: string;
}

export function getCargaHorariaAula({
  numeroAulas,
  tipoModalidade,
  diasMap,
  diaSemanaAula,
}: IGetCargaHorariaAula) {
  let ead = 0;
  let presencial = 0;

  if (tipoModalidade === TipoModalidade.ead) {
    ead = numeroAulas * 1;
  } else if (tipoModalidade === TipoModalidade.presencial) {
    presencial = numeroAulas * (45 / 60);
  } else if (tipoModalidade === TipoModalidade.split && diasMap != null) {
    if (diasMap[diaSemanaAula] === 'ead') {
      ead = numeroAulas * 1;
    } else if (diasMap[diaSemanaAula] === 'presencial') {
      presencial = numeroAulas * (45 / 60);
    }
  }

  return {
    ead,
    presencial,
  };
}
