import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { TableCell, Collapse, IconButton, Box } from '@mui/material';
import { useState } from 'react';

import { CustomTooltip } from '#shared/components/CustomTooltip';
import { LinkButton } from '#shared/components/LinkButton';

import { IDisciplinasInfo } from '#modules/disciplinas/types/disciplina';

import { StyledTableRow, TableRowCollapse, TableRowCollapseContent } from './styles';

interface IRowProps {
  data: IDisciplinasInfo;
}

export function RowDisciplina({ data }: IRowProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow
        border_color={data.statusColor}
        onClick={() => setOpen(!open)}
        open={open ? 1 : 0}
      >
        <CustomTooltip title={data.statusName}>
          <TableCell className="status" />
        </CustomTooltip>
        <TableCell>{data.nivel_campus}</TableCell>
        <TableCell>{data.curso}</TableCell>
        <TableCell>{data.disciplina}</TableCell>
        <TableCell>{data.professor}</TableCell>
        <TableCell>
          {data.turno} - {data.codTurma}
        </TableCell>
        <TableCell className="hide-750">{data.modalidade}</TableCell>
        <TableCell className="hide-1000">{data.periodoLetivo}</TableCell>
        <TableCell className="hide-1000">{data.prazo}</TableCell>
        <TableCell>
          <IconButton size="small">{open ? <ArrowDropDown /> : <ArrowDropUp />}</IconButton>
        </TableCell>
      </StyledTableRow>
      <TableRowCollapse border_color={data.statusColor} open={open ? 1 : 0}>
        <TableCell colSpan={10} sx={{ padding: 0 }}>
          <Collapse in={open} timeout={0}>
            <TableRowCollapseContent>
              <Box>
                <Box className="botoes">
                  <LinkButton to={`/disciplinas/${data.idTurmaDisc}`}>
                    Acessar Plano de Ensino
                  </LinkButton>

                  <LinkButton to={`/avaliacoes/${data.idTurmaDisc}`} disabled>
                    Acessar Avaliações
                  </LinkButton>
                </Box>
              </Box>
            </TableRowCollapseContent>
          </Collapse>
        </TableCell>
      </TableRowCollapse>
    </>
  );
}
