import { Button, Typography } from '@mui/material';
import { useState } from 'react';

import { CustomDialog } from '#shared/components/CustomDialog';
import { useAuth } from '#shared/hooks/auth';
import { useToast } from '#shared/hooks/toast';
import { usePost } from '#shared/services/useAxios';
import { perfilEnum } from '#shared/utils/status';

interface CopiarGerencialButtonProps {
  idTurmaDisc: number;
}

export function CopiarGerencialButton({ idTurmaDisc }: CopiarGerencialButtonProps) {
  const [open, setOpen] = useState(false);

  const { user } = useAuth();
  const { toast } = useToast();

  const { send, loading } = usePost(`disciplinas/${idTurmaDisc}/copiarGerencial`);

  async function copiarParaGerenciadas() {
    const { error } = await send({});

    if (error != null) {
      toast({ message: error, severity: 'error' });
    } else {
      toast({ message: 'Copiado com sucesso', severity: 'success' });

      setOpen(false);
    }
  }

  if (user.perfilSelected.id !== perfilEnum.gestor) return <></>;

  return (
    <>
      <CustomDialog
        maxWidth="xs"
        closeModal={() => setOpen(false)}
        open={open}
        title="Copiar para gerenciadas"
      >
        <Typography>
          Tem certeza que deseja copiar as informações para as disicplinas gerenciadas?
        </Typography>

        <Button
          variant="contained"
          fullWidth
          sx={{ mt: '1rem' }}
          onClick={copiarParaGerenciadas}
          disabled={loading}
        >
          {loading ? 'Copiando...' : 'Copiar informações para as gerenciadas'}
        </Button>
      </CustomDialog>

      <Button
        variant="contained"
        sx={{ maxWidth: '300px' }}
        onClick={() => setOpen(true)}
        disabled={loading}
      >
        Copiar informações para as gerenciadas
      </Button>
    </>
  );
}
