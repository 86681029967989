import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { IDisciplina } from '#modules/disciplinas/types/disciplina';

import { CarouselCard } from './styles';

export interface IDisciplinaCard extends IDisciplina {
  statusColor: string;
  statusText: string;
}

interface IDisciplinaCarouselCard {
  disciplina: IDisciplinaCard;
}

export function DisciplinaCarouselCard({ disciplina }: IDisciplinaCarouselCard) {
  const navigate = useNavigate();

  // console.log('DisciplinaCarouselCard');

  return (
    <CarouselCard
      elevation={1}
      color={disciplina.statusColor}
      onClick={() => navigate(`/disciplinas/${disciplina.idTurmaDisc}`)}
    >
      <Typography className="campus">{disciplina.campus}</Typography>

      <Typography className="disciplina">{disciplina.disciplina}</Typography>

      <Box className="info">
        <Typography className="label">Curso:</Typography>
        <Typography>{disciplina.curso}</Typography>
      </Box>

      <Box className="info">
        <Typography className="label">Turma:</Typography>
        <Typography>{disciplina.codTurma}</Typography>
      </Box>

      <Box className="info">
        <Typography className="label">Turno:</Typography>
        <Typography>{disciplina.turno}</Typography>
      </Box>

      <Box className="info">
        <Typography className="label">Modalidade:</Typography>
        <Typography>{disciplina.modalidade}</Typography>
      </Box>

      <Typography className="status">{disciplina.statusText}</Typography>
    </CarouselCard>
  );
}
