import { Button, styled, Typography } from '@mui/material';

export const TextEllipsis = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TextConfirm = styled(Typography)`
  color: #020560;
  margin-top: 1rem;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
`;

interface IButton {
  custom_color: string;
}

export const CustomButton = styled(Button)<IButton>`
  background: ${(props) => props.custom_color};

  transition: all 0.2s;

  &:hover {
    background: ${(props) => props.custom_color};

    filter: brightness(0.95);
  }
`;
