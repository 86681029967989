// export type ITipoModalidade = 'presencial' | 'ead' | 'split';

// interface IMapModalidades {
//   [key: string]: ITipoModalidade;
// }

// const mapModalidades: IMapModalidades = {
//   Presencial: 'presencial',
//   'Junção Turmas Presenciais': 'presencial',
//   'Mega Class': 'ead',
//   'Split Parte em Web Class Assíncrona': 'split',
//   'Split Parte em Web Class Síncrona': 'split',
//   'Web Class Compartilhada Inter Campus': 'ead',
//   'Web Class Compartilhada no Campus': 'ead',
//   'Web Class Isolada': 'ead',
//   'Web Class Compartilhada no Curso': 'ead',
// };

export const mapModalidadeValidacao: Record<string, 'gestor' | 'coordenador'> = {
  Presencial: 'coordenador',
  'Junção Turmas Presenciais': 'coordenador',
  'Split Parte em Web Class Síncrona': 'coordenador',
  'Split Parte em Web Class Assíncrona': 'coordenador',
  'Mega Class': 'gestor',
  'Web Class Compartilhada Inter Campus': 'gestor',
  'Web Class Compartilhada no Campus': 'gestor',
  'Web Class Isolada': 'gestor',
  'Web Class Compartilhada no Curso': 'gestor',
};

// export function getModalidadeTipo(modalidade: string) {
//   return mapModalidades[modalidade];
// }
