import { Save } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';

import { LoadingMemo } from '#shared/components/Loading';
import { useAuth } from '#shared/hooks/auth';
import { useToast } from '#shared/hooks/toast';
import { ISendWithInput, usePatch, usePost } from '#shared/services/useAxios';
import { mapDiaTextoNumero } from '#shared/utils/diaTransform';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';
import { mapModalidadeValidacao } from '#shared/utils/getModalidadeTipo';

import {
  disciplinaInfoActions,
  disciplinaInfoSelector,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  peAvaliacoesFormActions,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import {
  peObjetivosFormActions,
  peObjetivosFormSelector,
} from '#modules/disciplinas/redux/peObjetivosFormSlice';
import {
  planoAulasFormActions,
  planoAulasFormSelector,
} from '#modules/disciplinas/redux/planoAulasFormSlice';
import {
  IChangeValidationAvaliacao,
  IChangeValidationPlanoAula,
  IObjetivoSaveObject,
  ISaveAvaliacao,
  ISaveAvaliacaoResponse,
  ISaveDisciplinaInfo,
  ISaveDisciplinaInfoResponse,
  ISaveObjetivo,
  ISaveObjetivoResponse,
  ISavePlanoAula,
  ISavePlanoAulaResponse,
} from '#modules/disciplinas/types/disciplina';
import { TipoModalidade } from '#modules/disciplinas/types/enums/TipoModalidade';

import { SaveButton } from './styles';

// Quando eu clico para salvar ele causa 3 re-renders
export function DisciplinasSave() {
  // Alterações Disciplina
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaObsAvaliacao = useAppSelector(disciplinaInfoSelector.obsAvaliacao);
  const disciplinaCreditos = useAppSelector(disciplinaInfoSelector.creditos);
  const disciplinaDiasSemana = useAppSelector(disciplinaInfoSelector.diasSemanas);
  const disciplinaIdPlanoEnsino = useAppSelector(disciplinaInfoSelector.idPlanoEnsino);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const disciplinaChanged = useAppSelector(disciplinaInfoSelector.changed);

  // Alterações Plano de Aulas
  const changedPlanoAulas = useAppSelector(planoAulasFormSelector.changedItems);
  const planoAulas = useAppSelector(planoAulasFormSelector.planoAulas);

  // Alterações Avaliações
  const changedAvaliacoes = useAppSelector(peAvaliacoesFormSelector.changedItems);
  const avaliacoes = useAppSelector(peAvaliacoesFormSelector.avaliacoes);

  // Alterações Objetivos
  const changedObjetivos = useAppSelector(peObjetivosFormSelector.changedStatus);
  const objetivosFormFixo = useAppSelector(peObjetivosFormSelector.formFixo);
  const objetivosFormVariado = useAppSelector(peObjetivosFormSelector.formVariado);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const planoAulasChanged = Object.keys(changedPlanoAulas).map((key) => planoAulas[key]);
  const avaliacoesChanged = Object.keys(changedAvaliacoes).map((key) => avaliacoes[key]);

  const getObjetivosChanged = () => {
    const objetivosToSave: IObjetivoSaveObject[] = [];

    Object.entries(changedObjetivos).forEach(([item, objetivos]) => {
      Object.keys(objetivos).forEach((idTurmaDisc) => {
        const objetivoVariado = objetivosFormVariado[Number(item)][Number(idTurmaDisc)];

        const objetivoFixo = objetivosFormFixo[Number(item)];

        objetivosToSave.push({
          competencias: objetivoVariado.competencias,
          descricao: objetivoFixo.descricao,
          taxonomia: objetivoFixo.taxonomia ?? '',
          idObjetivo: objetivoVariado.idObjetivo,
          idTurmaDisc: Number(idTurmaDisc),
          item: Number(item),
          perfis: objetivoVariado.perfis,
        });
      });
    });

    return objetivosToSave;
  };

  const objetivosChanged = getObjetivosChanged();

  const { toast } = useToast();

  // console.log('DisciplinasSave');

  const { loading: saveAulasLoading, send: saveAulas } = usePost<
    ISavePlanoAulaResponse[],
    ISavePlanoAula
  >('/aulas/many');

  const { loading: changeValidationAulasLoading, send: changeValidationAulas } = usePatch<
    ISavePlanoAulaResponse[],
    IChangeValidationPlanoAula
  >('/aulas/many/validation');

  const { loading: saveAvaliacoesLoading, send: saveAvaliacoes } = usePost<
    ISaveAvaliacaoResponse[],
    ISaveAvaliacao
  >('/avaliacoes/many');

  const { loading: changeValidationAvaliacoesLoading, send: changeValidationAvaliacoes } = usePatch<
    ISaveAvaliacaoResponse[],
    IChangeValidationAvaliacao
  >('/avaliacoes/many/validation');

  const { loading: saveObjetivosLoading, send: saveObjetivos } = usePost<
    ISaveObjetivoResponse[],
    ISaveObjetivo
  >('/objetivos/many');

  const { loading: saveDisciplinaInfoLoading, send: saveDisciplinaInfo } = usePost<
    ISaveDisciplinaInfoResponse,
    ISaveDisciplinaInfo
  >('/disciplinas');

  const saveAlterationsProf = async () => {
    if (
      (planoAulasChanged.length === 0 &&
        avaliacoesChanged.length === 0 &&
        !disciplinaChanged &&
        objetivosChanged.length === 0) ||
      disciplina == null
    ) {
      return;
    }

    let tempIdPlanoEnsino = disciplinaIdPlanoEnsino;

    if (tempIdPlanoEnsino === 0 || disciplinaStatus === 0) {
      const { data, error } = await saveDisciplinaInfo({
        idPlanoEnsino: disciplinaIdPlanoEnsino,
        idTurmaDisc: disciplina.idTurmaDisc,
        status: 1,
      });

      if (error != null) {
        toast({ message: error, severity: 'error' });

        return;
      }

      if (data != null) {
        tempIdPlanoEnsino = data.idPlanoEnsino;

        if (disciplinaIdPlanoEnsino !== tempIdPlanoEnsino) {
          dispatch(disciplinaInfoActions.updateIdPlanoEnsino(tempIdPlanoEnsino));
        }

        if (disciplinaStatus === 0) {
          dispatch(disciplinaInfoActions.updateStatus(1));
        }
      } else {
        toast({ message: 'Aconteceu Algum Erro', severity: 'error' });

        return;
      }
    }

    const { diasEad, diasPresencial } = Object.entries(disciplinaDiasSemana).reduce(
      (map, [dia, tipo]) => {
        if (mapDiaTextoNumero[dia] == null || tipo == null) {
          return map;
        }

        const arrayName = tipo === 'presencial' ? 'diasPresencial' : 'diasEad';

        map[arrayName].push(mapDiaTextoNumero[dia]);

        return map;
      },
      {
        diasPresencial: [] as string[],
        diasEad: [] as string[],
      },
    );

    const savePromises: [
      Promise<ISendWithInput<ISavePlanoAulaResponse[]>> | null,
      Promise<ISendWithInput<ISaveAvaliacaoResponse[]>> | null,
      Promise<ISendWithInput<ISaveDisciplinaInfoResponse>> | null,
      Promise<ISendWithInput<ISaveObjetivoResponse[]>> | null,
    ] = [
      planoAulasChanged.length > 0
        ? saveAulas({
            idTurmaDisc: disciplina.idTurmaDisc,
            aulas: planoAulasChanged.map((planoAula) => ({
              ...planoAula,
              dataAula: planoAula.data,
              chAed: planoAula.chAed ?? '',
              chPresencial: planoAula.chPresencial ?? '',
              chEad: planoAula.chEad ?? '',
              textoAed: planoAula.textoAed ?? '',
              tipoAula: planoAula.tipoAula ?? undefined,
            })),
          })
        : null,
      avaliacoesChanged.length > 0
        ? saveAvaliacoes({
            idTurmaDisc: disciplina.idTurmaDisc,
            avaliacoes: avaliacoesChanged
              .filter((item) => item.data != null)
              .map((avaliacao) => ({
                ...avaliacao,
                reactId: avaliacao.id,
                data: avaliacao.data as string,
                idRecuperacao:
                  avaliacao.idRecuperacao != null ? Number(avaliacao.idRecuperacao) : undefined,
                peso: avaliacao.peso != null ? Number(avaliacao.peso) : undefined,
                bonus:
                  avaliacao.bonus != null ? Number(avaliacao.bonus.replace(',', '.')) : undefined,
                natureza: avaliacao.natureza?.value.id,
                modalidade: avaliacao.modalidade != null ? Number(avaliacao.modalidade) : undefined,
              })),
          })
        : null,
      disciplinaChanged
        ? saveDisciplinaInfo({
            idPlanoEnsino: tempIdPlanoEnsino,
            idTurmaDisc: disciplina.idTurmaDisc,
            creditosEad: disciplinaCreditos.creditosEad,
            creditosPresencial: disciplinaCreditos.creditosPresencial,
            diasEad,
            diasPresencial,
            obs: disciplinaObsAvaliacao,
          })
        : null,
      objetivosChanged.length > 0
        ? saveObjetivos({
            idTurmaDiscGerencial: disciplina.idTurmaDisc,
            objetivos: objetivosChanged,
          })
        : null,
    ];

    const [aulaResponse, avaliacaoResponse, disciplinaResponse, objetivosResponse] =
      await Promise.all(savePromises);

    const aulaData = aulaResponse?.data;
    const aulaError = aulaResponse?.error;

    const avaliacaoData = avaliacaoResponse?.data;
    const avaliacaoError = avaliacaoResponse?.error;

    const disciplinaData = disciplinaResponse?.data;
    const disciplinaError = disciplinaResponse?.error;

    const objetivoData = objetivosResponse?.data;
    const objetivoError = objetivosResponse?.error;

    if (
      aulaError != null ||
      avaliacaoError != null ||
      disciplinaError != null ||
      objetivoError != null
    ) {
      toast({
        message: aulaError ?? avaliacaoError ?? disciplinaError ?? objetivoError ?? '',
        severity: 'error',
      });
    }

    const savedItemsAula = aulaData?.filter((item) => !item.error);
    const savedItemsAvaliacao = avaliacaoData?.filter((item) => !item.error);
    const savedItemsObjetivos = objetivoData?.filter((item) => !item.error);

    if (
      savedItemsAula?.length !== aulaData?.length ||
      savedItemsAvaliacao?.length !== avaliacaoData?.length ||
      savedItemsObjetivos?.length !== objetivoData?.length
    ) {
      toast({ message: 'Algumas items não foram salvos', severity: 'error' });
    }

    if (disciplinaData != null) {
      dispatch(disciplinaInfoActions.saveAlterations());
    }

    dispatch(planoAulasFormActions.savePlanosAulas(savedItemsAula ?? []));
    dispatch(peAvaliacoesFormActions.saveAvaliacoes(savedItemsAvaliacao ?? []));
    dispatch(peObjetivosFormActions.saveObjetivos(savedItemsObjetivos ?? []));
  };

  const saveAlterationsCoordCH = async () => {
    if ((planoAulasChanged.length === 0 && !disciplinaChanged) || disciplina == null) {
      return;
    }

    let tempIdPlanoEnsino = disciplinaIdPlanoEnsino;

    if (tempIdPlanoEnsino === 0 || disciplinaStatus === 0) {
      const { data, error } = await saveDisciplinaInfo({
        idPlanoEnsino: disciplinaIdPlanoEnsino,
        idTurmaDisc: disciplina.idTurmaDisc,
        status: 1,
      });

      if (error != null) {
        toast({ message: error, severity: 'error' });

        return;
      }

      if (data != null) {
        tempIdPlanoEnsino = data.idPlanoEnsino;

        if (disciplinaIdPlanoEnsino !== tempIdPlanoEnsino) {
          dispatch(disciplinaInfoActions.updateIdPlanoEnsino(tempIdPlanoEnsino));
        }

        if (disciplinaStatus === 0) {
          dispatch(disciplinaInfoActions.updateStatus(1));
        }
      } else {
        toast({ message: 'Aconteceu Algum Erro', severity: 'error' });

        return;
      }
    }

    const { diasEad, diasPresencial } = Object.entries(disciplinaDiasSemana).reduce(
      (map, [dia, tipo]) => {
        if (mapDiaTextoNumero[dia] == null || tipo == null) {
          return map;
        }

        const arrayName = tipo === 'presencial' ? 'diasPresencial' : 'diasEad';

        map[arrayName].push(mapDiaTextoNumero[dia]);

        return map;
      },
      {
        diasPresencial: [] as string[],
        diasEad: [] as string[],
      },
    );

    const savePromises: [
      Promise<ISendWithInput<ISavePlanoAulaResponse[]>> | null,
      Promise<ISendWithInput<ISaveDisciplinaInfoResponse>> | null,
    ] = [
      planoAulasChanged.length > 0
        ? saveAulas({
            idTurmaDisc: disciplina.idTurmaDisc,
            aulas: planoAulasChanged.map((planoAula) => ({
              ...planoAula,
              dataAula: planoAula.data,
              chAed: planoAula.chAed ?? '',
              chPresencial: planoAula.chPresencial ?? '',
              chEad: planoAula.chEad ?? '',
              textoAed: planoAula.textoAed ?? '',
              tipoAula: planoAula.tipoAula ?? undefined,
            })),
          })
        : null,
      disciplinaChanged
        ? saveDisciplinaInfo({
            idPlanoEnsino: tempIdPlanoEnsino,
            idTurmaDisc: disciplina.idTurmaDisc,
            creditosEad: disciplinaCreditos.creditosEad,
            creditosPresencial: disciplinaCreditos.creditosPresencial,
            diasEad,
            diasPresencial,
            obs: disciplinaObsAvaliacao,
          })
        : null,
    ];

    const [aulaResponse, disciplinaResponse] = await Promise.all(savePromises);

    const aulaData = aulaResponse?.data;
    const aulaError = aulaResponse?.error;

    const disciplinaData = disciplinaResponse?.data;
    const disciplinaError = disciplinaResponse?.error;

    if (aulaError != null || disciplinaError != null) {
      toast({
        message: aulaError ?? disciplinaError ?? '',
        severity: 'error',
      });
    }

    const savedItemsAula = aulaData?.filter((item) => !item.error);

    if (savedItemsAula?.length !== aulaData?.length) {
      toast({ message: 'Algumas items não foram salvos', severity: 'error' });
    }

    if (disciplinaData != null) {
      dispatch(disciplinaInfoActions.saveAlterations());
    }

    dispatch(planoAulasFormActions.savePlanosAulas(savedItemsAula ?? []));
  };

  const saveAlterationsCoordValidation = async () => {
    if (
      (planoAulasChanged.length === 0 && avaliacoesChanged.length === 0) ||
      disciplina == null ||
      disciplinaIdPlanoEnsino === 0
    ) {
      return;
    }

    const savePromises: [
      Promise<ISendWithInput<ISavePlanoAulaResponse[]>> | null,
      Promise<ISendWithInput<ISaveAvaliacaoResponse[]>> | null,
    ] = [
      planoAulasChanged.length > 0
        ? changeValidationAulas({
            idTurmaDisc: disciplina.idTurmaDisc,
            aulas: planoAulasChanged.map((planoAula) => ({
              validacao: planoAula.validacao,
              dataAula: planoAula.data,
              idPlanoEnsinoAula: planoAula.idPlanoEnsinoAula,
            })),
          })
        : null,
      avaliacoesChanged.length > 0
        ? changeValidationAvaliacoes({
            idTurmaDisc: disciplina.idTurmaDisc,
            avaliacoes: avaliacoesChanged
              .filter((item) => item.data != null)
              .map((avaliacao) => ({
                data: avaliacao.data as string,
                idAvaliacao: avaliacao.idAvaliacao,
                validacao: avaliacao.validacao ?? false,
              })),
          })
        : null,
    ];

    const [aulaResponse, avaliacaoResponse] = await Promise.all(savePromises);

    const aulaData = aulaResponse?.data;
    const aulaError = aulaResponse?.error;

    const avaliacaoData = avaliacaoResponse?.data;
    const avaliacaoError = avaliacaoResponse?.error;

    if (aulaError != null || avaliacaoError != null) {
      toast({
        message: aulaError ?? avaliacaoError ?? '',
        severity: 'error',
      });
    }

    const savedItemsAula = aulaData?.filter((item) => !item.error);
    const savedItemsAvaliacao = avaliacaoData
      ?.filter((item) => !item.error)
      .map((item) => ({ ...item, reactId: item.idAvaliacao.toString() }));

    if (
      savedItemsAula?.length !== aulaData?.length ||
      savedItemsAvaliacao?.length !== avaliacaoData?.length
    ) {
      toast({ message: 'Algumas items não foram salvos', severity: 'error' });
    }

    dispatch(planoAulasFormActions.savePlanosAulas(savedItemsAula ?? []));
    dispatch(peAvaliacoesFormActions.saveAvaliacoes(savedItemsAvaliacao ?? []));
  };

  const cannotEditProf = cannotEditDisciplina({ user, disciplinaStatus });

  const canSaveCoordCh =
    !cannotEditDisciplina({
      user,
      disciplinaStatus,
      tipoPerfil: 'coordenador',
    }) && disciplina?.tipoModalidade === TipoModalidade.split;

  const cannotSaveCoordValidation = cannotEditDisciplina({
    user,
    disciplinaStatus,
    tipoStatus: 'coordenador',
    tipoPerfil: mapModalidadeValidacao[disciplina?.modalidade ?? 'Presencial'] ?? 'coordenador',
  });

  if (disciplina == null) return <></>;

  return (
    <>
      <LoadingMemo
        loading={
          saveAulasLoading ||
          saveAvaliacoesLoading ||
          saveDisciplinaInfoLoading ||
          saveObjetivosLoading ||
          changeValidationAulasLoading ||
          changeValidationAvaliacoesLoading
        }
      />

      {!cannotEditProf && (
        <SaveButton onClick={async () => await saveAlterationsProf()} size="large">
          <Badge
            badgeContent={
              planoAulasChanged.length +
              avaliacoesChanged.length +
              (disciplinaChanged ? 1 : 0) +
              objetivosChanged.length
            }
          >
            <Save fontSize="medium" />
          </Badge>
        </SaveButton>
      )}

      {canSaveCoordCh && (
        <SaveButton onClick={async () => await saveAlterationsCoordCH()} size="large">
          <Badge badgeContent={planoAulasChanged.length + (disciplinaChanged ? 1 : 0)}>
            <Save fontSize="medium" />
          </Badge>
        </SaveButton>
      )}

      {!cannotSaveCoordValidation && (
        <SaveButton onClick={async () => await saveAlterationsCoordValidation()} size="large">
          <Badge badgeContent={planoAulasChanged.length + avaliacoesChanged.length}>
            <Save fontSize="medium" />
          </Badge>
        </SaveButton>
      )}
    </>
  );
}

export const DisciplinasSaveMemo = React.memo(DisciplinasSave);
