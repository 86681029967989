import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';

import { FormSelectMemo } from '#shared/components/form/FormSelect';
import { FormTextFieldMemo } from '#shared/components/form/FormTextField';
import { FormTextFieldMaskMemo } from '#shared/components/form/FormTextFieldMask';
import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { planoAulasFormActions } from '#modules/disciplinas/redux/planoAulasFormSlice';
import {
  IPlanoAulaSchema,
  planoAulaSchema,
  tipoAulaMap,
  tipoAulaOptions,
} from '#modules/disciplinas/schemas/planoAulaSchema';
import { IAulaInfo } from '#modules/disciplinas/types/disciplina';

import { PlanoAulaCardFormCargaHorariaMemo } from './cargaHoraria';
import { metodologias } from './options';
import { PlanoAulaCardFormReferenciasMemo } from './referencias';
import { FormAulasStyled } from './styles';

interface IPlanoAulaCardForm {
  aula: IAulaInfo;
  getStatusColor: (form: IPlanoAulaSchema, isValid: boolean) => void;
}

// Quando eu gero um submit do form (Blur de qualquer input, o card está sofrendo 2 re-renders)
export function PlanoAulaCardForm({ aula, getStatusColor }: IPlanoAulaCardForm) {
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IPlanoAulaSchema>({
    resolver: yupResolver(planoAulaSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const getMetodologiasOptions = () => {
    const options = Array.from(metodologias);

    if (aula.metodologias != null) {
      aula.metodologias.forEach((item) => {
        if (!metodologias.includes(item)) {
          options.push(item);
        }
      });
    }

    return options;
  };

  const metodologiasOptions = getMetodologiasOptions();

  const handleFormSubmit = (form: IPlanoAulaSchema) => {
    dispatch(
      planoAulasFormActions.updateItem({
        id: aula.data,
        item: form,
      }),
    );
  };

  const handleFormChange = async () => {
    await handleSubmit(handleFormSubmit)();

    const form = getValues();

    let valid = true;

    try {
      planoAulaSchema.validateSync(form);
    } catch (error) {
      valid = false;
    }

    getStatusColor(form, valid);
  };

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus });

  const cannotEditAedCH =
    (aula.chAed == null || aula.chAed === '') &&
    (disciplina?.chAed == null || disciplina?.chAed === 0);

  const cannotEditAedText =
    (aula.textoAed == null || aula.textoAed === '') &&
    (disciplina?.chAed == null || disciplina?.chAed === 0);

  // console.log('PlanoAulaCardForm');

  return (
    <FormAulasStyled noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={4}>
          <PlanoAulaCardFormCargaHorariaMemo
            aula={aula}
            control={control}
            errors={errors}
            handleFormChange={handleFormChange}
            setValue={setValue}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Box className="input">
            <Tooltip title="Espaços Diversificados">
              <label htmlFor={`chAed-${aula.data}`}>CH AED</label>
            </Tooltip>

            <FormTextFieldMaskMemo
              id={`chAed-${aula.data}`}
              control={control}
              name="chAed"
              mask="##:@#"
              placeholder="__:__"
              regex={{ '#': /[0-9]/, '@': /[0-5]/ }}
              marginType="no-margin"
              onBlur={handleFormChange}
              defaultValue={aula.chAed}
              errors={errors.chAed as any}
              helperText=" "
              disabled={cannotEdit || cannotEditAedCH}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="input" sx={{ flex: 1 }}>
            <label htmlFor={`textoAed-${aula.data}`}>Atividade em espaço diversificado</label>

            <FormTextFieldMemo
              id={`textoAed-${aula.data}`}
              control={control}
              name="textoAed"
              marginType="no-margin"
              onBlur={handleFormChange}
              defaultValue={aula.textoAed}
              errors={errors.textoAed as any}
              helperText=" "
              disabled={cannotEdit || cannotEditAedText}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <Box className="input">
            <label htmlFor={`objetivos-${aula.data}`}>Objetivo</label>

            <FormSelectMemo
              id={`objetivos-${aula.data}`}
              control={control}
              name="objetivos"
              options={['1', '2', '3']}
              errors={errors.objetivos as any}
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={aula.objetivos}
              multiple
              helperText=" "
              disabled={cannotEdit}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box className="input">
            <label htmlFor={`metodologias-${aula.data}`}>Metodologia</label>

            <FormSelectMemo
              id={`metodologias-${aula.data}`}
              control={control}
              name="metodologias"
              options={metodologiasOptions}
              errors={errors.metodologias as any}
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={aula.metodologias}
              multiple
              helperText=" "
              freeSolo
              disabled={cannotEdit}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={2}>
          <Box className="input">
            <label htmlFor={`tipoaula-${aula.data}`}>Tipo Aula</label>

            <FormSelectMemo
              id={`tipoaula-${aula.data}`}
              control={control}
              name="tipoAula"
              options={tipoAulaOptions}
              errors={errors.tipoAula as any}
              optionLabel="label"
              optionValue="value"
              marginType="no-margin"
              customOnChange={handleFormChange}
              defaultValue={aula.tipoAula != null ? tipoAulaMap[aula.tipoAula] : tipoAulaMap.T}
              helperText=" "
              disabled={cannotEdit}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={8} lg={8} xl={9}>
          <Box className="input">
            <label htmlFor={`conteudo-${aula.data}`}>Conteúdo</label>

            <FormTextFieldMemo
              id={`conteudo-${aula.data}`}
              control={control}
              name="conteudo"
              errors={errors.conteudo}
              marginType="no-margin"
              onBlur={handleFormChange}
              multiline
              minRows={3}
              defaultValue={aula.conteudo}
              disabled={cannotEdit}
            />
          </Box>
        </Grid>

        <Grid item sm={3} md={4} lg={4} xl={3}>
          <Box className="referencia">
            <PlanoAulaCardFormReferenciasMemo
              aula={aula}
              control={control}
              errors={errors}
              handleFormChange={handleFormChange}
            />
          </Box>
        </Grid>
      </Grid>
    </FormAulasStyled>
  );
}

export const PlanoAulaCardFormMemo = React.memo(PlanoAulaCardForm);
