import { useAppSelector } from 'app/hooks';
import React from 'react';

import { disciplinasFilterSelector } from '#modules/home/redux/disciplinasFilterSlice';

import { RowDisciplina } from './row';
import { StyledTableBody } from './styles';

export function DisciplinasTableBody() {
  const disciplinas = useAppSelector(disciplinasFilterSelector.disciplinas);

  return (
    <StyledTableBody>
      {disciplinas.map((disciplina) => (
        <RowDisciplina data={disciplina} key={disciplina.idTurmaDisc} />
      ))}
    </StyledTableBody>
  );
}

export const DisciplinasTableBodyMemo = React.memo(DisciplinasTableBody);
