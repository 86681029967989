import { isBefore, fromUnixTime } from 'date-fns';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { createContext, useCallback, useState, useContext, ReactNode, useMemo } from 'react';

import { LoadingMemo } from '#shared/components/Loading';
import { usePost } from '#shared/services/useAxios';

import { IUser } from '#modules/users/types/user';

import { useToast } from './toast';

interface IAuthProviderProps {
  children: ReactNode;
}

interface IAuthResponse {
  token: string;
  user: IUser;
}

interface IAuthInput {
  username: string;
  password: string;
}

interface IReAuthInput {
  perfil: number;
}

interface IAuthContextData {
  user: IUser;
  logged: boolean;
  signIn: (authInput: IAuthInput) => Promise<void>;
  changePerfil: (reAuthInput: IReAuthInput) => Promise<void>;
  signOut: () => Promise<void>;
}

interface IAuthState {
  token: string;
  user: any;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export function AuthProvider({ children }: IAuthProviderProps) {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@plano_ensino:token');
    const user = localStorage.getItem('@plano_ensino:user');

    if (token != null) {
      const decodedToken = jwt_decode<JwtPayload>(token);

      if (
        decodedToken.exp != null &&
        Boolean(isBefore(fromUnixTime(Number(decodedToken.exp)), new Date()))
      ) {
        localStorage.removeItem('@plano_ensino:token');
        localStorage.removeItem('@plano_ensino:user');

        return {} as IAuthState;
      }
    }

    if (token != null && user != null) {
      return { token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const { toast } = useToast();

  const { send: auth, loading: authLoading } = usePost<IAuthResponse, IAuthInput>('/auth');

  const { send: reAuth, loading: reAuthLoading } = usePost<IAuthResponse, IReAuthInput>(
    '/auth/revalidate',
  );

  const signIn = useCallback(
    async (authInput: IAuthInput) => {
      const { data: authData, error } = await auth(authInput);

      if (error != null) {
        toast({ message: error, severity: 'error' });

        return;
      }

      const { token, user } = authData as IAuthResponse;

      localStorage.setItem('@plano_ensino:token', token);
      localStorage.setItem('@plano_ensino:user', JSON.stringify(user));

      setData({ token, user });
    },
    [auth, toast],
  );

  const changePerfil = useCallback(
    async (reAuthInput: IReAuthInput) => {
      if (reAuthInput?.perfil == null) {
        return;
      }

      const { data: reAuthData, error } = await reAuth(reAuthInput);

      if (error != null) {
        toast({ message: error, severity: 'error' });

        return;
      }

      const { token, user } = reAuthData as IAuthResponse;

      localStorage.setItem('@plano_ensino:token', token);
      localStorage.setItem('@plano_ensino:user', JSON.stringify(user));

      setData({ token, user });
    },
    [reAuth, toast],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@plano_ensino:token');
    localStorage.removeItem('@plano_ensino:user');

    setData({} as IAuthState);
  }, []);

  const authValue = useMemo<IAuthContextData>(() => {
    return {
      user: {
        ...data.user,
      },
      logged: data.user != null,
      signIn,
      signOut,
      changePerfil,
    };
  }, [data.user, signIn, signOut, changePerfil]);

  return (
    <AuthContext.Provider value={authValue}>
      <LoadingMemo loading={authLoading || reAuthLoading} />

      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (Object.keys(context).length === 0) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
