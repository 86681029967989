import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useEffect, useMemo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import { FormNumberFieldMemo } from '#shared/components/form/FormNumberField';
import { FormTextFieldMaskMemo } from '#shared/components/form/FormTextFieldMask';
import { useAuth } from '#shared/hooks/auth';
import { getCargaHorariaAula } from '#shared/utils/cargaHoraria';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';
import { hoursToTextDuration } from '#shared/utils/timeTransform';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { IPlanoAulaSchema } from '#modules/disciplinas/schemas/planoAulaSchema';
import { IAulaInfo } from '#modules/disciplinas/types/disciplina';
import { TipoModalidade } from '#modules/disciplinas/types/enums/TipoModalidade';

interface IPlanoAulaCardFormCargaHoraria {
  aula: IAulaInfo;
  control: Control<IPlanoAulaSchema, any>;
  handleFormChange: () => Promise<void>;
  errors?: any;
  setValue: UseFormSetValue<IPlanoAulaSchema>;
}

export function PlanoAulaCardFormCargaHoraria({
  aula,
  control,
  handleFormChange,
  errors,
  setValue,
}: IPlanoAulaCardFormCargaHoraria) {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const diasSemanas = useAppSelector(disciplinaInfoSelector.diasSemanas);

  const { user } = useAuth();

  const cargaHoraria = useMemo(() => {
    const carga = getCargaHorariaAula({
      numeroAulas: aula.numAulas,
      tipoModalidade: disciplina?.tipoModalidade ?? TipoModalidade.presencial,
      diaSemanaAula: aula.diaSemana,
      diasMap: diasSemanas,
    });

    return carga;
  }, [aula.diaSemana, aula.numAulas, diasSemanas, disciplina]);

  useEffect(() => {
    if (
      !(
        disciplina?.tipoModalidade === TipoModalidade.split &&
        disciplina.mapDomingosLetivos[aula.data]
      )
    ) {
      setValue('chPresencial', hoursToTextDuration(cargaHoraria.presencial));

      setValue('chEad', hoursToTextDuration(cargaHoraria.ead));
    }
  }, [aula.data, cargaHoraria.ead, cargaHoraria.presencial, disciplina, setValue]);

  // console.log(cargaHoraria, diasSemanas, aula.diaSemana);

  // console.log('PlanoAulaCardFormCargaHoraria');
  const allowChanges =
    disciplina?.tipoModalidade === TipoModalidade.split &&
    aula.diaSemana === 'domingo' &&
    // disciplina.mapDomingosLetivos[aula.data] &&
    !cannotEditDisciplina({ user, disciplinaStatus });

  if (disciplina == null) return <></>;

  if (disciplina.tipoModalidade === TipoModalidade.split && Object.keys(diasSemanas).length < 1)
    return (
      <Typography sx={{ width: '300px', fontSize: '1rem', fontWeight: '700', mr: '1rem' }}>
        Termine de preencher a distribuição de creditos primeiro
      </Typography>
    );

  return (
    <>
      <FormNumberFieldMemo
        sx={{ display: 'none' }}
        control={control}
        name="numAulas"
        errors={errors.numAulas}
        marginType="no-margin"
        scale={0}
        defaultValue={aula.numAulas.toString()}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box className="input">
            <label htmlFor={`chPresencial-${aula.data}`}>CH Presencial</label>

            <FormTextFieldMaskMemo
              id={`chPresencial-${aula.data}`}
              control={control}
              name="chPresencial"
              mask="##:@#"
              placeholder="__:__"
              regex={{ '#': /[0-9]/, '@': /[0-5]/ }}
              marginType="no-margin"
              onBlur={handleFormChange}
              defaultValue={aula.chPresencial ?? hoursToTextDuration(cargaHoraria.presencial)}
              helperText=" "
              disabled={!allowChanges}
              errors={errors.chPresencial}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="input">
            <label htmlFor={`chEad-${aula.data}`}>CH EAD</label>

            <FormTextFieldMaskMemo
              id={`chEad-${aula.data}`}
              control={control}
              name="chEad"
              mask="##:@#"
              placeholder="__:__"
              regex={{ '#': /[0-9]/, '@': /[0-5]/ }}
              marginType="no-margin"
              onBlur={handleFormChange}
              defaultValue={aula.chEad ?? hoursToTextDuration(cargaHoraria.ead)}
              helperText=" "
              disabled={!allowChanges}
              errors={errors.chEad}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export const PlanoAulaCardFormCargaHorariaMemo = React.memo(PlanoAulaCardFormCargaHoraria);
