import { yupResolver } from '@hookform/resolvers/yup';
import { Lock, Person } from '@mui/icons-material';
import { Button, TextField, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '#shared/hooks/auth';
import { useTitle } from '#shared/hooks/title';

import { authSchema, IAuthSchema } from '#modules/users/schemas/auth.schema';

import logoUnasp from '#static/logo_unasp.png';

import { AuthContainer, AuthModal } from './styles';

export function Auth() {
  const { updateTitle } = useTitle();
  const { signIn, logged } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IAuthSchema>({
    resolver: yupResolver(authSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (logged) {
      if (state?.returnThisPage != null) {
        navigate(-1);
      } else {
        navigate('/');
      }
    }

    updateTitle('Autenticação');
  }, [navigate, logged, updateTitle, state]);

  const onSubmit = useCallback(
    async (input: IAuthSchema) => {
      await signIn(input);
    },
    [signIn],
  );

  return (
    <AuthContainer>
      <AuthModal>
        <img src={logoUnasp} alt="Logo Unasp" />

        <Typography component="h2">Portal do Professor</Typography>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={errors.username != null}
                helperText={errors.username?.message ?? ''}
                label="Usuario"
                InputProps={{
                  endAdornment: <Person sx={{ color: '#00000087' }} />,
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={errors.password != null}
                helperText={errors.password?.message ?? ''}
                label="Senha"
                autoComplete="current-password"
                type="password"
                InputProps={{
                  endAdornment: <Lock sx={{ color: '#00000087' }} />,
                }}
                sx={{ mt: '2rem' }}
              />
            )}
          />

          <Button type="submit" variant="contained" fullWidth size="large">
            Entrar
          </Button>
        </form>
      </AuthModal>
    </AuthContainer>
  );
}
