import { type BoxProps, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';
import { type Control, Controller, type FieldError } from 'react-hook-form';

import { FormCheckboxContainer } from './styles';

interface IFormCheckbox {
  name: string;
  label?: string;
  control: Control<any>;
  errors?: FieldError | undefined;
  defaultValue?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  boxProps?: BoxProps;
  disabled?: boolean;
}

export function FormCheckbox({
  name,
  defaultValue,
  control,
  errors,
  label,
  onChange,
  boxProps,
  disabled,
}: IFormCheckbox) {
  return (
    <FormCheckboxContainer {...boxProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? false}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(e, c) => {
                  field.onChange(e, c);

                  if (onChange != null) {
                    onChange(e, c);
                  }
                }}
                sx={{
                  color: errors != null ? '#d32f2f' : undefined,
                  padding: '0.1rem',
                  marginRight: '0.3rem',
                }}
                disabled={disabled}
              />
            }
            label={label}
          />
        )}
      />

      {errors != null && <Typography component="span">{errors.message}</Typography>}
    </FormCheckboxContainer>
  );
}

export const FormCheckboxMemo = React.memo(FormCheckbox);
