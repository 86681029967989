/* eslint-disable no-nested-ternary */
import { OutlinedTextFieldProps, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

import { AutocompleteStyled, PopperStyled } from './styles';

interface IFormAutoComplete extends Omit<OutlinedTextFieldProps, 'variant'> {
  id?: string;
  name: string;
  label?: string;
  control: Control<any>;
  defaultValue?: any;
  options: any[];
  optionLabel?: string;
  optionValue?: string;
  multiple?: boolean;
  errors?: FieldError | FieldError[];
  disabled?: boolean;
  marginType?: 'no-margin' | 'left-margin';
  required?: boolean;
  freeSolo?: boolean;
  placeholder?: string;
  customOnChange?: (value: any) => void;
  helperText?: string;
}

export function FormSelect({
  id,
  multiple,
  options,
  optionLabel,
  optionValue,
  control,
  label,
  name,
  defaultValue,
  errors,
  disabled,
  marginType,
  required,
  freeSolo,
  customOnChange,
  placeholder,
  helperText,
  sx,
  ...textFieldProps
}: IFormAutoComplete) {
  const sxFixed = useMemo(() => {
    let marginTop: string | undefined = '1em';
    let marginLeft: string | undefined;

    if (marginType != null) {
      marginTop = undefined;
    }

    if (marginType === 'left-margin') {
      marginLeft = '1em';
    }

    return {
      marginTop,
      marginLeft,
      ...sx,
    };
  }, [marginType, sx]);

  const getLabel = useCallback<(option: any) => string>(
    (option: any) => {
      if (option == null) {
        return '';
      }

      if (optionLabel == null) {
        return option;
      }

      return option[optionLabel];
    },
    [optionLabel],
  );

  const isOptionValueEqual = useCallback(
    (option: any, value: any) => {
      if (optionValue == null) {
        return option === value;
      }

      return option[optionValue] === value[optionValue];
    },
    [optionValue],
  );

  const defaultValueFixed = useMemo(() => {
    if (defaultValue == null) {
      return null;
    }

    if (multiple === true) {
      return defaultValue
        .map((dv: any) => options.find((option) => isOptionValueEqual(option, dv)))
        .filter((dv: any) => dv != null);
    }

    return options.find((option) => isOptionValueEqual(option, defaultValue)) ?? null;
  }, [defaultValue, isOptionValueEqual, multiple, options]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValueFixed ?? (multiple === true ? [] : null)}
      render={({ field }) => (
        <AutocompleteStyled
          {...field}
          id={id}
          noOptionsText="Nenhuma Opção"
          filterSelectedOptions
          getOptionLabel={getLabel}
          freeSolo={freeSolo}
          multiple={multiple}
          options={options}
          disabled={disabled}
          onChange={(_, data) => {
            field.onChange(data);

            field.onBlur();

            if (customOnChange != null) {
              customOnChange(data);
            }
          }}
          isOptionEqualToValue={isOptionValueEqual}
          PopperComponent={PopperStyled}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              required={required}
              label={label}
              name={name}
              placeholder={placeholder}
              error={!(errors == null)}
              helperText={
                errors != null
                  ? Array.isArray(errors)
                    ? errors[0].message
                    : errors.message
                  : helperText
              }
              inputProps={{
                ...params.inputProps,
              }}
              sx={sxFixed}
            />
          )}
          fullWidth
        />
      )}
    />
  );
}

export const FormSelectMemo = React.memo(FormSelect);
