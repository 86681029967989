import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';

import { planoAulasFormSelector } from '#modules/disciplinas/redux/planoAulasFormSlice';
import { IAulaInfo } from '#modules/disciplinas/types/disciplina';

import { IAulaFormValidation } from '.';

interface IPlanoAulaCardTitle {
  aula: IAulaInfo;
  getStatusColorAlterations: (notSaved: boolean, aulaInfo: IAulaFormValidation) => void;
}

// Na primeira vez que um form der um submit valido, esse componente vai atualizar para todos os
// cards disponiveis (Se tiver 30 dias ele vai dar re-render nos 30 titulos)
// Ao salvar as alterações ele vai ter o mesmo comportamento.
export function PlanoAulaCardTitle({ aula, getStatusColorAlterations }: IPlanoAulaCardTitle) {
  const changedItems = useAppSelector(planoAulasFormSelector.changedItems);
  const planoAulas = useAppSelector(planoAulasFormSelector.planoAulas);

  // console.log('PlanoAulaCardTitle');

  const getTitle = () => {
    const aulasText = aula.numAulas === 1 ? 'aula' : 'aulas';

    const alterationsText = changedItems[aula.data] ? ' **' : '';

    return `Plano de Aula - ${aula.data} (${aula.diaSemana}) - ${aula.numAulas} ${aulasText}${alterationsText}`;
  };

  const title = getTitle();

  useEffect(() => {
    getStatusColorAlterations(changedItems[aula.data] ?? false, { ...planoAulas[aula.data] });
  }, [aula.data, changedItems, getStatusColorAlterations, planoAulas]);

  return <Typography>{title}</Typography>;
}

export const PlanoAulaCardTitleMemo = React.memo(PlanoAulaCardTitle);
