import { Add, DragHandle } from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';

import { getCargaHorariaAula, getCargaHorariaPE } from '#shared/utils/cargaHoraria';
import { hoursToTextDuration, textDurationToHours } from '#shared/utils/timeTransform';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { planoAulasFormSelector } from '#modules/disciplinas/redux/planoAulasFormSlice';
import { TipoModalidade } from '#modules/disciplinas/types/enums/TipoModalidade';

import { TotalPlanoAula } from './styles';

export function PlanoAulasCargaHoraria() {
  const planoAulas = useAppSelector(planoAulasFormSelector.planoAulas);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const { creditosEad, creditosPresencial } = useAppSelector(disciplinaInfoSelector.creditos);
  const diasSemanas = useAppSelector(disciplinaInfoSelector.diasSemanas);

  // console.log('PlanoAulasCargaHoraria');

  const getCargaHorariaCalc = () => {
    const cargaHorariaAed = Object.values(planoAulas).reduce(
      (total, aula) => {
        total.chAedTotal += textDurationToHours(aula.chAed ?? '00:00');

        return total;
      },
      {
        chAedTotal: 0,
      },
    );

    const cargaHorariaEP = disciplina?.aulas.reduce(
      (map, aula) => {
        const carga = getCargaHorariaAula({
          numeroAulas: aula.numAulas,
          tipoModalidade: disciplina?.tipoModalidade ?? (TipoModalidade.presencial as any),
          diaSemanaAula: aula.diaSemana,
          diasMap: diasSemanas,
        });

        const aulaEad = aula.chEad != null ? textDurationToHours(aula.chEad) : null;
        const aulaPresencial =
          aula.chPresencial != null ? textDurationToHours(aula.chPresencial) : null;

        map.chEadTotal += aulaEad ?? carga.ead;
        map.chPresencialTotal += aulaPresencial ?? carga.presencial;

        return map;
      },
      {
        chEadTotal: 0,
        chPresencialTotal: 0,
      },
    ) ?? { chEadTotal: 0, chPresencialTotal: 0 };

    const cargaHorariaDisciplina = getCargaHorariaPE({
      curso: disciplina?.curso ?? '',
      creditos: disciplina?.creditos ?? 0,
      tipoModalidade: disciplina?.tipoModalidade ?? (TipoModalidade.presencial as any),
      creditosEad,
      creditosPresencial,
    });

    const totalDisc = hoursToTextDuration(
      cargaHorariaDisciplina.chAed +
        cargaHorariaDisciplina.chEad +
        cargaHorariaDisciplina.chPresencial,
    );

    const totalAula = hoursToTextDuration(
      cargaHorariaAed.chAedTotal + cargaHorariaEP?.chEadTotal + cargaHorariaEP?.chPresencialTotal,
    );
    return {
      chAedDisc: hoursToTextDuration(cargaHorariaDisciplina.chAed),
      chEadDisc: hoursToTextDuration(cargaHorariaDisciplina.chEad),
      chPresencialDisc: hoursToTextDuration(cargaHorariaDisciplina.chPresencial),
      chAedAula: hoursToTextDuration(Number(cargaHorariaAed.chAedTotal.toFixed(5))),
      chEadAula: hoursToTextDuration(Number(cargaHorariaEP.chEadTotal.toFixed(5))),
      chPresencialAula: hoursToTextDuration(Number(cargaHorariaEP.chPresencialTotal.toFixed(5))),
      totalAula,
      totalDisc,
    };
  };

  const cargaHoraria = getCargaHorariaCalc();

  return (
    <TotalPlanoAula>
      <Box className="title">
        <Typography>Total da Carga Horária</Typography>
      </Box>

      <Box className="resultados">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Box className="calculo">
              <Typography className="label">CH Presencial (H)</Typography>

              <Typography className="valor">
                {cargaHoraria.chPresencialAula} de {cargaHoraria.chPresencialDisc}
              </Typography>
            </Box>
          </Grid>

          <Grid className="icon" item xs={0} lg={1}>
            <Add fontSize="large" />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Box className="calculo">
              <Typography className="label">CH EAD (H)</Typography>

              <Typography className="valor">
                {cargaHoraria.chEadAula} de {cargaHoraria.chEadDisc}
              </Typography>
            </Box>
          </Grid>

          <Grid className="icon" item xs={0} lg={1}>
            <Add fontSize="large" />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Box className="calculo">
              <Tooltip title="Espaços Diversificados">
                <Typography className="label">CH AED (H)</Typography>
              </Tooltip>

              <Typography className="valor">
                {cargaHoraria.chAedAula} de {cargaHoraria.chAedDisc}
              </Typography>
            </Box>
          </Grid>

          <Grid className="icon" item xs={0} lg={2}>
            <DragHandle fontSize="large" />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Box className="calculo">
              <Typography className="label">Total</Typography>

              <Typography className="valor">
                {cargaHoraria.totalAula} de {cargaHoraria.totalDisc}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </TotalPlanoAula>
  );
}

export const PlanoAulaCargaHorariaMemo = React.memo(PlanoAulasCargaHoraria);
