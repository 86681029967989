/* eslint-disable no-nested-ternary */
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  OutlinedTextFieldProps,
  TextField,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { PopperStyled } from './styles';

type IAutoCompleteProps = AutocompleteProps<
  any,
  boolean | undefined, // Multiple
  boolean | undefined, // DisableClerable
  boolean | undefined // FreeSolo
>;

interface ICustomSelect extends Omit<OutlinedTextFieldProps, 'variant' | 'onChange'> {
  value: any;
  onChange: (newValue: any) => void;
  options: any | any[];
  optionLabel?: string;
  optionValue?: string;
  multiple?: boolean;
  errors?: string;
  disabled?: boolean;
  marginType?: 'no-margin' | 'left-margin';
  freeSolo?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    options: any,
    state: AutocompleteRenderOptionState,
  ) => JSX.Element;
  AutoCompleteProps?: Omit<IAutoCompleteProps, 'options' | 'renderInput'>;
}

export function CustomSelect<T>({
  multiple,
  options,
  optionLabel,
  optionValue,
  errors,
  disabled,
  marginType,
  freeSolo,
  onChange,
  value,
  sx,
  fullWidth,
  renderOption,
  AutoCompleteProps,
  ...otherProps
}: ICustomSelect) {
  const sxFixed = useMemo(() => {
    let marginTop: string | undefined = '1em';
    let marginLeft: string | undefined;

    if (marginType != null) {
      marginTop = undefined;
    }

    if (marginType === 'left-margin') {
      marginLeft = '1em';
    }

    return {
      ...sx,
      marginTop,
      marginLeft,
    };
  }, [marginType, sx]);

  const getLabel = useCallback<(option: any) => string>(
    (option: any) => {
      if (option == null) {
        return '';
      }

      if (optionLabel == null) {
        return option;
      }

      return option[optionLabel];
    },
    [optionLabel],
  );

  const isOptionValueEqual = useCallback(
    (option: any, cvalue: any) => {
      if (optionValue == null) {
        return option === cvalue;
      }

      return option[optionValue] === cvalue[optionValue];
    },
    [optionValue],
  );

  return (
    <Autocomplete
      {...AutoCompleteProps}
      noOptionsText="Nenhuma Opção"
      getOptionLabel={getLabel}
      freeSolo={freeSolo}
      multiple={multiple}
      options={options}
      disabled={disabled}
      onChange={(_, data) => onChange(data as T)}
      isOptionEqualToValue={isOptionValueEqual}
      PopperComponent={PopperStyled}
      value={value}
      popupIcon={<ArrowDropDownCircleOutlined />}
      renderOption={renderOption ?? undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          {...otherProps}
          error={errors != null}
          helperText={errors ?? ''}
          inputProps={{
            ...params.inputProps,
          }}
          sx={sxFixed}
        />
      )}
      fullWidth={fullWidth}
    />
  );
}

export const CustomSelectMemo = React.memo(CustomSelect);
