import { Box, styled } from '@mui/material';

export const PlanoContainer = styled(Box)`
  padding: 13px;
  margin-top: 25px;
  border: 1px solid #d0d0d0;

  > h2 {
    font-family: 'Archivo';
    font-size: 1.75rem;
    font-weight: 500;
    color: #35353f;

    width: 100%;
    text-align: center;

    margin: 40px 0;
  }
`;

export const PlanoEnsinoHeaderContainer = styled('header')`
  border: 1px solid #35353fcc;
  display: flex;
  align-items: center;

  > div {
    height: 100%;
  }

  div.logo {
    padding: 19px;
    border-right: 1px solid #35353fcc;

    img {
      width: 392px;
    }

    @media screen {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  div.curso {
    padding: 13px;
    align-self: center;

    p {
      color: #35649f;
      font-size: 1.75rem;
      font-family: 'Archivo';
      font-weight: 500;
    }
  }
`;

export const TableStyled = styled('table')`
  width: 100%;
  margin-bottom: 3px;

  th {
    background: #35649f;
    color: #fff;
    text-align: center;
    border: 2px solid #35353fcc;
    font-size: 1.375rem;
    font-family: 'Archivo';
    border-left: 0;

    &:first-of-type {
      border-left: 2px solid #35353fcc;
    }
  }

  td {
    color: #35353f;
    font-family: 'Archivo';
    font-size: 1.25rem;
    text-align: center;
    border: 2px solid #35353fcc;
    border-left: 0;
    border-top: 0;

    &:first-of-type {
      border-left: 2px solid #35353fcc;
    }
  }
`;

export const BoxInfo = styled(Box)`
  margin-bottom: 20px;

  header {
    background: #35649f;
    border: 2px solid #35353f;
    padding: 10px;

    p {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 1.375rem;
    }
  }

  > div {
    border: 2px solid #35353fcc;
    border-top: 0;
    padding: 10px;
    font-size: 1.25rem !important;
    font-family: 'Archivo' !important;
    color: #35353f !important;

    p,
    span {
      font-size: 1.25rem !important;
      font-family: 'Archivo' !important;
      color: #35353f !important;
    }
  }
`;

export const CargaHorariaTableContainer = styled(Box)`
  overflow: auto;
  position: relative;
  margin-bottom: 20px;

  > table {
    min-width: 950px;
  }
`;

export const DisciplinaTableContainer = styled(Box)`
  overflow: auto;
  position: relative;
  margin-bottom: 20px;

  > table {
    min-width: 590px;
  }
`;
