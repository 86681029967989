export function hoursToTextDuration(hours: number): string {
  const horas = Math.floor(hours);

  const minutos = Math.floor((hours - horas) * 60);

  const horasText = horas < 10 ? `0${horas}` : horas.toString();

  const minutosText = minutos < 10 ? `0${minutos}` : minutos.toString();

  return `${horasText}:${minutosText}`;
}

export function textDurationToHours(duration: string): number {
  if (!/^[0-9]{2}:[0-5][0-9]$/g.test(duration)) {
    return 0;
  }

  const [horas, minutos] = duration.split(':');

  return Number(horas) + Number(minutos) / 60;
}
