import { Box, Paper, styled } from '@mui/material';

import { CustomIconButtonMemo } from '#shared/components/CustomIconButton';

interface IPlanoAulaCardContainer {
  color: string;
}

export const PlanoAulaCardContainer = styled(Paper)<IPlanoAulaCardContainer>`
  border-radius: 10px 10px 5px 5px;

  & + div {
    margin-top: 1rem;
  }

  > header {
    position: relative;

    border-radius: 10px 10px 0 0;
    padding: 0.5rem;
    background: ${({ color }) => color};
    cursor: pointer;

    > p {
      font-size: 1.25rem;
      font-family: 'Archivo';
      text-align: center;
      color: #fff;
    }
  }
`;

export const CardContent = styled(Box)`
  padding: 1rem;
`;

export const ValidateIcon = styled(CustomIconButtonMemo)`
  position: absolute;

  right: 5px;
  top: 5px;
`;
