import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';

import { concatArrayStrings } from '#shared/utils/concatArrayStrings';
import { mapDiaNumeroTexto } from '#shared/utils/diaTransform';
import { parseDateApi } from '#shared/utils/parseDateApi';

import { IAulaInfo, IDisciplinaGet } from '../types/disciplina';
import { TipoModalidade } from '../types/enums/TipoModalidade';
import { peAvaliacoesFormActions } from './peAvaliacoesFormSlice';
import { peObjetivosFormActions } from './peObjetivosFormSlice';
import { planoAulasFormActions } from './planoAulasFormSlice';

export interface IDisciplinaInfo extends Omit<IDisciplinaGet, 'professor' | 'aulas'> {
  professor: string;
  aulas: IAulaInfo[];
  diasAulas: string[];
  mapDomingosLetivos: Record<string, boolean>;
}

export interface IDisciplinaGerenciadaInfo extends IDisciplinaInfo {
  grade: string;
}

interface IUpdateCredidos {
  creditosPresencial: number;
  creditosEad: number;
  diasEad: string[];
  diasPresencial: string[];
}

export type IDiasSemanaMap = Record<string, 'presencial' | 'ead' | null>;

type IDiasDisciplina = Record<string, boolean>;

export interface IDisciplinaInfoState {
  data: IDisciplinaInfo | null;
  idPlanoEnsino: number;
  obsAvaliacoes?: string;
  feedback?: string;
  creditos: {
    creditosPresencial?: number;
    creditosEad?: number;
  };
  diasSemanas: IDiasSemanaMap;
  changedInfo: boolean;
  status: number;
}

const initialState: IDisciplinaInfoState = {
  data: null,
  changedInfo: false,
  idPlanoEnsino: 0,
  status: 0,
  creditos: {},
  diasSemanas: {},
};

export const disciplinaInfoSlice = createSlice({
  name: 'disciplinaInfo',
  initialState,
  reducers: {
    updateInfo: (state, { payload: disciplina }: PayloadAction<IDisciplinaInfo>) => {
      state.data = null;
      state.changedInfo = false;
      state.idPlanoEnsino = 0;
      state.status = 0;
      state.creditos = {};

      state.obsAvaliacoes = disciplina.Obs;

      state.feedback = disciplina.Feedback;

      state.data = { ...disciplina };

      if (disciplina.tipoModalidade === TipoModalidade.split) {
        const mapDomingos = disciplina.domingosLetivos.reduce<Record<string, boolean>>(
          (map, dia) => ({ ...map, [dia]: true }),
          {},
        );

        const mapDiasPresencial =
          disciplina.diasPresencial?.reduce<IDiasDisciplina>(
            (map, dia) => ({ ...map, [mapDiaNumeroTexto[dia]]: true }),
            {},
          ) ?? {};

        const mapDiasEad =
          disciplina.diasEad?.reduce<IDiasDisciplina>(
            (map, dia) => ({ ...map, [mapDiaNumeroTexto[dia]]: true }),
            {},
          ) ?? {};

        let keepSunday = false;

        const diasMap = disciplina.aulas.reduce<IDiasSemanaMap>((map, aula) => {
          const dia = aula.diaSemana;

          const defaultValue = mapDiasPresencial[dia]
            ? 'presencial'
            : mapDiasEad[dia]
            ? 'ead'
            : null;

          const calcValue = aula.numAulas === 1 ? 'ead' : 'presencial';

          if (aula.diaSemana === 'domingo' && !mapDomingos[aula.data]) {
            keepSunday = true;
          }

          map[dia] = defaultValue ?? calcValue;

          return map;
        }, {});

        if (!keepSunday && diasMap.domingo !== undefined) {
          delete diasMap.domingo;
        }

        state.diasSemanas = diasMap;

        state.data.mapDomingosLetivos = mapDomingos;

        state.data.diasAulas = Object.keys(diasMap);

        const fixedCreditosPresencial = disciplina.creditos - 1;

        state.creditos = {
          creditosEad: disciplina.creditosEad ?? 1,
          creditosPresencial: disciplina.creditosPresencial ?? fixedCreditosPresencial,
        };

        state.data.creditosEad = state.data.creditosEad ?? 1;
        state.data.creditosPresencial = state.data.creditosPresencial ?? fixedCreditosPresencial;

        const diasEadCalc: string[] = [];
        const diasPresencialCalc: string[] = [];

        Object.entries(diasMap).forEach(([dia, tipo]) => {
          if (tipo === 'ead') {
            diasEadCalc.push(dia);
          } else if (tipo === 'presencial') {
            diasPresencialCalc.push(dia);
          }
        });

        // if (state.data.diasEad == null || state.data.diasEad.length === 0) {
        state.data.diasEad = diasEadCalc;
        // }

        // if (state.data.diasPresencial == null || state.data.diasPresencial.length === 0) {
        state.data.diasPresencial = diasPresencialCalc;
        // }

        if (
          disciplina.creditosPresencial !== state.creditos.creditosPresencial ||
          disciplina.creditosEad !== state.creditos.creditosEad
        ) {
          state.changedInfo = true;
        }
      }

      state.idPlanoEnsino = disciplina.idPlanoEnsino ?? 0;
      state.status = disciplina.status ?? 0;
    },
    updateObsAvaliacao: (state, { payload: obs }: PayloadAction<string>) => {
      state.obsAvaliacoes = obs;

      state.changedInfo = true;
    },
    updateFeedback: (state, { payload: feedback }: PayloadAction<string>) => {
      state.feedback = feedback;
    },
    updateCreditos: (
      state,
      {
        payload: { creditosEad, creditosPresencial, diasEad, diasPresencial },
      }: PayloadAction<IUpdateCredidos>,
    ) => {
      state.creditos = {
        creditosEad,
        creditosPresencial,
      };

      diasEad.forEach((dia) => {
        state.diasSemanas[dia] = 'ead';
      });

      diasPresencial.forEach((dia) => {
        state.diasSemanas[dia] = 'presencial';
      });

      state.changedInfo = true;
    },
    updateStatus: (state, { payload: status }: PayloadAction<number>) => {
      state.status = status;
    },
    updateIdPlanoEnsino: (state, { payload: idPlanoEnsino }: PayloadAction<number>) => {
      state.idPlanoEnsino = idPlanoEnsino;
    },
    saveAlterations: (state) => {
      state.changedInfo = false;
    },
    clearData: (state) => {
      state.data = null;
      state.changedInfo = false;
      state.idPlanoEnsino = 0;
      state.status = 0;
      state.creditos = {};
    },
  },
});

const updateDisciplinaInfo =
  (disciplina: IDisciplinaGet): AppThunk =>
  (dispatch) => {
    const aulasMaped = disciplina.aulas.map<IAulaInfo>((aula) => {
      return {
        numAulas: aula.numAulas,
        idTurmaDisc: disciplina.idTurmaDisc,
        validacao: aula.validacao ?? false,
        data: parseDateApi(aula.data, 'dd/MM/yyyy', 'Sem Data'),
        diaSemana: parseDateApi(aula.data, 'EEEE', 'Sem Data'),
        chEad: aula.chEad,
        chPresencial: aula.chPresencial,
        chAed: aula.chAed ?? '',
        textoAed: aula.textoAed ?? '',
        objetivos: aula.objetivos ?? [],
        conteudo: aula.conteudo ?? '',
        metodologias: aula.metodologias ?? [],
        referencias: aula.referencias ?? [],
        idPlanoEnsinoAula: aula.idPlanoEnsinoAula ?? 0,
        tipoAula: aula.tipoAula,
      };
    });

    const disciplinaInfo = {
      ...disciplina,
      professor: concatArrayStrings(disciplina.professor),
      aulas: aulasMaped,
      diasAulas: [],
      mapDomingosLetivos: {},
    };

    dispatch(disciplinaInfoActions.updateInfo(disciplinaInfo));

    if (disciplinaInfo != null) {
      dispatch(planoAulasFormActions.loadingData(disciplinaInfo.aulas));

      dispatch(peAvaliacoesFormActions.loadingData(disciplinaInfo));

      dispatch(peObjetivosFormActions.loadingData(disciplinaInfo));
    }
  };

const updateCreditosDisciplina =
  (payload: IUpdateCredidos): AppThunk =>
  (dispatch, getState) => {
    dispatch(disciplinaInfoActions.updateCreditos(payload));

    const newState = getState();

    if (newState.disciplinaInfo.data != null) {
      dispatch(
        planoAulasFormActions.updateStatusSplit({
          diasSemanas: newState.disciplinaInfo.diasSemanas,
          disciplina: newState.disciplinaInfo.data,
        }),
      );
    }
  };

export const disciplinaInfoActions = {
  ...disciplinaInfoSlice.actions,
  updateDisciplinaInfo,
  updateCreditosDisciplina,
};

export const disciplinaInfoSelector = {
  disciplina: (state: RootState) => state.disciplinaInfo.data,
  idPlanoEnsino: (state: RootState) => state.disciplinaInfo.idPlanoEnsino,
  status: (state: RootState) => state.disciplinaInfo.status,
  obsAvaliacao: (state: RootState) => state.disciplinaInfo.obsAvaliacoes,
  feedback: (state: RootState) => state.disciplinaInfo.feedback,
  creditos: (state: RootState) => state.disciplinaInfo.creditos,
  diasSemanas: (state: RootState) => state.disciplinaInfo.diasSemanas,
  changed: (state: RootState) => state.disciplinaInfo.changedInfo,
};
