import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { FormTextFieldMemo } from '#shared/components/form/FormTextField';
import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';
import { usePost } from '#shared/services/useAxios';
import { CustomButton } from '#shared/styledComponents/common';

import {
  disciplinaInfoActions,
  disciplinaInfoSelector,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peAvaliacoesFormSelector } from '#modules/disciplinas/redux/peAvaliacoesFormSlice';
import { planoAulasFormSelector } from '#modules/disciplinas/redux/planoAulasFormSlice';
import { IDisciplinaSendValidation } from '#modules/disciplinas/types/disciplina';

import { SendSecretariaMemo } from './sendSecretaria';
import { FeedBackContainer, SendValidationContainer } from './styles';

type IChoice = 'none' | 'sim' | 'nao';

interface IFeedbackSchema {
  feedback: string;
}

const feedbackSchema = yup.object().shape({
  feedback: yup.string().required('O feedback é obrigatório'),
});

export function SendValidation() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaFeedback = useAppSelector(disciplinaInfoSelector.feedback);
  const disciplinaIdPlanoEnsino = useAppSelector(disciplinaInfoSelector.idPlanoEnsino);
  const aulasChanged = useAppSelector(planoAulasFormSelector.changedItems);
  const avaliacoesChanged = useAppSelector(peAvaliacoesFormSelector.changedItems);

  const dispatch = useAppDispatch();

  const [choice, setChoice] = useState<IChoice>('none');
  const [feedback, setFeedback] = useState('');
  const [dialogValidation, setDialogValidation] = useState(false);

  const { toast } = useToast();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFeedbackSchema>({
    resolver: yupResolver(feedbackSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { loading: sendValidationLoading, send: sendValidationApi } = usePost<
    any,
    IDisciplinaSendValidation
  >(`/disciplinas/validation/${disciplina?.idTurmaDisc}/revalidate`);

  const sendValidationSubmit = (data: IFeedbackSchema) => {
    setFeedback(data.feedback);

    setDialogValidation(true);
  };

  const sendValidation = async () => {
    if (disciplina?.idTurmaDisc != null) {
      const { error } = await sendValidationApi({
        feedback,
        idPlanoEnsino: disciplinaIdPlanoEnsino,
      });

      if (error != null) {
        toast({ message: error, severity: 'error' });
      } else {
        setDialogValidation(false);

        dispatch(disciplinaInfoActions.updateFeedback(feedback));

        dispatch(disciplinaInfoActions.updateStatus(3));

        toast({ message: 'Plano de ensino enviado para o professor', severity: 'success' });
      }
    }
  };

  const canSend =
    Object.keys(aulasChanged).length === 0 && Object.keys(avaliacoesChanged).length === 0;

  return (
    <>
      <LoadingMemo loading={sendValidationLoading} />

      <SendValidationContainer>
        <Typography>
          O professor preencheu o arquivo segundo os critérios estabelecidos para o plano de ensino?
        </Typography>

        <RadioGroup row value={choice} onChange={(e) => setChoice(e.target.value as IChoice)}>
          <FormControlLabel
            value="none"
            control={<Radio />}
            label="Nenhum"
            sx={{ display: 'none' }}
          />

          <FormControlLabel
            value="sim"
            control={<Radio sx={{ color: '#8CC59A', '&.Mui-checked': { color: '#8CC59A' } }} />}
            label="Sim"
          />
          <FormControlLabel
            value="nao"
            control={<Radio sx={{ color: '#E03E1A', '&.Mui-checked': { color: '#E03E1A' } }} />}
            label="Não"
          />
        </RadioGroup>
      </SendValidationContainer>

      {choice === 'nao' && (
        <FeedBackContainer>
          <CustomDialogMemo
            open={dialogValidation}
            title="Confirmação"
            closeModal={() => setDialogValidation(false)}
            maxWidth="xs"
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                textAlign: 'justify',
              }}
            >
              Apertar esse botão, significa que o arquivo foi preenchido da maneira incorreta e será
              encaminhado para o professor para preencher novamente.
            </Typography>

            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '0.9375rem',
                fontFamily: 'Montserrat',
                mt: '1rem',
                textAlign: 'justify',
              }}
            >
              Sendo assim, tem certeza que deseja enviar o arquivo?
            </Typography>

            <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center' }}>
              <CustomButton
                variant="contained"
                custom_color="#5BAB68"
                fullWidth
                sx={{ mr: '1rem' }}
                onClick={async () => await sendValidation()}
              >
                Sim
              </CustomButton>

              <CustomButton
                variant="contained"
                custom_color="#E03E1A"
                fullWidth
                onClick={() => setDialogValidation(false)}
              >
                Não
              </CustomButton>
            </Box>
          </CustomDialogMemo>

          <form noValidate onSubmit={handleSubmit(sendValidationSubmit)}>
            <Box className="input">
              <label htmlFor="feedback">Feedback</label>

              <FormTextFieldMemo
                id="feedback"
                control={control}
                name="feedback"
                errors={errors.feedback}
                marginType="no-margin"
                multiline
                minRows={3}
                defaultValue={disciplinaFeedback}
              />
            </Box>

            <Box className="button">
              <Box>
                <Button type="submit" disabled={!canSend} variant="contained">
                  Enviar arquivo para o professor
                </Button>

                {!canSend && <Typography>Salve antes de enviar</Typography>}
              </Box>
            </Box>
          </form>
        </FeedBackContainer>
      )}

      {choice === 'sim' && <SendSecretariaMemo />}
    </>
  );
}

export const SendValidationMemo = React.memo(SendValidation);
