import { OutlinedTextFieldProps, TextField } from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type IFormTextField = Omit<OutlinedTextFieldProps, 'variant'> & {
  name: string;
  control: Control<any>;
  errors?: FieldError | undefined;
  marginType?: 'no-margin' | 'left-margin';
  min?: number;
  max?: number;
  scale?: number;
};

export function FormNumberField({
  errors,
  marginType,
  sx,
  name,
  control,
  defaultValue,
  helperText,
  min,
  max,
  scale,
  onBlur,
  ...props
}: IFormTextField) {
  const sxFixed = useMemo(() => {
    let marginTop: string | undefined = '1em';
    let marginLeft: string | undefined;

    if (marginType != null) {
      marginTop = undefined;
    }

    if (marginType === 'left-margin') {
      marginLeft = '1em';
    }

    return {
      marginTop,
      marginLeft,
      ...sx,
    };
  }, [marginType, sx]);

  const TextMaskCustom = useMemo(
    () =>
      forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
          <IMaskInput
            {...other}
            mask={Number}
            scale={scale}
            min={min}
            max={max}
            thousandsSeparator="."
            radix=","
            mapToRadix={['.']}
            inputRef={ref as any}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          onBlur={(e) => {
            field.onBlur();

            if (onBlur != null) {
              onBlur(e);
            }
          }}
          variant="outlined"
          error={!(errors == null)}
          sx={sxFixed}
          helperText={errors != null ? errors.message : helperText}
          InputProps={{
            inputComponent: TextMaskCustom as any,
          }}
          {...props}
        />
      )}
    />
  );
}

export const FormNumberFieldMemo = React.memo(FormNumberField);
