import { Box, styled } from '@mui/material';

export const FormCheckboxContainer = styled(Box)`
  > span {
    display: block;
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    margin-left: 10px;
    color: #d32f2f;
  }
`;
