import { Popover } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';

import { FilterFormDisciplinasMemo } from '#modules/home/pages/Home/filter';
import { disciplinasFilterSelector } from '#modules/home/redux/disciplinasFilterSlice';

interface IDisciplinasTableFormPopover {
  filterAnchor: HTMLElement | null;
  updateFilterAnchor: (element: HTMLElement | null) => void;
}

export function DisciplinasTableFormPopover({
  filterAnchor,
  updateFilterAnchor,
}: IDisciplinasTableFormPopover) {
  const filtro = useAppSelector(disciplinasFilterSelector.filtro);

  useEffect(() => {
    updateFilterAnchor(null);
  }, [filtro, updateFilterAnchor]);

  return (
    <Popover
      open={filterAnchor != null}
      anchorEl={filterAnchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={() => updateFilterAnchor(null)}
      keepMounted
    >
      <FilterFormDisciplinasMemo />
    </Popover>
  );
}

export const DisciplinasTableFormPopoverMemo = React.memo(DisciplinasTableFormPopover);
