import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

export interface IRadioOption {
  value: string;
  label: string;
}

type IFormTextField = RadioGroupProps & {
  name: string;
  control: Control<any>;
  defaultValue?: string | null;
  marginType?: 'no-margin' | 'left-margin';
  options: IRadioOption[];
  customOnChange?: (value: any) => void;
  disabled?: boolean;
};

export function FormRadioGroup({
  name,
  control,
  defaultValue,
  options,
  customOnChange,
  disabled,
  ...props
}: IFormTextField) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup
          {...field}
          {...props}
          onChange={(e) => {
            field.onChange(e);

            if (customOnChange != null) {
              customOnChange(e.target.value);
            }
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
}

export const FormRadioGroupMemo = React.memo(FormRadioGroup);
