import { AccountCircle, Logout } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '#shared/hooks/auth';

import { CustomSelectMemo } from '../inputs/CustomSelect';
import { DisciplinaMenuMemo } from './disciplinaMenu';
import { AppBarContainer, autoCompleteTheme } from './styles';

export function SiteAppBar() {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();
  const { user, signOut, changePerfil } = useAuth();
  const location = useLocation();

  if (location.pathname === '/auth') return <></>;

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <AppBarContainer disableGutters>
          <Typography component="h1">PE UNASP</Typography>

          <Box className="menu-md" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button onClick={() => navigate('/')}>Home</Button>
          </Box>

          <Box className="user">
            {location.pathname.includes('/disciplinas/') && <DisciplinaMenuMemo />}

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <CustomSelectMemo
                label="Atuação"
                onChange={async (data) => {
                  await changePerfil({ perfil: data?.id });
                }}
                value={user.perfilSelected}
                options={user.perfis ?? []}
                optionLabel="perfil"
                optionValue="id"
                marginType="no-margin"
                sx={{ minWidth: '200px' }}
                size="small"
                AutoCompleteProps={{
                  size: 'small',
                  fullWidth: true,
                  sx: autoCompleteTheme,
                }}
              />
            </Box>

            <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <AccountCircle sx={{ color: '#fff', fontSize: '2.1875rem' }} />
            </IconButton>
          </Box>
        </AppBarContainer>

        <Menu anchorEl={menuAnchor} open={menuAnchor != null} onClose={() => setMenuAnchor(null)}>
          <CustomSelectMemo
            label="Atuação"
            onChange={async (data) => {
              await changePerfil({ perfil: data?.id });
            }}
            value={user.perfilSelected}
            options={user.perfis ?? []}
            optionLabel="perfil"
            optionValue="id"
            marginType="no-margin"
            sx={{ width: '250px', m: '1rem' }}
          />

          <MenuItem
            onClick={async () => {
              setMenuAnchor(null);

              await signOut();
            }}
            sx={{ py: '1rem', borderTop: '1px solid #333' }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sair
          </MenuItem>
        </Menu>
      </Container>
    </AppBar>
  );
}
