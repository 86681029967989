import { Close } from '@mui/icons-material';
import { Typography, IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function DisciplinasHeader() {
  const navigate = useNavigate();

  return (
    <header>
      <Typography component="h1">Plano de Ensino</Typography>

      <IconButton size="small" onClick={() => navigate('/')}>
        <Close htmlColor="#35649F" fontSize="large" />
      </IconButton>
    </header>
  );
}

export const DisciplinasHeaderMemo = React.memo(DisciplinasHeader);
