import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { BoxInfo } from './styles';

export function PlanoEnsinoBibliografiaBasica() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  if (disciplina == null) return <></>;

  return (
    <BoxInfo id="bibliografia-basica">
      <header>
        <Typography>BIBLIOGRAFIA BÁSICA</Typography>
      </header>

      <div dangerouslySetInnerHTML={{ __html: disciplina.bibliografiaBasica }} />
    </BoxInfo>
  );
}
