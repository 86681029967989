import { css, styled } from '@mui/material';
import { Link } from 'react-router-dom';

interface ILinkProps {
  disabled?: boolean;
}

export const LinkStyled = styled(Link)<ILinkProps>`
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 700;

  background: #020560;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  color: #fff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;

  ${(props) =>
    props.disabled === true &&
    css`
      pointer-events: none;
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    `}
`;
