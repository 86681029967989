import { Close } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@mui/material';
import React from 'react';
import { ReactNode } from 'react';

import { CustomIconButtonMemo } from '../CustomIconButton';
import { Content, Title } from './styles';

interface ICDialogProps {
  open: boolean;
  title: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeModal: (e: React.MouseEvent<any, MouseEvent>) => void;
  children: ReactNode;
  customActions?: ReactNode;
  zIndex?: number;
}

export function CustomDialog({
  open,
  title,
  children,
  maxWidth,
  closeModal,
  customActions,
  zIndex,
}: ICDialogProps) {
  return (
    <Dialog
      open={open}
      fullScreen={maxWidth == null}
      maxWidth={maxWidth}
      fullWidth
      onClose={closeModal}
      sx={{ zIndex: zIndex ?? 1300 }}
    >
      <Title>
        <CustomIconButtonMemo
          title="Fechar"
          action={closeModal}
          Icon={<Close htmlColor="#fff" />}
        />

        <Typography component="h3">{title}</Typography>

        <Box>{customActions}</Box>
      </Title>

      <Content>{children}</Content>
    </Dialog>
  );
}

export const CustomDialogMemo = React.memo(CustomDialog);
