import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PopupContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 2rem;

  svg {
    font-size: 4rem;
  }
`;
