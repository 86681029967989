import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

import { IObjetivoSchema, objetivoSchema } from '../schemas/objetivosSchema';
import { IItemValue, IObjetivo, ISaveObjetivoResponse } from '../types/disciplina';
import { IDisciplinaInfo } from './disciplinaInfoSlice';

export type IObjetivoType = 'media' | 'bonus' | 'recuperacao';

export interface IObjetivoForm {
  idObjetivo: number;
  idTurmaDisc: number;
  item: number;
  competencias: string[];
  perfis: string[];
  taxonomia: string | null;
  descricao: string;
}

interface ISelectItem {
  idTurmaDisc: number;
  name: string;
}

interface IUpdateForm {
  idTurmaDisc: number;
  item: number;
  form: {
    competencias: string[];
    perfis: string[];
    taxonomia: string | null;
    descricao: string;
  };
}

interface IFormFixo {
  taxonomia: string | null;
  descricao: string;
}

interface IFormVariado {
  idObjetivo: number;
  idTurmaDisc: number;
  competencias: string[];
  perfis: string[];
}

export interface IPeObjetivosFormState {
  selectedItem: ISelectItem | null;
  itemsOptions: ISelectItem[];
  perfis: Record<string, IItemValue[]>;
  competencias: Record<string, IItemValue[]>;
  changedStatus: Record<number, Record<number, boolean>>;
  formFixo: Record<number, IFormFixo>;
  formVariado: Record<number, Record<number, IFormVariado>>;
  formDefault: {
    fixo: Record<number, IFormFixo>;
    variado: Record<number, Record<number, IFormVariado>>;
  };
  validStatus: Record<number, Record<number, boolean>>;
}

const initialState: IPeObjetivosFormState = {
  changedStatus: {},
  competencias: {},
  perfis: {},
  selectedItem: null,
  itemsOptions: [],
  formFixo: {},
  formVariado: {},
  formDefault: { fixo: {}, variado: {} },
  validStatus: {},
};

export const peObjetivosFormSlice = createSlice({
  name: 'peObjetivosForm',
  initialState,
  reducers: {
    loadingData: (state, { payload: disciplina }: PayloadAction<IDisciplinaInfo>) => {
      state.changedStatus = {};
      state.competencias = {};
      state.perfis = {};
      state.selectedItem = null;
      state.itemsOptions = [];
      state.formFixo = {};
      state.formVariado = {};

      // Quando não for gerencial
      if (!disciplina.gerencial) {
        const key = disciplina.idTurmaDisc;

        const item: ISelectItem = {
          idTurmaDisc: key,
          name: `${disciplina.curso} - ${disciplina.campus} - ${disciplina.codTurma}`,
        };

        state.selectedItem = item;
        state.itemsOptions = [item];

        state.competencias[key] = disciplina.competencias;
        state.perfis[key] = disciplina.perfis;

        const objetivosMap = disciplina.objetivos.reduce<Record<number, IObjetivo | undefined>>(
          (map, objetivo) => {
            map[objetivo.item] = objetivo;

            return map;
          },
          {},
        );

        [1, 2, 3].forEach((item) => {
          state.formFixo[item] = {
            descricao: objetivosMap[item]?.descricao ?? '',
            taxonomia: objetivosMap[item]?.taxonomia ?? null,
          };

          state.formVariado[item] = {
            [disciplina.idTurmaDisc]: {
              idTurmaDisc: disciplina.idTurmaDisc,
              competencias: objetivosMap[item]?.competencias ?? [],
              perfis: objetivosMap[item]?.perfis ?? [],
              idObjetivo: objetivosMap[item]?.idObjetivo ?? 0,
            },
          };

          const form: IObjetivoSchema = {
            descricao: objetivosMap[item]?.descricao ?? '',
            perfis: objetivosMap[item]?.perfis?.map((per) => ({ item: per } as any)) ?? [],
            competencias:
              objetivosMap[item]?.competencias?.map((comp) => ({ item: comp } as any)) ?? [],
            taxonomia: objetivosMap[item]?.taxonomia ?? null,
          };

          state.validStatus[disciplina.idTurmaDisc] = {
            ...state.validStatus[disciplina.idTurmaDisc],
            [item]: objetivoSchema.isValidSync(form),
          };
        });
      }
      // Quando for gerencial
      else {
        const descricao: Record<number, string> = {
          1: '',
          2: '',
          3: '',
        };

        const taxonomia: Record<number, string | null> = {
          1: null,
          2: null,
          3: null,
        };

        disciplina.gerenciadas.forEach((gerenciada) => {
          const key = gerenciada.idTurmaDisc;

          state.competencias[key] = gerenciada.competencias;
          state.perfis[key] = gerenciada.perfis;

          const objetivosMap = gerenciada.objetivos.reduce<Record<number, IObjetivo | undefined>>(
            (map, objetivo) => {
              map[objetivo.item] = objetivo;

              return map;
            },
            {},
          );

          [1, 2, 3].forEach((item) => {
            if (descricao[item] === '') {
              descricao[item] = objetivosMap[item]?.descricao ?? '';
            }

            if (taxonomia[item] == null || taxonomia[item] === '') {
              taxonomia[item] = objetivosMap[item]?.taxonomia ?? null;
            }

            if (state.formVariado[item] == null) {
              state.formVariado[item] = {
                [gerenciada.idTurmaDisc]: {
                  idTurmaDisc: gerenciada.idTurmaDisc,
                  competencias: objetivosMap[item]?.competencias ?? [],
                  perfis: objetivosMap[item]?.perfis ?? [],
                  idObjetivo: objetivosMap[item]?.idObjetivo ?? 0,
                },
              };
            } else {
              state.formVariado[item][gerenciada.idTurmaDisc] = {
                idTurmaDisc: gerenciada.idTurmaDisc,
                competencias: objetivosMap[item]?.competencias ?? [],
                perfis: objetivosMap[item]?.perfis ?? [],
                idObjetivo: objetivosMap[item]?.idObjetivo ?? 0,
              };
            }

            const form: IObjetivoSchema = {
              descricao: objetivosMap[item]?.descricao ?? '',
              perfis: objetivosMap[item]?.perfis?.map((per) => ({ item: per } as any)) ?? [],
              competencias:
                objetivosMap[item]?.competencias?.map((comp) => ({ item: comp } as any)) ?? [],
              taxonomia: objetivosMap[item]?.taxonomia ?? null,
            };

            state.validStatus[gerenciada.idTurmaDisc] = {
              ...state.validStatus[gerenciada.idTurmaDisc],
              [item]: objetivoSchema.isValidSync(form),
            };
          });

          state.itemsOptions.push({
            idTurmaDisc: key,
            name: `${gerenciada.curso} - ${gerenciada.campus} - ${gerenciada.codTurma} - ${gerenciada.grade}`,
          });
        });

        [1, 2, 3].forEach((item) => {
          state.formFixo[item] = {
            descricao: descricao[item] ?? '',
            taxonomia: taxonomia[item] ?? null,
          };
        });
      }

      state.formDefault = { fixo: state.formFixo, variado: state.formVariado };
    },
    updateForm: (state, { payload: { form, idTurmaDisc, item } }: PayloadAction<IUpdateForm>) => {
      if (
        state.formFixo[item].descricao !== form.descricao ||
        state.formFixo[item].taxonomia !== form.taxonomia
      ) {
        Object.keys(state.changedStatus[item] ?? {}).forEach((key) => {
          state.changedStatus[item][Number(key)] = true;
        });
      }

      state.changedStatus[item] = {
        ...state.changedStatus[item],
        [idTurmaDisc]: true,
      };

      state.formFixo[item].descricao = form.descricao;
      state.formFixo[item].taxonomia = form.taxonomia;

      state.formVariado[item][idTurmaDisc] = {
        idObjetivo: state.formVariado[item][idTurmaDisc].idObjetivo ?? 0,
        idTurmaDisc,
        competencias: form.competencias,
        perfis: form.perfis,
      };

      state.validStatus[idTurmaDisc][item] = true;
    },
    changeCurso: (state, { payload: item }: PayloadAction<ISelectItem>) => {
      state.formDefault = { fixo: state.formFixo, variado: state.formVariado };

      state.selectedItem = item;
    },
    saveObjetivos: (state, { payload }: PayloadAction<ISaveObjetivoResponse[]>) => {
      payload.forEach((objetivo) => {
        if (
          state.formVariado[objetivo.item] != null ||
          state.formVariado[objetivo.item][objetivo.idTurmaDisc] != null
        ) {
          state.formVariado[objetivo.item][objetivo.idTurmaDisc].idObjetivo = objetivo.idObjetivo;

          delete state.changedStatus[objetivo.item][objetivo.idTurmaDisc];
        }
      });
    },
  },
});

export const peObjetivosFormActions = { ...peObjetivosFormSlice.actions };

export const peObjetivosFormSelector = {
  competencias: (state: RootState) => state.peObjetivosForm.competencias,
  perfis: (state: RootState) => state.peObjetivosForm.perfis,
  changedStatus: (state: RootState) => state.peObjetivosForm.changedStatus,
  selectedItem: (state: RootState) => state.peObjetivosForm.selectedItem,
  itemsOptions: (state: RootState) => state.peObjetivosForm.itemsOptions,
  formDefault: (state: RootState) => state.peObjetivosForm.formDefault,
  formFixo: (state: RootState) => state.peObjetivosForm.formFixo,
  formVariado: (state: RootState) => state.peObjetivosForm.formVariado,
  validStatus: (state: RootState) => state.peObjetivosForm.validStatus,
};
