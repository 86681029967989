import { IUser } from '#modules/users/types/user';

import { perfilEnum, statusEnum } from './status';

interface IGetEditPermission {
  user: IUser;
  disciplinaStatus: number;
  tipoStatus?: 'professor' | 'coordenador';
  tipoPerfil?: 'professor' | 'coordenador' | 'gestor';
}

const statusInvalidosMap = {
  professor: [statusEnum.comCoordenador, statusEnum.naSecretaria],
  coordenador: [
    statusEnum.emPreenchimento,
    statusEnum.naSecretaria,
    statusEnum.naoPreenchido,
    statusEnum.emValidacao,
  ],
};

export function cannotEditDisciplina({
  disciplinaStatus,
  user,
  tipoStatus,
  tipoPerfil,
}: IGetEditPermission) {
  if (user.perfilSelected.id === perfilEnum.visualizacao) return true;

  const statusInvalidos = statusInvalidosMap[tipoStatus ?? 'professor'];

  const perfil = tipoPerfil ?? 'professor';

  const isStatusInvalid = statusInvalidos.reduce(
    (result, status) => result || disciplinaStatus === status,
    false,
  );

  return user.perfilSelected.id !== perfilEnum[perfil] || isStatusInvalid;
}
