import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

import { axiosClient } from '#shared/services/axiosClient';
import { IAutoCompleteOptions } from '#shared/types/autocomplete';
import { getError } from '#shared/utils/getError';

import { INaturezaAvaliacao, ISaveAvaliacaoResponse } from '../types/disciplina';
import { modalidadeAvaliacaoMap } from '../types/enums/modalidadeAvaliacao';
import { IDisciplinaInfo } from './disciplinaInfoSlice';

export type IAvaliacaoType = 'media' | 'bonus' | 'recuperacao';

export interface IAvaliacaoForm {
  id: string;
  idAvaliacao: number;
  idTurmaDisc: number;
  tipo: IAvaliacaoType;
  data: string | null;
  sigla: string;
  nome?: string;
  idRecuperacao?: string | null;
  peso?: string;
  bonus?: string;
  validacao?: boolean;
  aed?: boolean;
  modalidade?: number;
  natureza?: IAutoCompleteOptions<INaturezaAvaliacao> | null;
}

interface IAvaliacoesMap {
  [key: string]: IAvaliacaoForm;
}

interface IKeyBoleanMap {
  [key: string]: boolean;
}

interface IUpdateItemAction {
  id: string;
  item: Partial<IAvaliacaoForm>;
}

interface IUpdateValidationAction {
  id: string;
  validation: boolean;
}

export type IStatusThunks = 'notLoaded' | 'pending' | 'fulfilled' | 'rejected';

export interface IPeAvaliacoesFormState {
  avaliacoes: IAvaliacoesMap;
  naturezas: INaturezaAvaliacao[];
  avaliacoesList: IAvaliacaoForm[];
  openStatus: IKeyBoleanMap;
  changedStatus: IKeyBoleanMap;
  openAllState: boolean;
  loadingDelete: boolean;
  deleteError: string | null;
  idIndex: number;

  statusNaturezas: IStatusThunks;
  errorNaturezas: string | null;
}

const initialState: IPeAvaliacoesFormState = {
  avaliacoes: {},
  naturezas: [],
  avaliacoesList: [],
  openStatus: {},
  changedStatus: {},
  openAllState: true,
  loadingDelete: false,
  deleteError: null,
  idIndex: 3,

  statusNaturezas: 'notLoaded',
  errorNaturezas: null,
};

const deleteAvaliacaoAsync = createAsyncThunk(
  'peAvaliacoesForm/deleteItem',
  async (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const avaliacao = state.peAvaliacoesForm.avaliacoes[id];

    if (avaliacao.idAvaliacao === 0) {
      dispatch(peAvaliacoesFormActions.deleteItem(id));
    } else {
      try {
        await axiosClient.delete('/avaliacoes', {
          data: { idAvaliacao: avaliacao.idAvaliacao, idTurmaDisc: avaliacao.idTurmaDisc },
        });

        return {
          error: null,
          id,
        };
      } catch (error) {
        return {
          error: getError(error),
          id,
        };
      }
    }
  },
);

const getNaturezasAsync = createAsyncThunk('peAvaliacoesForm/getNaturezasAsync', async () => {
  try {
    const data = await axiosClient.get<INaturezaAvaliacao[]>('/avaliacoes/naturezas');

    return {
      error: null,
      data,
    };
  } catch (error) {
    return {
      error: getError(error),
      data: null,
    };
  }
});

export const peAvaliacoesFormSlice = createSlice({
  name: 'peAvaliacoesForm',
  initialState,
  reducers: {
    loadingData: (state, { payload }: PayloadAction<IDisciplinaInfo>) => {
      state.avaliacoes = {};
      state.avaliacoesList = [];
      state.openStatus = {};
      state.changedStatus = {};
      state.openAllState = true;
      state.loadingDelete = false;
      state.deleteError = null;
      state.idIndex = 3;

      const avaliacoes = payload.avaliacoes.map((avaliacao) => ({
        ...avaliacao,
        sigla: avaliacao.sigla ?? '',
        validacao: avaliacao.validacao ?? false,
        tipo: avaliacao.tipo ?? 'media',
        peso: avaliacao.peso != null ? avaliacao.peso.toString() : undefined,
        bonus: avaliacao.bonus != null ? avaliacao.bonus.toString() : undefined,
        id: avaliacao.idAvaliacao.toString(),
      }));

      const defaultAvaliacao: IAvaliacaoForm = {
        id: '0_{index}',
        idAvaliacao: 0,
        data: null,
        idTurmaDisc: payload.idTurmaDisc,
        sigla: '',
        tipo: 'media',
        validacao: false,
        nome: '',
        peso: '',
        aed: false,
        modalidade: modalidadeAvaliacaoMap.individual,
        natureza: null,
      };

      const mediaAvaliacoes: IAvaliacaoForm[] = [];
      const outrasAvaliacoes: IAvaliacaoForm[] = [];

      let mediasDefault = 0;

      const minMedias = payload.estagio === true ? 1 : 2;

      avaliacoes.forEach((avaliacao) => {
        if (avaliacao.tipo === 'media' && mediasDefault < minMedias) {
          // keysRemove[avaliacao.id] = avaliacao.id;

          mediasDefault += 1;

          mediaAvaliacoes.push({
            ...avaliacao,
            idRecuperacao: avaliacao.idRecuperacao?.toString(),
            natureza:
              avaliacao.natureza != null
                ? { label: avaliacao.natureza.nome, value: avaliacao.natureza }
                : null,
          });
        } else {
          outrasAvaliacoes.push({
            ...avaliacao,
            idRecuperacao: avaliacao.idRecuperacao?.toString(),
            natureza:
              avaliacao.natureza != null
                ? { label: avaliacao.natureza.nome, value: avaliacao.natureza }
                : null,
          });
        }
      });

      if (mediaAvaliacoes.length < minMedias) {
        const diff = minMedias - mediaAvaliacoes.length;

        for (let i = 1; i <= diff; i++) {
          mediaAvaliacoes.push({ ...defaultAvaliacao, id: `0_${i}` });
        }
      }

      const todasAvaliacoes = [...mediaAvaliacoes, ...outrasAvaliacoes];

      state.avaliacoesList = todasAvaliacoes;

      todasAvaliacoes.forEach((item) => {
        const key = String(item.id);

        state.avaliacoes[key] = item;
        state.openStatus[key] = state.openAllState;
      });
    },
    handleOpenAll: (state) => {
      Object.keys(state.openStatus).forEach((key) => {
        state.openStatus[key] = !state.openAllState;
      });

      state.openAllState = !state.openAllState;
    },
    changeOpenStatus: (state, { payload }: PayloadAction<string>) => {
      state.openStatus[payload] = !state.openStatus[payload];
    },
    updateItem: (state, { payload: { id, item } }: PayloadAction<IUpdateItemAction>) => {
      const key = String(id);

      state.avaliacoes[key] = {
        ...state.avaliacoes[key],
        ...item,
      };

      state.changedStatus[key] = true;
    },
    updateValidation: (
      state,
      { payload: { id, validation } }: PayloadAction<IUpdateValidationAction>,
    ) => {
      const key = String(id);

      state.avaliacoes[key] = {
        ...state.avaliacoes[key],
        validacao: validation,
      };

      state.changedStatus[key] = true;
    },
    addItem: (state, { payload: idTurmaDisc }: PayloadAction<number>) => {
      const avaliacao: IAvaliacaoForm = {
        id: `0_${state.idIndex}`,
        idAvaliacao: 0,
        data: null,
        idTurmaDisc,
        sigla: '',
        tipo: 'media',
        nome: '',
        peso: '',
        validacao: false,
      };

      state.idIndex += 1;

      state.avaliacoesList.push(avaliacao);

      state.avaliacoes[avaliacao.id] = avaliacao;
    },
    deleteItem: (state, { payload: id }: PayloadAction<string>) => {
      delete state.avaliacoes[id];

      delete state.changedStatus[id];

      state.avaliacoesList = state.avaliacoesList.filter((item) => item.id !== id);
    },
    saveAvaliacoes: (state, { payload }: PayloadAction<ISaveAvaliacaoResponse[]>) => {
      payload.forEach((item) => {
        const key = item.reactId;

        if (key !== item.idAvaliacao.toString()) {
          const newAvaliacao = {
            ...state.avaliacoes[key],
            idAvaliacao: item.idAvaliacao,
            id: item.idAvaliacao.toString(),
          };

          state.avaliacoes[item.idAvaliacao] = newAvaliacao;

          delete state.avaliacoes[key];

          // state.avaliacoes[key].idAvaliacao = item.idAvaliacao;

          state.avaliacoesList = state.avaliacoesList.map((avaliacao) =>
            avaliacao.id === key ? newAvaliacao : avaliacao,
          );
        }

        delete state.changedStatus[key];
      });

      state.avaliacoesList = Object.values(state.avaliacoes);
    },
    showError: (state) => {
      state.deleteError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteAvaliacaoAsync.pending, (state) => {
        state.loadingDelete = true;
      })
      .addCase(deleteAvaliacaoAsync.fulfilled, (state, { payload }) => {
        state.loadingDelete = false;

        if (payload == null) {
          return;
        }

        if (payload.error != null) {
          state.deleteError = payload.error;
        } else {
          delete state.avaliacoes[payload.id];

          delete state.changedStatus[payload.id];

          state.avaliacoesList = state.avaliacoesList.filter((item) => item.id !== payload.id);
        }
      })

      .addCase(getNaturezasAsync.pending, (state) => {
        state.statusNaturezas = 'pending';
      })
      .addCase(getNaturezasAsync.fulfilled, (state, { payload }) => {
        const { data, error } = payload;

        if (error != null) {
          state.statusNaturezas = 'rejected';

          state.errorNaturezas = error;
        } else {
          state.statusNaturezas = 'fulfilled';

          state.errorNaturezas = null;

          state.naturezas = data;
        }
      });
  },
});

export const peAvaliacoesFormActions = {
  ...peAvaliacoesFormSlice.actions,
  deleteAvaliacaoAsync,
  getNaturezasAsync,
};

export const peAvaliacoesFormSelector = {
  openAll: (state: RootState) => state.peAvaliacoesForm.openAllState,
  openStatus: (state: RootState) => state.peAvaliacoesForm.openStatus,
  changedItems: (state: RootState) => state.peAvaliacoesForm.changedStatus,
  avaliacoes: (state: RootState) => state.peAvaliacoesForm.avaliacoes,
  avaliacoesList: (state: RootState) => state.peAvaliacoesForm.avaliacoesList,
  naturezas: (state: RootState) => state.peAvaliacoesForm.naturezas,
  statusNaturezas: (state: RootState) => state.peAvaliacoesForm.statusNaturezas,
  errorNaturezas: (state: RootState) => state.peAvaliacoesForm.errorNaturezas,
  loadingDelete: (state: RootState) => state.peAvaliacoesForm.loadingDelete,
  deleteError: (state: RootState) => state.peAvaliacoesForm.deleteError,
};
