import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useParams } from 'react-router-dom';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';

import { Avaliacoes } from '../Avaliacoes';
import { Objetivos } from '../Objetivos';
import { PdfGeneratorMemo } from '../PdfGenerator';
import { PlanoAulas } from '../PlanoAulas';
import { PlanoEnsinoArtigos } from './artigos';
import { PlanoEnsinoBibliografiaBasica } from './bibliografiaBasica';
import { PlanoEnsinoBibliografiaComplementar } from './bibliografiaComplementar';
import { PlanoEnsinoCargaHorariaTable } from './cargaHorariaTable';
import { PlanoEnsinoDisciplinaTable } from './disciplinaTable';
import { PlanoEnsinoEmenta } from './ementa';
import { PlanoEnsinoHeader } from './header';
import { PlanoEnsinoIntegracao } from './integracao';
import { PlanoEnsinoInterdisciplinaridade } from './interdisciplinaridade';
import { PlanoEnsinoObservacoes } from './observacoes';
import { PlanoContainer } from './styles';

export function PlanoEnsino() {
  const params = useParams();
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  if (disciplina == null || disciplina.idTurmaDisc !== Number(params.id)) return <></>;

  return (
    <PlanoContainer>
      <PdfGeneratorMemo idTurmaDisc={disciplina.idTurmaDisc} />

      <PlanoEnsinoHeader />

      <Typography component="h2">PLANO DE ENSINO</Typography>

      <PlanoEnsinoDisciplinaTable />

      <PlanoEnsinoCargaHorariaTable />

      <PlanoEnsinoEmenta />

      <Objetivos />

      <PlanoAulas />

      <Avaliacoes />

      <PlanoEnsinoInterdisciplinaridade />

      <PlanoEnsinoIntegracao />

      <PlanoEnsinoBibliografiaBasica />

      <PlanoEnsinoBibliografiaComplementar />

      <PlanoEnsinoArtigos />

      <PlanoEnsinoObservacoes />
    </PlanoContainer>
  );
}

export const PlanoEnsinoMemo = React.memo(PlanoEnsino);
