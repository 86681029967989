import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { useAuth } from '#shared/hooks/auth';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  peAvaliacoesFormActions,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

import { AvaliacaoCardMemo } from '../AvaliacaoCard';
import { Status } from '../PlanoAulas/styles';
import { DeleteStatusAvaliacoesMemo } from './deleteStatus';
import { AvaliacoesFormulaMediaMemo } from './formulaMedia';
import { AvaliacaoObservacaoMemo } from './obsvacao';
import { ResumoModalidades } from './ResumoModalidade';
import { AvaliacaoContainer } from './styles';

export function Avaliacoes() {
  const allOpen = useAppSelector(peAvaliacoesFormSelector.openAll);
  const listaAvaliacoes = useAppSelector(peAvaliacoesFormSelector.avaliacoesList);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const canAdd = !cannotEditDisciplina({ disciplinaStatus, user });

  if (disciplina == null) return <></>;

  return (
    <>
      <DeleteStatusAvaliacoesMemo />

      <AvaliacaoContainer id="avaliacao">
        <header>
          <Typography>INSTRUMENTOS, CRITÉRIOS E CALENDÁRIO DE AVALIAÇÕES</Typography>
        </header>

        <main>
          <Box className="info">
            <Box>
              <Status color_status="#8CC59A">Preenchido</Status>
              <Status color_status="#f49e1e">Incompleto</Status>
              <Status color_status="#E05959">Não Preenchido</Status>
              <Status color_status="#adb5bd">Validação</Status>
              <Status color_status="#1985ed">Não Está Salvo</Status>
            </Box>

            <Box>
              <Button
                variant="text"
                onClick={() => dispatch(peAvaliacoesFormActions.handleOpenAll())}
              >
                {allOpen ? 'Fechar' : 'Abrir'} Todos
              </Button>
            </Box>
          </Box>

          <Box>
            {listaAvaliacoes.map((avaliacao, index) => (
              <AvaliacaoCardMemo key={avaliacao.id} avaliacao={avaliacao} numero={index + 1} />
            ))}
          </Box>

          {canAdd && (
            <Button
              className="add"
              fullWidth
              variant="contained"
              onClick={() => dispatch(peAvaliacoesFormActions.addItem(disciplina.idTurmaDisc))}
            >
              Adicionar
            </Button>
          )}

          <AvaliacoesFormulaMediaMemo />

          <ResumoModalidades />

          <AvaliacaoObservacaoMemo />
        </main>
      </AvaliacaoContainer>
    </>
  );
}
