import { Box, Typography } from '@mui/material';
import React from 'react';

import { useAuth } from '#shared/hooks/auth';
import { statusDescMap } from '#shared/utils/status';

import banner from '#static/home/banner.png';

import { Banner, Status } from './styles';

export function HomeInfo() {
  const { user } = useAuth();

  return (
    <>
      <Banner elevation={3}>
        <Box>
          <Typography className="name">Olá, {user.nome}</Typography>

          <Typography className="message">
            Estamos felizes por tê-lo novamente em nosso sistema!!
          </Typography>
        </Box>

        <img src={banner} />
      </Banner>

      <Typography component="h2">Disciplinas</Typography>

      <Box className="status">
        {user.perfilSelected != null &&
          statusDescMap[user.perfilSelected.id].map((status) => (
            <Status key={status.name} color_status={status.color}>
              {status.name}
            </Status>
          ))}
      </Box>
    </>
  );
}

export const HomeInfoMemo = React.memo(HomeInfo);
