import { Box, styled, SxProps, Theme, Toolbar } from '@mui/material';

export const autoCompleteTheme: SxProps<Theme> = {
  '.MuiAutocomplete-input': {
    color: '#fff',
  },
  '.MuiAutocomplete-inputRoot fieldset, .MuiAutocomplete-inputRoot.Mui-focused fieldset': {
    borderColor: '#ddd',
  },
  '.MuiInputLabel-outlined, .MuiInputLabel-outlined.Mui-focused': {
    color: '#ddd',
  },
  '.MuiAutocomplete-endAdornment': {
    color: '#fff',
  },
  '.MuiAutocomplete-clearIndicator': {
    color: '#fff',
  },
  '.MuiAutocomplete-popupIndicator': {
    color: '#fff',
  },
  '&:hover': {
    '.MuiAutocomplete-inputRoot fieldset': {
      borderColor: '#fff',
    },
  },
  '.MuiCircularProgress-root': {
    color: '#fff',
  },
};

export const AppBarContainer = styled(Toolbar)`
  display: flex;
  align-items: center;

  > h1 {
    margin-left: 1rem;
    margin-right: 2rem;
    font-size: 1.625rem;
    font-weight: bold;
    letter-spacing: 3px;
    font-family: 'Archivo';

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .menu-md {
    flex-grow: 1;
    display: flex;
    align-items: center;

    > button {
      color: #fff;
      font-size: 0.875rem;

      &:hover {
        color: #e8923d;
      }
    }
  }

  .user {
    flex-grow: 0;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
  }
`;

export const MenuDisciplinaContainer = styled(Box)`
  @media only screen and (min-width: 1300px) {
    display: none;
  }
`;

export const MenuDisciplina = styled(Box)`
  width: 100%;

  padding: 1rem;

  > p {
    color: #333;
    font-family: 'Archivo';
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

export const MenuItemDisciplina = styled(Box)`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 0.5rem;
  }

  > p {
    color: #333;
    font-size: 1rem;
    font-family: 'Archivo';
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
      font-weight: 700;
    }
  }
`;
