import { Delete } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useState } from 'react';
import React from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { useAuth } from '#shared/hooks/auth';
import { CustomButton, TextConfirm } from '#shared/styledComponents/common';
import { cannotEditDisciplina } from '#shared/utils/getEditPermission';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IAvaliacaoForm,
  peAvaliacoesFormActions,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

import { FloatIcon } from './styles';

interface IAvaliacaoCardDelete {
  avaliacao: IAvaliacaoForm;
  numero: number;
}

export function AvaliacaoCardDelete({ avaliacao, numero }: IAvaliacaoCardDelete) {
  const disciplinaStatus = useAppSelector(disciplinaInfoSelector.status);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const cannotEdit = cannotEditDisciplina({ user, disciplinaStatus });

  const minDelete = disciplina?.estagio === true ? 2 : 3;

  if (numero < minDelete || cannotEdit) return <></>;

  return (
    <>
      <CustomDialogMemo
        open={openDialog}
        title="Confirmar"
        closeModal={() => setOpenDialog(false)}
        maxWidth="xs"
      >
        <Typography>
          Voce tem certeza que deseja <b>deletar</b> a avaliacão:
        </Typography>

        <TextConfirm>Instrumento de Avaliação - {numero}</TextConfirm>

        <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center' }}>
          <CustomButton
            variant="contained"
            custom_color="#5BAB68"
            fullWidth
            sx={{ mr: '1rem' }}
            onClick={() => dispatch(peAvaliacoesFormActions.deleteAvaliacaoAsync(avaliacao.id))}
          >
            Sim
          </CustomButton>

          <CustomButton
            variant="contained"
            custom_color="#E03E1A"
            fullWidth
            onClick={() => setOpenDialog(false)}
          >
            Não
          </CustomButton>
        </Box>
      </CustomDialogMemo>

      <FloatIcon
        Icon={<Delete sx={{ color: '#fff' }} />}
        action={() => setOpenDialog(true)}
        title="Deletar Avaliacao"
      />
    </>
  );
}

export const AvaliacaoCardDeleteMemo = React.memo(AvaliacaoCardDelete);
