import { Typography, Box, Button } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import React, { useState } from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { LoadingMemo } from '#shared/components/Loading';
import { useToast } from '#shared/hooks/toast';
import { usePost } from '#shared/services/useAxios';
import { CustomButton } from '#shared/styledComponents/common';

import {
  disciplinaInfoSelector,
  disciplinaInfoActions,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import {
  IErrorValidation,
  IDisciplinaValidationResponse,
} from '#modules/disciplinas/types/disciplina';

import { DisciplinasSendErrorsModalMemo } from './errorsModal';
import { SendContainer } from './styles';

export function SendCoordenacao() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorsValidation, setErrorsValidation] = useState<IErrorValidation[] | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const dispatch = useAppDispatch();

  const { toast } = useToast();

  const { loading: validateLoading, send: validateDisciplina } = usePost<
    IDisciplinaValidationResponse,
    null
  >(`/disciplinas/validation/${disciplina?.idTurmaDisc}`);

  const sendCordenacao = async () => {
    if (disciplina?.idTurmaDisc != null) {
      const { data, error } = await validateDisciplina(null);

      if (error != null) {
        toast({ message: error, severity: 'error' });
      }

      if (data != null) {
        setOpenDialog(false);

        if (data.valid) {
          dispatch(disciplinaInfoActions.updateStatus(2));

          toast({ message: 'Plano de ensino enviado para validação', severity: 'success' });

          setErrorsValidation(null);
        } else {
          setErrorsValidation(data.errorsList);

          setOpenErrorDialog(true);
        }
      }
    }
  };

  return (
    <>
      <LoadingMemo loading={validateLoading} />

      <DisciplinasSendErrorsModalMemo
        open={openErrorDialog}
        errors={errorsValidation ?? []}
        closeModal={() => setOpenErrorDialog(false)}
      />

      <CustomDialogMemo
        open={openDialog}
        title="Confirmação"
        closeModal={() => setOpenDialog(false)}
        maxWidth="xs"
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            textAlign: 'justify',
          }}
        >
          Apertar esse botão, significa que o arquivo foi preenchido da maneira correta e será
          encaminhado para o coordenador para validação.
        </Typography>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '0.9375rem',
            fontFamily: 'Montserrat',
            mt: '1rem',
            textAlign: 'justify',
          }}
        >
          Sendo assim, tem certeza que deseja enviar o arquivo?
        </Typography>

        <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center' }}>
          <CustomButton
            variant="contained"
            custom_color="#5BAB68"
            fullWidth
            sx={{ mr: '1rem' }}
            onClick={async () => await sendCordenacao()}
          >
            Sim
          </CustomButton>

          <CustomButton
            variant="contained"
            custom_color="#E03E1A"
            fullWidth
            onClick={() => setOpenDialog(false)}
          >
            Não
          </CustomButton>
        </Box>
      </CustomDialogMemo>

      <SendContainer>
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Enviar para Coordenação
        </Button>

        {errorsValidation != null && (
          <Button variant="contained" onClick={() => setOpenErrorDialog(true)}>
            Mostrar erros ({errorsValidation.length})
          </Button>
        )}
      </SendContainer>
    </>
  );
}

export const SendCoordenacaoMemo = React.memo(SendCoordenacao);
