import { Box, Typography } from '@mui/material';
import React from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { CustomButton } from '#shared/styledComponents/common';

import { IErrorValidation } from '#modules/disciplinas/types/disciplina';

import { ErrorsList, ErrorsModalContainer } from './styles';

interface IDisciplinasSendErrorsModal {
  open: boolean;
  errors: IErrorValidation[];
  closeModal: () => void;
}

interface IErrorsMap {
  [key: string]: {
    [key: string]: string[];
  };
}

export function DisciplinasSendErrorsModal({
  closeModal,
  errors,
  open,
}: IDisciplinasSendErrorsModal) {
  const errorsInfo = errors.reduce<IErrorsMap>((map, error) => {
    const subGrupo = map[error.categoria]?.[error.subtitulo ?? '__geral__'] ?? [];

    map[error.categoria] = {
      ...map[error.categoria],
      [error.subtitulo ?? '__geral__']: [...subGrupo, error.message],
    };

    return map;
  }, {});

  return (
    <CustomDialogMemo open={open} title="Erros no envio" closeModal={() => closeModal()}>
      <ErrorsModalContainer>
        <ErrorsList>
          {Object.entries(errorsInfo).map(([categoria, subGrupo]) => (
            <Box key={categoria}>
              <Typography component="h3">{categoria}:</Typography>

              {Object.entries(subGrupo).map(([subGrupo, errors]) => (
                <Box key={subGrupo}>
                  {subGrupo !== '__geral__' ? (
                    <>
                      <Typography component="h4">{subGrupo}:</Typography>

                      <Box>
                        {errors.map((error) => (
                          <Typography key={error}>{error};</Typography>
                        ))}
                      </Box>
                    </>
                  ) : (
                    errors.map((error) => <Typography key={error}>{error};</Typography>)
                  )}
                </Box>
              ))}
            </Box>
          ))}
        </ErrorsList>

        <Box sx={{ mt: '1rem' }}>
          <CustomButton
            variant="contained"
            custom_color="#A8a8a8"
            fullWidth
            onClick={() => closeModal()}
          >
            Fechar (Só sera atualizada essa lista ao enviar para a coordenação novamente)
          </CustomButton>
        </Box>
      </ErrorsModalContainer>
    </CustomDialogMemo>
  );
}

export const DisciplinasSendErrorsModalMemo = React.memo(DisciplinasSendErrorsModal);
