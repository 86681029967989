import { ArrowForward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';

import { MenuContainer, MenuDisciplina, MenuItemDisciplina } from './styles';

interface IMenuItem {
  id: string;
  text: string;
}

function MenuItem({ id, text }: IMenuItem) {
  const scrollTo = () => {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <MenuItemDisciplina>
      <ArrowForward sx={{ color: '#f5f6f5' }} />

      <Typography onClick={scrollTo}>{text}</Typography>
    </MenuItemDisciplina>
  );
}

const MenuItemMemo = React.memo(MenuItem);

export function DisciplinaMenu() {
  return (
    <MenuContainer>
      <MenuDisciplina>
        <Typography>Ir para</Typography>

        <MenuItemMemo id="disciplinas-pendentes" text="Disciplinas Pendentes" />

        <MenuItemMemo id="ementa" text="Ementa" />

        <MenuItemMemo id="objetivos" text="Objetivos" />

        <MenuItemMemo id="plano" text="Plano de Aula" />

        <MenuItemMemo id="avaliacao" text="Instrumento, critérios, calendários de avaliações" />

        <MenuItemMemo id="descricao" text="Descrição da Interdipliscinaridade" />

        <MenuItemMemo id="integracao" text="Integração com a filosofia institucional" />

        <MenuItemMemo id="bibliografia-basica" text="Bibliografia Básica" />

        <MenuItemMemo id="bibliografia-complementar" text="Bibliografia Complementar" />

        <MenuItemMemo id="obs" text="Observações" />
      </MenuDisciplina>
    </MenuContainer>
  );
}

export const DisciplinaMenuMemo = React.memo(DisciplinaMenu);
