import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useState } from 'react';

import { CustomDialogMemo } from '#shared/components/CustomDialog';
import { LoadingMemo } from '#shared/components/Loading';
import { useAuth } from '#shared/hooks/auth';
import { useToast } from '#shared/hooks/toast';
import { usePost } from '#shared/services/useAxios';
import { CustomButton } from '#shared/styledComponents/common';
import { perfilEnum } from '#shared/utils/status';

import {
  disciplinaInfoActions,
  disciplinaInfoSelector,
} from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { IDisciplinaReopen } from '#modules/disciplinas/types/disciplina';

import { ReopenButtonContainer, ReopenTextContainer } from './styles';

export function DisciplinaReopen() {
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);
  const disciplinaIdPlanoEnsino = useAppSelector(disciplinaInfoSelector.idPlanoEnsino);

  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);

  const { user } = useAuth();
  const { toast } = useToast();

  const { loading: reopenLoading, send: reopenApi } = usePost<any, IDisciplinaReopen>(
    `/disciplinas/validation/${disciplina?.idTurmaDisc}/reopen`,
  );

  const sendReopen = async () => {
    if (disciplina?.idTurmaDisc != null) {
      const { error } = await reopenApi({
        idPlanoEnsino: disciplinaIdPlanoEnsino,
      });

      if (error != null) {
        toast({ message: error, severity: 'error' });
      } else {
        setOpenDialog(false);

        dispatch(disciplinaInfoActions.updateStatus(3));

        toast({ message: 'Plano de ensino enviado para o professor', severity: 'success' });
      }
    }
  };

  const canReopen = user.perfilSelected.id === perfilEnum.gestor;

  return (
    <>
      <LoadingMemo loading={reopenLoading} />

      <CustomDialogMemo
        open={openDialog}
        title="Confirmação"
        closeModal={() => setOpenDialog(false)}
        maxWidth="xs"
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            textAlign: 'justify',
          }}
        >
          Apertar esse botão, significa que o arquivo necessita de revisão e será encaminhado para o
          professor para preencher novamente.
        </Typography>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '0.9375rem',
            fontFamily: 'Montserrat',
            mt: '1rem',
            textAlign: 'justify',
          }}
        >
          Sendo assim, tem certeza que deseja enviar o arquivo para revisão?
        </Typography>

        <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center' }}>
          <CustomButton
            variant="contained"
            custom_color="#5BAB68"
            fullWidth
            sx={{ mr: '1rem' }}
            onClick={async () => await sendReopen()}
          >
            Sim
          </CustomButton>

          <CustomButton
            variant="contained"
            custom_color="#E03E1A"
            fullWidth
            onClick={() => setOpenDialog(false)}
          >
            Não
          </CustomButton>
        </Box>
      </CustomDialogMemo>

      <ReopenTextContainer>
        <Typography>Plano de Ensino finalizado e enviado para secretaria.</Typography>
      </ReopenTextContainer>

      {canReopen && (
        <ReopenButtonContainer>
          <Button variant="contained" onClick={() => setOpenDialog(true)}>
            Abrir arquivo para revisão
          </Button>
        </ReopenButtonContainer>
      )}
    </>
  );
}

export const DisciplinaReopenMemo = React.memo(DisciplinaReopen);
