import { Tooltip } from '@mui/material';
import React from 'react';

import { TextEllipsis } from '#shared/styledComponents/common';

interface ICustomTooltip {
  title: string | JSX.Element;
  children: string | JSX.Element;
}

export function CustomTooltip({ title, children }: ICustomTooltip) {
  return (
    <>
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              border: '1px solid #666',
            },
          },
        }}
        title={title}
      >
        {typeof children === 'string' ? <TextEllipsis>{children}</TextEllipsis> : children}
      </Tooltip>
    </>
  );
}

export const CustomTooltipMemo = React.memo(CustomTooltip);
