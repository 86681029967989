import type { IGetOptionsFormProps } from './types';

export function getOptionsForm<T, D>({
  data,
  transformData,
  defaultOption,
}: IGetOptionsFormProps<T, D>) {
  const options = data != null ? data.map(transformData) : [];

  if (defaultOption != null) {
    const labelSet = new Set<string>(options.map((option) => option.label));

    if (Array.isArray(defaultOption)) {
      defaultOption.forEach((option) => {
        if (!labelSet.has(option.label)) {
          options.push(option);
          labelSet.add(option.label);
        }
      });
    } else if (!labelSet.has(defaultOption.label)) {
      options.push(defaultOption);
      labelSet.add(defaultOption.label);
    }
  }

  return options;
}
