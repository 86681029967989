import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React from 'react';

import { disciplinaInfoSelector } from '#modules/disciplinas/redux/disciplinaInfoSlice';
import { peAvaliacoesFormSelector } from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

import { FormulaMediaContainer } from './styles';

interface IMapAvaliacoes {
  mediaFormula: string[];
  bonusFormula: string[];
  totalMediaResumo: number;
  siglasMediaResumo: string[];
  totalRecuperacaoResumo: number;
  siglasRecuperacaoResumo: string[];
  totalBonusResumo: number;
  siglasBonusResumo: string[];
}

export function AvaliacoesFormulaMedia() {
  const avaliacoes = useAppSelector(peAvaliacoesFormSelector.avaliacoes);
  const disciplina = useAppSelector(disciplinaInfoSelector.disciplina);

  const getAvaliacoesResumo = () => {
    const avaliacoesMap = Object.values(avaliacoes).reduce<IMapAvaliacoes>(
      (map, avaliacao) => {
        if (avaliacao.tipo === 'media' && avaliacao.peso !== '') {
          const pesoNumber = Number(avaliacao.peso);

          const peso = (pesoNumber / 100).toFixed(2).replace('.', ',');

          map.mediaFormula.push(`${peso}*${avaliacao.sigla}`);
          map.totalMediaResumo += pesoNumber;
          map.siglasMediaResumo.push(avaliacao.sigla);
        } else if (avaliacao.tipo === 'bonus' && avaliacao.bonus !== '') {
          const bonusNumber = Number(avaliacao.bonus?.replace(',', '.'));

          map.bonusFormula.push(avaliacao.sigla);
          map.totalBonusResumo += bonusNumber;
          map.siglasBonusResumo.push(avaliacao.sigla);
        } else if (avaliacao.tipo === 'recuperacao' && avaliacao.idRecuperacao != null) {
          const siglaRecuperando = avaliacoes[avaliacao.idRecuperacao]?.sigla;

          if (siglaRecuperando != null) {
            map.totalRecuperacaoResumo += 1;
            map.siglasRecuperacaoResumo.push(`${avaliacao.sigla} - ${siglaRecuperando}`);
          }
        }

        return map;
      },
      {
        mediaFormula: [],
        bonusFormula: [],
        totalMediaResumo: 0,
        siglasMediaResumo: [],
        totalRecuperacaoResumo: 0,
        siglasRecuperacaoResumo: [],
        totalBonusResumo: 0,
        siglasBonusResumo: [],
      },
    );

    return {
      formula: [...avaliacoesMap.mediaFormula, ...avaliacoesMap.bonusFormula].join(' + '),
      mediaResumo: `${avaliacoesMap.totalMediaResumo}% - (${avaliacoesMap.siglasMediaResumo.join(
        ', ',
      )})`,
      recuperacaoResumo: `${
        avaliacoesMap.totalRecuperacaoResumo
      } - (${avaliacoesMap.siglasRecuperacaoResumo.join(', ')})`,
      bonusResumo: `${avaliacoesMap.totalBonusResumo.toFixed(
        1,
      )} pontos - (${avaliacoesMap.siglasBonusResumo.join(', ')})`,
    };
  };

  const avaliacoesResumo = getAvaliacoesResumo();

  return (
    <FormulaMediaContainer>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={8}>
          <Box className="formula">
            <Typography>Média final = {avaliacoesResumo.formula}</Typography>

            <Typography>{disciplina?.textoAvaliacao}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box className="resumo">
            <Typography>Avaliações que compõem a média:</Typography>
            <Typography component="span">{avaliacoesResumo.mediaResumo}</Typography>

            <Typography>Recuperações cadastradas:</Typography>
            <Typography component="span">{avaliacoesResumo.recuperacaoResumo}</Typography>

            <Typography>Bônus cadastrados:</Typography>
            <Typography component="span">{avaliacoesResumo.bonusResumo}</Typography>
          </Box>
        </Grid>
      </Grid>
    </FormulaMediaContainer>
  );
}

export const AvaliacoesFormulaMediaMemo = React.memo(AvaliacoesFormulaMedia);
