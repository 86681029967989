import { Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';

import {
  IAvaliacaoForm,
  peAvaliacoesFormSelector,
} from '#modules/disciplinas/redux/peAvaliacoesFormSlice';

interface IAvaliacaoCardTitle {
  avaliacao: IAvaliacaoForm;
  numero: number;
  getStatusColorAlterations: (notSaved: boolean) => void;
}

export function AvaliacaoCardTitle({
  avaliacao,
  numero,
  getStatusColorAlterations,
}: IAvaliacaoCardTitle) {
  const changedItems = useAppSelector(peAvaliacoesFormSelector.changedItems);

  // console.log('AvaliacaoCardTitle');

  const getTitle = () => {
    const alterationsText = changedItems[avaliacao.id] ? ' **' : '';

    return `Instrumento de Avaliação - ${numero} ${alterationsText}`;
  };

  const title = getTitle();

  useEffect(() => {
    getStatusColorAlterations(changedItems[avaliacao.id] ?? false);
  }, [avaliacao.id, changedItems, getStatusColorAlterations]);

  return <Typography>{title}</Typography>;
}

export const AvaliacaoCardTitleMemo = React.memo(AvaliacaoCardTitle);
